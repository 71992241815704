export default class OrderHistoryData {
    constructor(options) {
        options = options || {};

        this.packageId = options?.PackageId || 0;
        this.packageGId = options?.PackageGId || '';
        this.title = options?.Title || '';
        this.bodyRegionNames = options?.BodyRegionNames || 'No Body Region';
        this.bodyRegionIds = options?.BodyRegionIds || '';
        this.shortDescription = options?.ShortDescription || 'No description available.';
        this.packageDetails = options?.PackageDetails || 'No details available.';
        this.imageUrl = options?.ImageUrl || '';
        this.shortImageUrl = options?.ShortImageUrl || '';
        this.stripeProductId = options?.StripeProductId || '';
        this.orderId = options?.OrderId || 0;
        this.orderGId = options?.OrderGId || '';
        this.price = options?.Price || 0;
        this.paymentStatus = options?.PaymentStatus || 'Pending';
        this.paymentDate = options?.PaymentDate || null;
        this.invitationId = options?.InvitationId || null;
        this.invitationGId = options?.InvitationGId || null;
        this.testId = options?.TestId || null;
    }
}
