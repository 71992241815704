import React, { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { format } from "date-fns";
import {Redirect} from "react-router-dom";
import ReactPaginate from "react-paginate";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import AppContext from "../../contexts/AppContext";
import examService from "../../services/ExamService";
import routes from "../../constants/Routes";
import Assessment from "../../models/Assessment";
import FysicalScoreReportView from "../report/FysicalScoreReportView";
import Refresh from "../shared/Refresh";
import {
    getArrayFromObject, getBodyRegionsValueLabelObjectArray, isEmptyObject
} from "../../utils/Utils";
import MmhTable from "../shared/table/datatable/MmhTable";

import editBtn from "../../assets/img/myAssessmensList/editIcon.svg";
import startExerciseDisableBtn from "../../assets/img/myAssessmensList/startExerciseBtn.svg";
import viewReportBtn from "../../assets/img/myAssessmensList/eyeIcon.svg";
import SelectOption from "./SelectOption";
import MyAssessmentTooltip from "./MyAssessmentTooltip";
import MultipleSelectOption from "./MultipleSelectOption";
import BiometricMovementAndCautionQuestionContainer
    from "../shared/MovementModal/BiometricMovementAndCautionQuestionContainer";
import {toast} from "react-toastify";
import {_getInCompleteAssessmentType} from "../dashboard/dashboardUtills";
import {saveIntake} from "../shared/intake-form/intake-form-service";
import useIntakeFrom from "../shared/intake-form/useIntakeFrom";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";

const MyAssessmentsDetail = memo((props) => {
    const { userInfo, setTelemedicineProperties, resetTelemedicineKeys, assessmentTypeForMyAssessmentPage, resetAssessmentTypeForMyAssessmentPage, setSelectedTabForEditAssessment } = useContext(AppContext);

    const { handleOpenIntakeForm, handleCloseIntakeForm, handleSetJoiningTelemedicine } = useIntakeFrom();
    const { getAssessmentAvailabilityStatus, handleOpenUpcomingOrExpiredPopupMessage } = useHandleExpiredAndUpcomingMeetingPopupMsg();

    const { showCompletedAssessments } = props
    const { EnableFysicalScore, IsPatientReportAvailable } = userInfo.Permissions;
    const [assessments, setAssessments] = useState([]);
    const [fysicalReportView, setFysicalReportView] = useState(false);
    const [fysicalRportUrl, setFysicalRportUrl] = useState('');
    const [selectedExam, setSelectedExam] = useState({});////TODO: need to remove
    const [showFollowUpAlert, setShowFollowUpAlert] = useState(false);
    const [showAssessmentModal, setShowAssessmentModal] = useState(false);
    const [FollowUpAlertMessage, setFollowUpAlertMessage] = useState('');
    const subscription = useRef(true);
    const [loadingAssessment, setLoadingAssessment] = useState(false);
    const [reloadMyRoms, setReloadMyRoms] = useState(false);
    const [redirectToAssessmentPage, setRedirectToAssessmentPage] = useState(false);
    const [redirectToMyExercises, setRedirectToMyExercises] = useState(false);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [reportFilterValue, setReportFilterValue] = useState(CONSTANT.FILTER_BY_REPORT.ALL.key);
    const [bodyRegions, setBodyRegions] = useState([]);
    const pageRefressInterval = useRef()
    const myPageSize = useRef(CONSTANT.PAGE_SIZE.TEN.value);
    const myPageNumber = useRef(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
    const reportStatus = useRef(null);
    const bodyRegionList = useRef('');
    const isFollowUp = useRef(null);
    const isInCompleted = useRef(0);
    const invitationIdRef = useRef(null);
    const selectedExamRef = useRef(null);

    // eslint-disable-next-line
    // const [followUpFilterValue, setFollowUpFilterValue] = useState(CONSTANT.FOLLOW_UP.YES_OR_NO.key)
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reportStatusOption, setReportStatusOption] = useState(CONSTANT.FILTER_BY_REPORT.ALL.key);
    const [assessmentTypeOption, setAssessmentTypeOption] = useState(CONSTANT.ASSESSMENT_TYPE.ALL.key);
    const [testId, setTestId] = useState('');
    const [isNeedTableRefresh, setIsNeedTableRefresh] = useState(true);
    const [clickedRowId, setClickedRowId] = useState(-1);
    const [openBiometricMovementAndCautionQuestionContainer, setOpenBiometricMovementAndCautionQuestionContainer] = useState(false);

    useEffect(() => {
        if(isNeedTableRefresh){
            setLoading(true);
            // noinspection JSIgnoredPromiseFromCall
            if(assessmentTypeForMyAssessmentPage) {
                if(assessmentTypeForMyAssessmentPage?.isFollowUp) {
                    onChangeAssessmentTypeOption(CONSTANT.ASSESSMENT_TYPE.FOLLOW_UP.key);
                } else if(assessmentTypeForMyAssessmentPage?.isLastIncompletedAssessments) {
                    onChangeAssessmentTypeOption(CONSTANT.ASSESSMENT_TYPE.LAST_INCOMPLETE.key);
                }
            }
            _getMyAssessments().then(() => {
                resetAssessmentTypeForMyAssessmentPage();
                setIsNeedTableRefresh(false);
            })
        }
        // eslint-disable-next-line
    }, [isNeedTableRefresh]);

    useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        setBodyRegions(getBodyRegionsValueLabelObjectArray());

        return () => {
            subscription.current = false;
            GLOBAL.ASSESSMENT = null;
            clearInterval(pageRefressInterval.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall

        if (reloadMyRoms) {
            _getMyAssessments().then();
            setReloadMyRoms(false);
        }
        // eslint-disable-next-line
    }, [reloadMyRoms]);

    const callMyAssessmentApi = async () => {
        let payload = {
            PageSize: myPageSize.current,
            PageNumber: myPageNumber.current,
            ReportStatus: reportStatus.current,
            BodyRegionList: bodyRegionList.current ? bodyRegionList.current.split`,`.map(x => +x) : [],
            IsFollowUp: isFollowUp.current,
            IsInCompleted: isInCompleted.current,
            Completed: showCompletedAssessments,
            Search: `${testId}`,
        };

        const response = await examService.getAssessments(payload);
        const { data, success, error } = response;

        if (subscription.current) {
            if (!success) {
                console.error(error);
                return [];
            }

            const tempData = data || '[]';
            setAssessments(tempData['MyRomList']);
            setTotalRecord(tempData['TotalRecords']);

            commonFilter({
                data: tempData['MyRomList'],
                reportStatus: reportFilterValue,
                bodyRegions: []
            });
        }
    }

    const _getMyAssessments = async () => {
        setLoading(true);
        await callMyAssessmentApi();
        clearInterval(pageRefressInterval.current);
        setLoading(false);
        setIsNeedTableRefresh(true);
    };

    const onClickActivityCell = (row, firstInCompletedGroup = {}, original = {}) => {
        if(firstInCompletedGroup?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
            if(original?.InvitationType === CONSTANT.INVITATION_TYPE.Telemedicine) {
                onClickJoinNow(original, row)
            } else if(original?.InvitationType === CONSTANT.INVITATION_TYPE.Self) {
                openTakeBiometricMovementHandler(row)
            }
        } else if(original?.ReportUrl) {
            onClickViewFysicalScoreReportCell(row)
        } else {
            onClickEditMeasurementCell(row, firstInCompletedGroup);
        }
    }

    const onClickEditMeasurementCell = (row, firstInCompletedGroup = {}) => {
        let keyOfFirstIncompleteGroup = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
        if(!isEmptyObject(firstInCompletedGroup)) {
            keyOfFirstIncompleteGroup = firstInCompletedGroup?.key || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
            if(keyOfFirstIncompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) keyOfFirstIncompleteGroup = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
        }
        setSelectedTabForEditAssessment(keyOfFirstIncompleteGroup);
        GLOBAL.ASSESSMENT = null;
        const { original } = row;
        setSelectedExam(original);
        setRedirectToAssessmentPage(true);
        invitationIdRef.current = original.InvitationId;
    };

    const onConfirmFollowUpAlert = async () => {
        setShowFollowUpAlert(false);
        setShowAssessmentModal(true);
        setLoadingAssessment(true);
        setFollowUpAlertMessage('');
        GLOBAL.ASSESSMENT = null;

        const payload = { TestId: selectedExam.TestId };
        const response = await examService.followUp(payload);
        const { success, data } = response;

        setLoadingAssessment(false);
        setReloadMyRoms(true);

        if (!success) {
            console.error('Error in follow up test creation');
            setShowAssessmentModal(false);
            return;
        }

        if (data) {
            const examData = data || '{}';
            selectedExam.TestId = examData.TestId;
            GLOBAL.ASSESSMENT = new Assessment({ TestId: examData.TestId });
        }
    };

    const onCancelFollowUpAlert = () => {
        setShowFollowUpAlert(false);
        setFollowUpAlertMessage('');
    };

    const toggleShowAssessmentModal = () => {
        setShowAssessmentModal(false);
    };

    const onClickStartMovement = () => {
        setShowAssessmentModal(false);
    };

    const onClickUpdateAssessment = async () => {
        setRedirectToAssessmentPage(true);
    }

    const onClickViewFysicalScoreReportCell = (row) => {
        if(!IsPatientReportAvailable) return;
        const { original } = row;
        const url = original['ReportUrl'];
        setFysicalRportUrl(url);
        toggleFysicalReportModal();
    };

    const openTakeBiometricMovementHandler = (row) => {
        selectedExamRef.current = row.original;
        setOpenBiometricMovementAndCautionQuestionContainer(true);
    }

    const onClickTrackMovement = (row) => () => {
        GLOBAL.ASSESSMENT = null;
        const { original } = row;
        setSelectedExam(original);
        setRedirectToMyExercises(true);
    }

    const onClickJoinNow = (original, cell) => {
        const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(original?.MeetingStartDate, original?.MeetingEndDate);
        if(assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY || assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) {
            handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus);
            return;
        }

        const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || '';
        selectedExamRef.current = {
            id: cell?.id,
            invitationId: cell?.original?.InvitationId,
            bodyRegionList: cell?.original?.BodyRegionList,
            TestId: cell?.original?.TestId
        };

        if(!isIntakeSigned) {
            let bodyRegionList = cell?.original?.BodyRegionList || '';
            let invitationTypeName = cell?.original?.ReferTypeName || '';

            handleOpenIntakeForm({
                bodyRegionList: bodyRegionList || [],
                invitationTypeName: invitationTypeName || '',
                showSubmitBtn: true,
                onClickSubmitIntakeForm: onClickSubmitIntakeForm
            })
        } else {
            handleJoinNow(cell?.id, original?.InvitationId, original?.BodyRegionList);
        }
    }

    const onClickSubmitIntakeForm = async (values) => {
        handleSetJoiningTelemedicine(true);
        let selectedExam = selectedExamRef.current;
        if(!selectedExam || !selectedExam?.id || !selectedExam?.bodyRegionList || !selectedExam?.TestId) return;

        GLOBAL.ASSESSMENT = new Assessment({ TestId: selectedExam?.TestId });
        setClickedRowId(selectedExam?.id);
        const response = await saveIntake(values);
        const { data, success, error } = response;
        if(!success) {
            toast.error(error || 'Failed to save intake form');
            onCloseTelemedicine();
            return;
        }
        handleJoinNow(selectedExam?.id, selectedExam?.invitationId, selectedExam?.bodyRegionList);
    }

    const handleJoinNow = (id, invitationId, bodyRegionList) => {
        handleCloseIntakeForm();
        setClickedRowId(id);
        setTelemedicineProperties(invitationId, bodyRegionList, null, onCloseTelemedicine);
    }

    const onCloseTelemedicine =  () => {
        handleSetJoiningTelemedicine(false);
        handleCloseIntakeForm();
        resetTelemedicineKeys();
        setClickedRowId(-1);
        GLOBAL.ASSESSMENT = null;
    }

    const toggleFysicalReportModal = () => {
        setFysicalReportView(!fysicalReportView);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Test ID',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    let isReportPDFAvailable = !!original?.ReportUrl;
                    let firstInCompletedGroup = _getInCompleteAssessmentType(original);

                    return (
                        <div className="text-center">
                            <p className="mb-0">{original?.IsFollowUp ? " FOLLOW-UP: " : ""}{original['TestId']}</p>
                            {original?.BaseTestId ? <p className="m-0">Initial: {original['BaseTestId']}</p> : null}

                            {((firstInCompletedGroup?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) || (isReportPDFAvailable || original?.IsReportReady))
                                ? (
                                    <div>
                                        <button
                                            className="bg-color-primary-light-1 color-white outline-none rounded-100px border-0 px-2 py-1"
                                            onClick={() => onClickEditMeasurementCell(cellProps.row)}
                                        >
                                            {(firstInCompletedGroup?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key)
                                                ? (
                                                    <span><img className="mr-1 svg-icon-white h-13px w-20px" src={editBtn} alt="Bio"/> Edit</span>
                                                ) : null
                                            }
                                            {(isReportPDFAvailable || original?.IsReportReady)
                                                ? (
                                                    <span><img className="mr-1 svg-icon-white" src={viewReportBtn} alt="Bio"/> View</span>
                                                ) : null
                                            }
                                        </button>
                                    </div>
                                )
                                : null
                            }
                        </div>
                    )
                }
            },
            {
                Header: 'Body Region',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original['BodyRegionName']}`;
                }
            },
            {
                Header: 'Assessment',
                HeaderSecLine: 'Type',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original['InvitationTypeTxt']}`;
                }
            },
            {
                Header: 'Order',
                HeaderSecLine: 'Date',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return format(new Date(original['SubmissionDate']) || new Date(), CONSTANT.DATE_FORMAT.MMM_DD_YYYY);
                }
            },
            {
                Header: 'Activity',
                Cell: (cellProps) => {
                    const {original} = cellProps.row;
                    let firstInCompletedGroup = _getInCompleteAssessmentType(original);
                    let isReportAvailable = !!original?.ReportUrl;
                    let assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(original?.MeetingStartDate, original?.MeetingEndDate);

                    return (
                        <>
                            {!isReportAvailable ? (
                                <>
                                    {(
                                        isEmptyObject(firstInCompletedGroup) && !isReportAvailable && original?.IsReportReady)
                                        ? <span className="badge badge-warning font-medium font-size-sm color-black">In Review</span>

                                        :   <div>
                                            <button
                                                className="btn pointer button-row btn-border"
                                                id={`edit-${original['TestId']}`}
                                                onClick={() => onClickActivityCell(cellProps.row, firstInCompletedGroup, original)}
                                                disabled={
                                                    (clickedRowId === cellProps?.row?.id) ||
                                                    ((original['InvitationType'] === CONSTANT.INVITATION_TYPE.InClinic) && (firstInCompletedGroup?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key))
                                                }
                                            >
                                                        <span className="d-flex justify-content-center align-items-center">
                                                            {clickedRowId === cellProps?.row?.id
                                                                ? <span><i className="fa fa-spinner fa-spin mr-3"></i></span>
                                                                : <span><img className="mr-3" src={editBtn} alt="Bio"/></span>
                                                            }
                                                            {
                                                                !isEmptyObject(firstInCompletedGroup)
                                                                    ? ((original['InvitationType'] === CONSTANT.INVITATION_TYPE.Telemedicine) && (firstInCompletedGroup?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key))
                                                                        ? (
                                                                            (assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) && (<span>{CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.MESSAGE}</span>)) ||
                                                                            ((assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) && (<span>{CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.MESSAGE}</span>)) ||
                                                                            ((assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.KEY) && (<span>{CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.MESSAGES.JOIN_NOW}</span>)
                                                                        )
                                                                        : `Resume ${firstInCompletedGroup?.midName}` : ""
                                                            }

                                                            {(isEmptyObject(firstInCompletedGroup) && !isReportAvailable && original?.IsReportReady)
                                                                ?
                                                                <span className="badge badge-warning font-medium font-size-sm color-black">In Review</span>
                                                                : ""
                                                            }
                                                            {/*Not need exactly just for safety*/}
                                                            {(isEmptyObject(firstInCompletedGroup) && !isReportAvailable && !original?.IsReportReady) ? "Edit" : ""}
                                                        </span>
                                                <MyAssessmentTooltip text="Update Information" target={`edit-${original['TestId']}`}/>
                                            </button>
                                        </div>
                                    }

                                </>
                            ) : ""}

                            {isReportAvailable ? (
                                <button
                                    className={`btn button-row btn-border ${IsPatientReportAvailable ? '' : 'pointer-none opacity-0_4'}`}
                                    onClick={() => onClickActivityCell(cellProps.row, firstInCompletedGroup, original)}
                                >
                                    <span className="d-flex justify-content-center align-items-center">
                                        <img className="mr-3" src={viewReportBtn} alt="Telemedicine"/> View Report
                                    </span>
                                </button>
                            ) : ""}
                        </>
                    )
                }
            },
            {
                Header: 'Home',
                HeaderSecLine: 'Exercises',
                style: 'w-100 text-center kt-align-center',
                Cell: (cellProps) => {
                    const {original} = cellProps.row;
                    return (
                        <>
                            {original['TotalHomeExercise'] === 0
                                ? (
                                    <span className="badge badge-warning font-medium font-size-sm color-black">Not Available</span>
                                )
                                : (
                                    <button
                                        className="btn pointer button-row bg-color-ice-blue-4"
                                        id={`home_exercise-${original['TestId']}`}
                                        onClick={onClickTrackMovement(cellProps.row)}
                                    >
                                        <span className="d-flex justify-content-center align-items-center color-primary-dark-1">
                                            <img className="mr-3" src={startExerciseDisableBtn} alt="Home Exercise" /> Start
                                        </span>
                                        <MyAssessmentTooltip text="Start Home Exercise" target={`home_exercise-${original['TestId']}`} />
                                    </button>

                                )
                            }
                        </>
                    );
                }
            },
        ],

        // eslint-disable-next-line
        [clickedRowId]
    );

    const onChangeReportStatusOption = (value) => {
        setReportStatusOption(value);

        if (value === CONSTANT.FILTER_BY_REPORT.IN_PROGRESS.key) {
            reportStatus.current = 2;
        }
        if (value === CONSTANT.FILTER_BY_REPORT.COMPLETED.key) {
            reportStatus.current = 1;
        }
        if (value === CONSTANT.FILTER_BY_REPORT.ALL.key) {
            reportStatus.current = null;
        }

        myPageNumber.current = CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value;
        myPageSize.current = CONSTANT.PAGE_SIZE.TEN.value;
        setIsNeedTableRefresh(true);
        setReportFilterValue(value);
        commonFilter({ data: assessments, reportStatus: value });
    }

    const onChangeSelectBodyRegion = (values) => {
        const keys = values.map(item => item.value);

        bodyRegionList.current = '';
        keys.map((key) => {
            if (bodyRegionList.current.length > 0) {
                bodyRegionList.current = bodyRegionList.current + ',' + key;
            } else {
                bodyRegionList.current = key + '';
            }
        })

        myPageNumber.current = CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value;
        myPageSize.current = CONSTANT.PAGE_SIZE.TEN.value;
        setIsNeedTableRefresh(true);
    }

    const onChangeAssessmentTypeOption = (value) => {
        setAssessmentTypeOption(value);

        if (value === CONSTANT.ASSESSMENT_TYPE.FOLLOW_UP.key) {
            isFollowUp.current = true;
            isInCompleted.current = 0;
        }
        if (value === CONSTANT.ASSESSMENT_TYPE.LAST_INCOMPLETE.key) {
            isFollowUp.current = null;
            isInCompleted.current = 1;
        }
        if (value === CONSTANT.ASSESSMENT_TYPE.ALL.key) {
            isFollowUp.current = null;
            isInCompleted.current = 0;
        }

        myPageNumber.current = CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value;
        myPageSize.current = CONSTANT.PAGE_SIZE.TEN.value;

        setIsNeedTableRefresh(true);
        // setFollowUpFilterValue(value);
        commonFilter({ data: assessments, followUp: value });
    }

    const onKeyUpTestId = (e) => {
        e.preventDefault();
        setTestId(e.target.value);
        myPageNumber.current = CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value;
        myPageSize.current = CONSTANT.PAGE_SIZE.TEN.value;
        setReloadMyRoms(true);
    }

    const handlePageSize = (event) => {
        myPageSize.current = event.target.value;
        myPageNumber.current = CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value;
        setIsNeedTableRefresh(true);
    }

    const handleRefresh = () => {
        setReloadMyRoms(true);
    }

    const commonFilter = (
        {
            data,
        }) => {
        //as already api filtering the data we do not need to filter those data again
        setFilteredAssessments(data);
    }

    return (
        <>
            {redirectToAssessmentPage && (
                <>
                    <Redirect to={`${routes.editAssessment}/${selectedExam.TestId}?groupId=${_getInCompleteAssessmentType(selectedExam)?.key}`}/>
                </>

            )}
            {redirectToMyExercises && (
                <Redirect to={`${routes.myExercises}/${selectedExam.TestId}`}/>
            )}
            <div className="kt-portlet kt-portlet--bordered color-primary font-wight-500 my-assessment w-100-percent">
                <div className="kt-portlet__body">
                    <div className="filter-list">
                        <div className="row flex-row  kt-ml-0 float-left">
                            <div className='kt-mr-5'>
                                <label htmlFor="example-text-input"
                                       className="align-self-center kt-ml-20">Report</label>
                                <div className="filter_width_height">
                                    <SelectOption
                                        onChange={onChangeReportStatusOption}
                                        value={reportStatusOption}
                                        values={getArrayFromObject(CONSTANT.FILTER_BY_REPORT)}
                                    />
                                </div>
                            </div>
                            <div className='kt-mr-5'>
                                <label htmlFor="example-text-input"
                                       className="align-self-center kt-ml-20">Assessment Type</label>
                                <div className="select-div filter_width_height">
                                    <SelectOption
                                        onChange={onChangeAssessmentTypeOption}
                                        value={assessmentTypeOption}
                                        // values={getArrayFromObject(CONSTANT.FOLLOW_UP)}
                                        values={getArrayFromObject(CONSTANT.ASSESSMENT_TYPE)}
                                    />
                                </div>
                            </div>
                            {EnableFysicalScore && (
                                <div className="">
                                    <label htmlFor="example-text-input"
                                           className="align-self-center kt-ml-15">Body Region</label>

                                    <div className="filter_width_height">
                                        <MultipleSelectOption
                                            placeholder="Body Region"
                                            options={bodyRegions}
                                            onChange={onChangeSelectBodyRegion}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className='kt-ml-5'>
                                <label
                                    htmlFor="example-text-input"
                                    className="align-self-center kt-ml-15">TEST ID
                                </label>
                                <div>
                                    <input
                                        placeholder="Test ID"
                                        className="form-control filter_width_height"
                                        onChange={e => setTestId(e.target.value)}
                                        onKeyUp={onKeyUpTestId}
                                    />
                                </div>
                            </div>
                        </div>
                        <Refresh refreshFunction={handleRefresh}/>
                    </div>

                    <MmhTable columns={columns} data={filteredAssessments} loading={loading}/>

                    <div className="mt-2  d-flex justify-content-between table-pagination">
                        <div className="table-pagination__page-number">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(totalRecord / myPageSize.current)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) => {
                                    //setMyPageNumber(data.selected + 1)
                                    myPageNumber.current = data.selected + 1;
                                    setIsNeedTableRefresh(true);
                                }}
                                containerClassName={"pagination  justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item "}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={(myPageNumber.current - 1)}
                            />
                        </div>
                        <div className="table-pagination__quantity-in-a-page">
                            <span
                                className="mr-2 kt-datatable__pager-detail">Showing {((myPageNumber.current * myPageSize.current) - myPageSize.current) + 1} to {(myPageNumber.current * myPageSize.current)} of {totalRecord} entries</span>
                            <select
                                className="form-control-sm color-primary"
                                onChange={handlePageSize}
                                value={myPageSize.current}>
                                {getArrayFromObject(CONSTANT.PAGE_SIZE)?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {fysicalReportView && (
                <FysicalScoreReportView
                    open={fysicalReportView}
                    url={fysicalRportUrl}
                    toggleFysicalReportModal={toggleFysicalReportModal}
                >
                </FysicalScoreReportView>
            )}

            {showFollowUpAlert && (
                <SweetAlert
                    show={showFollowUpAlert}
                    showCancel
                    cancelBtnCssClass="btn btn-sm btn-success"
                    confirmBtnCssClass="btn btn-sm btn-danger"
                    cancelBtnText="Yes, create it!"
                    confirmBtnText="No, don't create it!"
                    title='Are you sure?' warning
                    onCancel={onConfirmFollowUpAlert}
                    onConfirm={onCancelFollowUpAlert}
                    closeOnClickOutside={false}>
                    {FollowUpAlertMessage}
                </SweetAlert>
            )}
            {showAssessmentModal && (
                <Modal isOpen={showAssessmentModal} className="modal-md">
                    <ModalHeader toggle={toggleShowAssessmentModal}>
                        Confirmation of assessment changes
                    </ModalHeader>
                    <ModalBody>
                        {loadingAssessment && (
                            <div className="h-300">
                                <div className="kt-spinner kt-spinner--lg kt-spinner--info kt-mb-20"/>
                            </div>
                        )}
                        {!loadingAssessment && (
                            <>
                                <p className="kt-margin-b-20">
                                    Do you want to change Activity of Daily Living or Medical History or Chief Complaints? Please press button yes otherwise start movement.
                                </p>
                                <button type="button"
                                        className="btn btn-primary kt-margin-r-10"
                                        onClick={onClickUpdateAssessment}>
                                    Yes, Update Assessment
                                </button>
                                <button type="button"
                                        className="btn btn-primary"
                                        onClick={onClickStartMovement}>
                                    Start/Review Movement
                                </button>
                            </>
                        )}
                    </ModalBody>
                </Modal>
            )}

            {openBiometricMovementAndCautionQuestionContainer &&
                <BiometricMovementAndCautionQuestionContainer
                    data={{
                        testId: selectedExamRef.current?.TestId || '',
                        selectedBodyRegions: {bodyRegionList : selectedExamRef.current?.BodyRegionList || [] },
                        openBiometricMovementAndCautionQuestionContainer,
                    }}
                    setOpenBiometricMovementAndCautionQuestionContainer={setOpenBiometricMovementAndCautionQuestionContainer}
                />
            }
        </>
    )
});

export default MyAssessmentsDetail;
