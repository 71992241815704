import PackageDetails from "./PackageDetails";
import React, { useEffect, useState } from "react";
import routes from "../../constants/Routes";
import CONSTANT from "../../constants/Constant";
import useRedirect from "../../hooks/useRedirect";
import DirectToConsumerService from "../../services/DirectToConsumerService";
import PackageData from "../../models/PackageData";

const Packages = (props) => {
    const [isLoadingNotification, setIsLoadingNotification] = useState(true);
    const [isError, setIsError] = useState(false);
    const [packages, setPackages] = useState([]);
    const bgClass = props?.bgClass || 'bg-color-light-gray-1';

    const { redirect } = useRedirect();

    useEffect(() => {
        handleGetPackages();
    }, []);

    const handleGetPackages = async () => {
        let tempPackagesData = await DirectToConsumerService.getAllPackages({});
        if(tempPackagesData.success) {
            let tempPackages = tempPackagesData.data.map((packageData) => {
                return new PackageData(packageData);
            });
            setPackages(tempPackages);
            // tempPackageDataMappedWithDTO = new PackageData(tempPackageData.data);
            // setPageData(makeSerializable(tempPackageDataMappedWithDTO));
        } else {
            setIsError(true);
        }
        setIsLoadingNotification(false);
    }

    const handleOnClickGetStarted = (packageData) => {
        redirect(`${routes.packageDetails}?${CONSTANT.QUERY_PARAM.PACKAGE_ID}=${packageData?.gId || 0}`);
    }

    return (
        <div>
            {
                (isLoadingNotification || isError)
                    ?
                    (
                        <div className="position-center">
                            {isLoadingNotification && <i className="fa fa-spinner fa-spin kt-font-primary font-size-4-rem"></i>}
                            {isError && <div className="font-size-2-rem">Internal Server Error</div>}
                        </div>
                    )
                    : (
                        <div className={`p-4 ${bgClass}`}>
                            <div
                                className="kt-portlet__body d-flex justify-content-center align-items-center mt-xl-0">
                                <div className="font-family-outfit">
                                    <div className="container">
                                        <div className="row">
                                            {packages.map((packageData, index) => {
                                                return (
                                                    <div className="col-12 col-lg-4 h-80vh">
                                                        <PackageDetails
                                                            title={packageData?.title || ''}
                                                            iconSrc={packageData?.shortImageUrl || ''}
                                                            price={packageData?.price || 0}
                                                            packageDetails={packageData?.packageDetails || ''}
                                                            onClickGetStarted={() => handleOnClickGetStarted(packageData)}
                                                            btnText="Show Details"
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default Packages