import "./Dashboard.scss";
import React, { useContext, useEffect, useState } from "react";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import MainHeaderWrapper from "../shared/layout/MainHeaderWrapper";
import DashboardBody from "./DashboardBody";
import DashboardFooter from "../shared/layout/Footer";
import AppContext from "../../contexts/AppContext";
import {getNotificationData} from "./notification/business/services";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";

function Dashboard() {
    const { blockedUI, userInfo } = useContext(AppContext);
    const { EnableFysicalScore, IsPostureAnalyzeEnabled } = userInfo.Permissions;
    const emailAddress = (GLOBAL.USER_INFO && GLOBAL.USER_INFO["EmailAddress"]) || "";
    const [allNotifications, setAllNotifications] = useState([]);
    const [isLoadingNotification, setIsLoadingNotification] = useState(true);

    useEffect(() => {
        (async () => {
            const prepareData = await getNotificationData(emailAddress);
            setAllNotifications(prepareData);
            setIsLoadingNotification(false);
        })()

    }, [])

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <MainHeaderWrapper />
                    {isLoadingNotification && (
                        <div className="position-center">
                            <i className="fa fa-spinner fa-spin kt-font-primary font-size-4-rem"></i>
                        </div>
                    )}
                    <React.Fragment>
                        {!EnableFysicalScore && IsPostureAnalyzeEnabled && !isLoadingNotification && (
                            <DashboardBody/>
                        )}
                        {EnableFysicalScore && (
                            <React.Fragment>
                                <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.DASHBOARD} >
                                    {!blockedUI &&
                                        <DashboardBody
                                            isLoadingNotification={isLoadingNotification}
                                            allNotifications={allNotifications}
                                        />
                                    }

                                </BodyWithTopMenu>
                                <DashboardFooter/>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;