import PropTypes from "prop-types";

const PackageDetails = (props) => {
    const iconSrc = props?.iconSrc || '';
    const title = props?.title || '';
    const price = props?.price || 0;
    const packageDetails = props?.packageDetails || '';
    const onClickGetStarted = props?.onClickGetStarted || (() => {});

    return (
        <div className="package-details">
            <div className="package-details__header">
                <div className="package-details__image-wrapper">
                    <img
                        src={iconSrc}
                        alt="Posture"
                        className="package-details__image"
                    />
                </div>

                <h3 className="package-details__title">{title}</h3>
                <p className="package-details__price">$ {price}</p>

                <button
                    className="package-details__button"
                    onClick={onClickGetStarted}
                >
                    Get Started
                </button>
                </div>
            <div className="package-details__details overflow-hidden flex-grow-1">
                <p className="package-details__description">
                    {packageDetails}
                </p>
            </div>
        </div>
    )
}

PackageDetails.propTypes = {
    iconSrc: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    packageDetails: PropTypes.string,
    onClickGetStarted: PropTypes.func,
};

export default PackageDetails;