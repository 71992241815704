import React, { useState } from "react";

const PackageOverview = ({ shortDescription }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const truncateDescriptionAfterLength = 200;

    const handleToggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    // Only truncate if the length exceeds the truncation length
    const truncatedDescription = shortDescription.length > truncateDescriptionAfterLength
        ? shortDescription.slice(0, truncateDescriptionAfterLength) + "..."
        : shortDescription;

    return (
        <div className="bg-white package-overview">
            <h3 className="package-overview__title">Details</h3>
            <hr />

            <div className="package-overview__content">
                <p className="package-overview__description">
                    {showFullDescription ? shortDescription : truncatedDescription}
                </p>
            </div>

            {/* Show 'Continue Reading' button only if the description is long enough to be truncated */}
            {shortDescription.length > truncateDescriptionAfterLength && !showFullDescription && (
                <button
                    type="button"
                    className="package-overview__button"
                    onClick={handleToggleDescription}
                >
                    Continue Reading
                </button>
            )}
        </div>
    );
};

export default PackageOverview;
