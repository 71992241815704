import React, { useMemo } from "react";
import MmhDataTable from "../shared/table/datatable/MmhDataTable";

const ExercisesTrackingDetails = (props) => {
    const { exercise } = props;
    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'ExerciseHistoryDate',
                style: 'w-200px',
            },
            {
                Header: 'No Of Sets',
                accessor: 'NoOfSets',
            },
            {
                Header: 'No of Reps',
                accessor: 'NoOfReps',
            },
            {
                Header: 'Wrong Count',
                accessor: 'NoOfWrongCount',
            }
        ],

        // eslint-disable-next-line
        []
    );

    const getWeekday = (date) => {
        date = new Date(date)
        const options = { weekday: "long" };
        return date.toLocaleTimeString("en-us", options)
    }

    const getDate = (date) => {
        date = new Date(date)
        const options = { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" };
        return date.toLocaleTimeString("en-us", options)
    }

    const getTime = (date) => {
        date = new Date(date)
        const options = { hour: "2-digit", minute: "2-digit" };
        return date.toLocaleTimeString("en-us", options)
    }

    const dateFormate = (d) => {
        let date = new Date(d);
        let options = {
            weekday: "long", year: "numeric", month: "short",
            day: "numeric", hour: "2-digit", minute: "2-digit"
        };

        return date.toLocaleTimeString("en-us", options)

    }

    const prepareExerciseTrackingDetailsData = (trackingDetails) => {
        trackingDetails.forEach((tracking) =>{
            tracking.ExerciseHistoryDate = `${getDate(tracking?.ExerciseHistoryDate)} `
        })
        return trackingDetails;
    }

    return (
        <div className="kt-portlet__body">
            <div className="d-flex flex-column">
                <div className="d-flex">
                    <div className="kt-avatar kt-avatar--outline kt-avatar--circle-" id="kt_user_edit_avatar">
                        <div className="kt-avatar__holder">
                            <img src={exercise.ExerciseImageUrl} alt="exercise" height="100%" width="100%"/>
                        </div>
                    </div>
                    <div className="kt-mr-10 kt-ml-10">
                        <span className="kt-font-xl kt-font-bold">Exercise Name: </span>
                        <span className="kt-font-xl">{exercise.ExerciseName}</span><br/>
                        <span className="kt-font-bold">Start Date: </span>
                        <span>{dateFormate(exercise?.['ExercisePlan']?.StartDate)}</span><br/>
                        <span className="kt-font-bold">Completed Sets: </span>
                        <span>{exercise.CompletedSets}</span><br/>
                        <span className="kt-font-bold">Completed Reps: </span>
                        <span>{exercise.CompletedReps}</span><br/>
                    </div>
                </div>
                <MmhDataTable
                    columns={columns}
                    data={exercise?.['ExerciseTrackingDetails']?.length > 0 ? prepareExerciseTrackingDetailsData(exercise?.['ExerciseTrackingDetails']) : []}
                    flagForFilter="true"/>
            </div>
        </div>
    )
};

export default ExercisesTrackingDetails;
