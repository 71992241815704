export default class PackageData {
    constructor(options) {
        options = options || {};

        // this.id = options?.Id || 0;
        this.gId = options?.GId || '';
        this.title = options?.Title || 'No Title';
        this.bodyRegionIds = options?.BodyRegionIds || '';
        // this.invitationTypeId = options?.InvitationTypeId || 0;
        // this.duration = options?.Duration || 0;
        this.price = options?.Price || 0;
        this.shortDescription = options?.ShortDescription || 'No description available.';
        this.packageDetails = options?.PackageDetails || 'No details available.';
        // this.tenantId = options?.TenantId || 0;
        this.imageUrl = options?.ImageUrl || '';
        this.shortImageUrl = options?.ShortImageUrl || '';
        // this.stripeProductId = options?.StripeProductId || '';
        // this.isActive = options?.IsActive ?? false; // Nullish coalescing for boolean
        // this.creationDate = options?.CreationDate || null; // Default to null for dates
    }
}