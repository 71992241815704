import React from "react";

const PlaceOrder = (props) => {
    const assessmentName = props?.assessmentName || '';
    const price = props?.price || 0;
    const onClickPlaceOrder = props?.onClickPlaceOrder || (() => {});
    const isLoadingCountry = props?.isLoadingCountry || false;
    const isPlacingOrder = props?.isPlacingOrder || false;

    return (
        <div className="place-order">
            {
                !isLoadingCountry && (
                    <div>
                        <div className="place-order__summary bg-white">
                            <div className="place-order__item">
                                <p className="place-order__item-name">{assessmentName}</p>
                                <p className="place-order__item-price">$ {price}</p>
                            </div>
                            <hr className="place-order__divider"/>

                            {/*<div className="place-order__item">*/}
                            {/*    <p className="place-order__item-name">Sub Total</p>*/}
                            {/*    <p className="place-order__item-price">$ 14.99</p>*/}
                            {/*</div>*/}
                            <hr className="place-order__divider"/>

                            <div className="place-order__item">
                                <p className="place-order__item-name">Total</p>
                                <p className="place-order__item-price">$ {price}</p>
                            </div>
                        </div>

                        <button
                            className="place-order__button"
                            onClick={onClickPlaceOrder}
                            disabled={isPlacingOrder}
                        >
                            {isPlacingOrder ?
                                (
                                    <div className="d-flex">
                                        <i className="ml-4 fa fa-spinner fa-spinner--sm fa-spin"></i>
                                        <p className="ml-4 mb-0">Placing Order...</p>
                                    </div>

                                )
                                : "Place Order"
                            }
                        </button>
                    </div>
                )
            }
        </div>
    );
};

export default PlaceOrder;
