import { initDirectToConsumer } from "./initDirectToConsumer";
import { createSlice } from "@reduxjs/toolkit";

const directToConsumerSlice = createSlice({
    name: 'directToConsumer',
    initialState: initDirectToConsumer,
    reducers: {
        setValue: (state, action) => {
            const { key, value } = action.payload;
            if (state[key]) {
                state[key].value = value;
            }
        },
        setCountries: (state, action) => {
            state.countries.value = action.payload;
        },
        setCountryStates: (state, action) => {
            state.countryStates.value = action.payload;
        },
        setStates: (state, action) => {
            state.states.value = action.payload;
        },
        setError: (state, action) => {
            state[action.payload.name].error = action.payload.error;
        },
        setIsClickedPlaceOrder: (state, action) => {
            state.isClickedPlaceOrder = action.payload;
        }
    }
});

export const {
    setCountries,
    setCountryStates,
    setStates,
    setError,
    setIsClickedPlaceOrder,
    setValue,
} = directToConsumerSlice.actions;

export default directToConsumerSlice.reducer;