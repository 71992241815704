import "../../assets/sass/components/signIn/_signIn.scss";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import AppContext from "../../contexts/AppContext";
import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import routes from "../../constants/Routes";
import authService from "../../services/AuthService";
import storageService from "../../utils/StorageService";

import { Trans, useTranslation } from "react-i18next";
import { invalidFormElement } from "../../utils/DefaultInitialization";
import { prepareEnabledSubscribedFeatures } from "../../utils/Utils";
import DisplayUrlContent from "../shared/DisplayUrlContent";

import mmhColorLogo from '../../assets/img/signin/mmhLogo.svg';
import doctorIcon from '../../assets/img/signin/doctor-icon.svg';
import topRoundIcon from '../../assets/img/signin/topRound.svg';
import leftIcon from '../../assets/img/signin/leftIcon.svg';
import bottomRoundIcon from '../../assets/img/signin/bottomRound.svg';
import useRedirectToBillingPage from "../../hooks/useRedirectToBillingPage";
import useRedirect from "../../hooks/useRedirect";

function SignIn() {
    const {
        authenticated,
        authenticate,
        deAuthenticate,
        storeUserInfo,
        storeUserInfoToLocalStorage
    } = useContext(AppContext);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);
    const [redirectToReports, setRedirectToReports] = useState(false);
    const [redirectToSignUp, setRedirectToSignUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { i18n } = useTranslation();
    const subscription = useRef(true);
    const [inputType, setInputType] = useState('password');
    const [termOfServiceModal, setTermOfServiceModal] = useState(false);
    const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);

    const location = useLocation();
    const { handleRedirectToBillingPageIfFromPackagePage } = useRedirectToBillingPage();
    const { redirectKeepingQueryParams } = useRedirect();

    useEffect(() => {
        if (!GLOBAL.URL_PARAMS.IS_INTAKE_SIGNED) {
            deAuthenticate();
        }

        // eslint-disable-next-line
    }, []);

    const initialValuesForLogin = () => {
        return {
            Email: GLOBAL.URL_PARAMS.EMAIL_ADDRESS ? GLOBAL.URL_PARAMS.EMAIL_ADDRESS : '',
            Password: "",
            AcceptTerms: false
        };
    };

    const validationSchema = () => {
        return Yup.object().shape({
            Email: Yup.string()
                .email('Please enter a valid email')
                .required(i18n.t("common.message.error.required-field")),
            Password: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
        });
    };

    const onSubmitLogin = async (values, actions) => {
        actions.setSubmitting(true);
        setErrorMessage('');

        const response = await authService.login(values);
        const { data, success, error } = response;

        if (!success) {
            !error && setErrorMessage('Server error');
            error && setErrorMessage(error);
            actions.setSubmitting(false);
            return;
        }

        storageService.addItem(CONSTANT.JWT.TOKEN_KEY, data?.Token);
        storageService.addItem(CONSTANT.REFRESH_TOKEN.KEY_NAME, data?.RefreshToken);

        let userInfo = data || '{}';
        const isProvider = userInfo['RoleName'] === CONSTANT.ROLE.PROVIDER;
        const isProviderLogin = storageService.getItem('providerLogin') === 'true';

        if (!isProviderLogin && isProvider) {
            setErrorMessage('You are not patient, please go to the provider view');
            return;
        }

        if (isProviderLogin && !isProvider) {
            setErrorMessage('You are not provider, please go to the patient view');
            return;
        }

        const subscriptionFeatures = await getSubscriptionFeatures(userInfo, isProvider);
        userInfo.Permissions = prepareEnabledSubscribedFeatures(subscriptionFeatures);

        if (isProvider) {
            window.location.href = `https://${GLOBAL.TENANT}.${CONSTANT.URLS.BASE_URL}/account/painemmalogin?patientId=${userInfo['ContactId']}&redirecturl=home`;
            return;
        }

        if (userInfo.Permissions.IsNoFaultEnabled && !userInfo['IsIntakeSigned']) {
            window.location.href = `https://${GLOBAL.TENANT}.${CONSTANT.URLS.BASE_URL}/intake/info?patientId=${userInfo['ContactId']}&redirect=self&type=inclinic&invid=${GLOBAL.URL_PARAMS.INVITATION_ID}`;
            return;
        }

        GLOBAL.USER_INFO = userInfo;
        authenticate(true);
        storeUserInfo(GLOBAL.USER_INFO);
        actions.setSubmitting(false);
        storeUserInfoToLocalStorage();

        if(handleRedirectToBillingPageIfFromPackagePage()) {
            return;
        }

        // if (window.location.href.includes('?') && !GLOBAL.URL_PARAMS.IS_REPORT_DOWNLOAD) {
        //     setRedirectToScreening(true);
        // } else
        if (GLOBAL.URL_PARAMS.IS_REPORT_DOWNLOAD) {
            setRedirectToReports(true);
        } else {
            setRedirectToDashboard(true);
        }
    };

    const getSubscriptionFeatures = async (userInfo, isProvider) => {
        let role = '';
        if (isProvider) {
            role = CONSTANT.ROLE.REFERRING_PROVIDER
        } else {
            role = CONSTANT.ROLE.PATIENT
        }
        const payload = { Role: role }
        const response = await authService.getSubscriptionFeature(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error)
            return;
        }

        const subscriptionFeatures = data || '{}';
        GLOBAL.SUBSCRIPTION_FEATURES = subscriptionFeatures.Features;
        return subscriptionFeatures.Features;
    }

    const onClickSignUp = (e) => {
        e.preventDefault();
        // setRedirectToSignUp(true);
        redirectKeepingQueryParams(routes.signUp);
    };

    useEffect(() => {
        if (subscription.current) {
            if (authenticated && !GLOBAL.URL_PARAMS.AUTO_LOGIN) {
                setRedirectToDashboard(true);
            }
        }

        return () => {
            subscription.current = false;
        };
    }, [authenticated]);

    if (redirectToDashboard) {
        return <Redirect to={`${routes.dashboard}${location?.search}`}/>;
    }

    if (redirectToSignUp) {
        return <Redirect to={`${routes.signUp}`}/>;
    }

    if (redirectToReports) {
        return <Redirect to={`${routes.myReports}`}/>;
    }

    const handleOnClickForgotPassword = () => {
        window.location.replace(`https://${GLOBAL.TENANT}.${CONSTANT.URLS.BASE_URL}/account/forgotpassword`);
    }

    const onClickChangeInputType = () => {
        if (inputType === 'text') {
            setInputType('password');
        } else {
            setInputType('text');
        }
    }

    const onClickTermOfServiceToggle = (e) => {
        e.preventDefault();
        setTermOfServiceModal(!termOfServiceModal);
    };

    const onClickPrivacyPolicyToggle = (e) => {
        e.preventDefault();
        setPrivacyPolicyModal(!privacyPolicyModal);
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className='signup_signin_container'>
                <img src={leftIcon} className='left_icon'/>
                <img src={doctorIcon} className='doctorimg'/>
                <img src={topRoundIcon} className='halfround'/>
                <img src={bottomRoundIcon} className='roundBottom'/>

                <div className="signup_signin_form max-w-40vw">
                    <Formik
                        initialValues={initialValuesForLogin()}
                        validationSchema={validationSchema()}
                        onSubmit={onSubmitLogin}
                    >
                        {({
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              values,
                              errors,
                              touched,
                              isSubmitting,
                          }) => (
                            <div>
                                <div className="color_logo_mmh">
                                    {GLOBAL.LOGO_URL ? (
                                        <img src={GLOBAL.LOGO_URL} alt="MMH Logo"/>) : (
                                        <img src={mmhColorLogo} alt="MMH Logo"/>)}
                                </div>
                                <div className="signup_signin_form_header">
                                    {/*<p> Registered {getUserIdentification()} </p>*/}
                                    <p>Existing patients Sign in</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div style={{marginBottom: "3rem"}}>
                                        <label className="signup_signin_input-label">E-mail</label>
                                        <div className="signin_signin_input-container">
                                            <span><i className="far fa-envelope icon-left"></i></span>
                                            <input
                                                className={`signup_signin_input-field ${invalidFormElement(
                                                    "Email",
                                                    errors,
                                                    values,
                                                    touched
                                                )}`}
                                                type="email"
                                                placeholder="Enter Your Email Address"
                                                name="Email"
                                                disabled={!!GLOBAL.URL_PARAMS.EMAIL_ADDRESS}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.Email}
                                            />
                                        </div>
                                        {errors.Email && touched.Email && (
                                            <div className="invalid-feedback">{errors.Email}</div>
                                        )}
                                    </div>
                                    <div style={{marginBottom: "3rem"}}>
                                        <label className="signup_signin_input-label">Password</label>
                                        <div className="signin_signin_input-container">
                                            <span><i className="fas fa-lock icon-left"></i></span>
                                            <input className={`signup_signin_input-field ${invalidFormElement(
                                                "Password",
                                                errors,
                                                values,
                                                touched
                                            )}`}
                                                   type={inputType}
                                                   placeholder="Enter Your Password"
                                                   name="Password"
                                                   autoComplete="off"
                                                   onChange={handleChange}
                                                   onBlur={handleBlur}
                                                   value={values.Password}
                                            />
                                            <span>
                                        {values.Password && inputType === 'password' && (
                                            <i onClick={onClickChangeInputType}
                                               className="fa fa-eye icon-right"/>
                                        )}
                                                {values.Password && inputType === 'text' && (
                                                    <i onClick={onClickChangeInputType}
                                                       className="fa fa-eye-slash icon-right"/>
                                                )}
                                    </span>
                                        </div>
                                        {errors.Password && touched.Password && (
                                            <div className="invalid-feedback">
                                                {errors.Password}
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={{marginBottom: "3rem"}}>
                                        <div className="paddingTop10">
                                            {/*metronics checkbox*/}
                                            {/*<label*/}
                                            {/*    className="kt-checkbox kt-checkbox--solid kt-checkbox--success signin-forgot-password">*/}
                                            {/*    <input type="checkbox"/>Remember me?*/}
                                            {/*    <span style={{ backgroundColor: "#0075B6" }}></span>*/}
                                            {/*</label>*/}
                                        </div>
                                        <div className="cursor-pointer">
                                            {/*<Link to={routes.forgot} className="signin-forgot-password">*/}
                                            <div onClick={handleOnClickForgotPassword}
                                                 className="signin-forgot-password">
                                                <Trans i18nKey="page.page.forgot-password"/>
                                            </div>
                                            {/*</Link>*/}
                                        </div>
                                    </div>
                                    <div style={{marginBottom: "2rem"}} className="signup_signin_submit_wrapper">
                                        <button
                                            type="submit"
                                            className="signup_signin_submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;
                                                    <Trans i18nKey="common.message.info.please-wait"/>
                                                </>
                                            ) : (
                                                i18n.t("common.form.sign-in")
                                            )}
                                        </button>
                                        {errorMessage && (
                                        <div className="kt-login__account text-center mt-2">
                                            {/*style={{ fontSize: "1.25rem" }}>*/}
                                            {/*<span className="kt-login__account-msg text-danger">*/}
                                            <span className="error-message text-danger">
                                        {errorMessage}
                                    </span>
                                        </div>
                                        )}
                                    </div>
                                </form>
                                <div className="signup_signin_registered">
                                    <div className="kt-login__account mmh-login-account cursor-pointer"
                                         onClick={onClickSignUp}>
                                <span className="kt-login__account-msg">
                                    <Trans i18nKey="common.form.no-account-yet"/>
                                </span>
                                        &nbsp;&nbsp;
                                        <a href={"!#"} rel="noopener noreferrer" className="kt-login__account-link">
                                            <Trans i18nKey="common.form.sign-up"/>!
                                        </a>
                                    </div>
                                </div>

                                {/*<div className="kt-login__account mmh-login-account cursor-pointer" onClick={onClickSignUp}>*/}
                                {/*    <span className="kt-login__account-msg">*/}
                                {/*        <Trans i18nKey="common.form.no-account-yet"/>*/}
                                {/*    </span>*/}
                                {/*    &nbsp;&nbsp;*/}
                                {/*    <a href={"!#"} rel="noopener noreferrer" className="kt-login__account-link">*/}
                                {/*        <Trans i18nKey="common.form.sign-up"/>!*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                <div className="signup_signin_terms_and_privacy_policy margin-top-2rem">
                                    <div className="kt-login__account mmh-login-terms">
                                <span className="kt-login__account-msg">
                                    <span
                                        className="cursor-pointer text-decoration-underline-hover"
                                        onClick={onClickTermOfServiceToggle}>
                                        Terms & Services <span> </span>
                                    </span>
                                    /
                                    <span
                                        className="cursor-pointer text-decoration-underline-hover"
                                        onClick={onClickPrivacyPolicyToggle}>
                                         <span> </span>Privacy Policy
                                    </span>
                                </span>
                                    </div>
                                </div>

                            </div>
                        )}
                    </Formik>
                    {termOfServiceModal && (
                        <DisplayUrlContent
                            headerTitle={"Terms and Service"}
                            open={termOfServiceModal}
                            url="https://vatb.injurycloud.com/account/termsofservice"
                            onToggleModal={onClickTermOfServiceToggle}>
                        </DisplayUrlContent>
                    )}
                    {privacyPolicyModal && (
                        <DisplayUrlContent
                            headerTitle={"Privacy Policy"}
                            open={privacyPolicyModal}
                            url="https://vatb.injurycloud.com/account/privacypolicy"
                            onToggleModal={onClickPrivacyPolicyToggle}>
                        </DisplayUrlContent>
                    )}
                </div>

                {/*<SweetAlert*/}
                {/*    confirmBtnCssClass="btn btn-sm btn-success"*/}
                {/*    showConfirm= {true}*/}
                {/*    showCancel={false}*/}
                {/*    show={showLogoutInfo}*/}
                {/*    title=""*/}
                {/*    type="info"*/}
                {/*    onConfirm={() => {*/}
                {/*        setShowLogoutInfo(false);*/}
                {/*        GLOBAL.IS_LOGOUT_FOR_INACTIVE = false;*/}
                {/*    }}*/}
                {/*    confirmBtnText="Ok"*/}
                {/*    closeOnClickOutside={false}>*/}
                {/*    <div className='tele_sweetalert_message'>*/}
                {/*        You are logged out for being inactive.*/}
                {/*    </div>*/}
                {/*</SweetAlert>*/}
            </div>
        </div>

    );
}

export default SignIn;
