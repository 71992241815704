import React, {useContext, useEffect} from 'react';
import {Route, Switch, HashRouter, useLocation, useHistory} from 'react-router-dom';

import routes from '../constants/Routes';
import PrivateRoute from "./shared/PrivateRoute";
import SignIn from "./sign-in/SignIn";
import Dashboard from "./dashboard/Dashboard";
import Unauthorized from "./unauthorized/Unauthorized";
import NotFound from "./not-found/NotFound";
// import UserProfile from "./user-profile/UserProfile";
import MyAssessments from "./my-assessments/MyAssessments";
import SignUp from "./sign-up/SignUp";
import Forgot from "./forgot/Forgot";
import EditAssessmentDetails from "./edit-assessment/EditAssessmentDetails";
// import ChangePassword from "./user-profile/ChangePassword";
// import MyTelemedicineInvitation from "./my-telemedicine-invitation/MyTelemedicineInvitation";
import MySubscription from "./user-profile/subscriptions/MySubscription";
import MyExercises from "./my-exercises/MyExercises";
import MyCalendar from "./my-calendar/MyCalendar";
import MyReports from "./my-reports/MyReports";
// import MyIntake from "./my-intake/MyIntake";
// import MyDocuments from "./my-documents/MyDocuments";
// import MyPainlogs from "./my-painlogs/MyPainlogs";
// import MyInsight from "./my-painlogs/MyInsight";
// import NewScreenings from "./screenings/NewScreenings";
// import MyMedicalHistory from "./my-medical-history/MyMedicalHistory";
import EditAssessmentOld from "./edit-assessment/EditAssessmentOld";
import EditAssessment from './editAssessmentNew/EditAssessment'
// import MyStatistics from "./my-statistics/MyStatistics";
// import TeamsEditAssessment from "./edit-assessment/team-edit-assessment/EditAssessmentOld";
import MyCards from "./my-cards/MyCards";
import CreditCardForm from "./sign-up/CreditCardForm";
import AppContext from "../contexts/AppContext";
import {redirectTo} from "./shared/chatbotNew/ChatServices";
import ShowAllQuestions from "./show-all-questions/ShowAllQuestions";
import DeleteAccount from "./deleteAccount/DeleteAccount";
import PackageDetailsPageWrapper from "./package/PackageDetailsPageWrapper";
import Billing from "./billing/Billing";
import Packages from "./package/PackagesPageWrapper";
import Plans from "./plans/Plans";

const AppRoutes = () => {
    const { authenticated } = useContext(AppContext);

    const SECOND = 1000;
    const MINUTES = 60 * SECOND;
    const TIME_WHEN_WILL_BE_LOGOUT = 30 * MINUTES;

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const events = ['mousemove', 'click', 'keypress', 'touchstart'];

        events.forEach(event => {
            window.addEventListener(event, updateLastActivityTime);
        });

        handleLogout();

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, updateLastActivityTime);
            });
        };
    }, [location.pathname, authenticated]);

    useEffect(() => {
        const interval = setInterval(() => {
            handleLogout();
        }, SECOND * 60);

        return () => clearInterval(interval);
    }, []);

    const handleLogout = () => {
        const lastActivity = localStorage.getItem('lastActivity');
        const now = new Date().getTime();

        if (lastActivity && (now - parseInt(lastActivity)) > TIME_WHEN_WILL_BE_LOGOUT) {
            logoutUser();
        }
    }

    const updateLastActivityTime = () => {
        const now = new Date().getTime();
        localStorage.setItem('lastActivity', now.toString());
    };

    const logoutUser = () => {
        if(!(history.location.hash === "#/")) {
            redirectTo("/")
        }
    };

    return (
        <HashRouter>
            <Switch>
                <Route exact path={routes.landing} component={SignIn}/>
                <Route exact path={routes.signIn} component={SignIn}/>
                <Route exact path={routes.signUp} component={SignUp}/>
                <Route exact path={routes.forgot} component={Forgot}/>
                <Route exact path={routes.deleteAccount} component={DeleteAccount}/>
                <Route exact path={routes.packageDetails} component={PackageDetailsPageWrapper}/>
                <Route exact path={routes.plans} component={Plans}/>
                <Route exact path={routes.packages} component={Packages}/>
                <Route exact path={routes.billing} component={Billing}/>
                {/*<Route exact path={routes.otp} component={Otp}/>*/}
                <PrivateRoute exact path={routes.dashboard} component={Dashboard}/>
                {/*<PrivateRoute exact path={routes.userProfile} component={UserProfile}/>*/}
                {/*<PrivateRoute exact path={routes.changePassword} component={ChangePassword}/>*/}
                <PrivateRoute exact path={`${routes.myAssessments}/:isShowCompletedAssessments`} component={MyAssessments}/>
                <PrivateRoute exact path={routes.assessment} component={EditAssessmentDetails}/>
                {/*<PrivateRoute exact path={routes.myTelemedicineInvitation} component={MyTelemedicineInvitation}/>*/}
                <PrivateRoute exact path={`${routes.myExercises}/:testId`} component={MyExercises}/>
                <PrivateRoute exact path={routes.mySubscription} component={MySubscription}/>
                <PrivateRoute exact path={routes.myCalendar} component={MyCalendar}/>
                <PrivateRoute exact path={routes.myReports} component={MyReports}/>
                {/*<PrivateRoute exact path={routes.myIntake} component={MyIntake}/>*/}
                {/*<PrivateRoute exact path={routes.myDocuments} component={MyDocuments}/>*/}
                {/*<PrivateRoute exact path={routes.myPainlogs} component={MyPainlogs}/>*/}
                {/*<PrivateRoute exact path={routes.myInsight} component={MyInsight}/>*/}
                {/*<PrivateRoute exact path={routes.newScreenings} component={NewScreenings}/>*/}
                {/*<PrivateRoute exact path={routes.myMedicalHistory} component={MyMedicalHistory}/>*/}
                {/*<PrivateRoute exact path={routes.myStatistics} component={MyStatistics}/>*/}
                <PrivateRoute exact path={`${routes.editAssessmentOld}/:testId/:bodyRegionList`} component={EditAssessmentOld}/>
                <PrivateRoute exact path={`${routes.editAssessment}/:testId`} component={EditAssessment}/>
                <PrivateRoute exact path={`${routes.showAllQuestion}/:testId/:bodyRegionList`} component={ShowAllQuestions}/>
                {/*<PrivateRoute exact path={`${routes.teamsEditAssessment}/:testId/:bodyRegionList`} component={TeamsEditAssessment}/>*/}
                <PrivateRoute exact path={`${routes.cards}`} component={MyCards}/>
                <PrivateRoute exact path={`${routes.addCard}`} component={CreditCardForm}/>
                <Route exact path={routes.unauthorized} component={Unauthorized}/>
                <Route exact component={NotFound}/>
            </Switch>
        </HashRouter>
    )
};

export default AppRoutes;