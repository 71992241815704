import React, { useContext, useEffect, useState } from "react";
import DirectToConsumerService from "../../services/DirectToConsumerService";
import { extractCountryAndStates } from "./billing-service";
import {
    setCountries, setCountryStates, setError, setStates, setValue
} from "../../features/directToConsumer/directToConsumerSlice";
import { useDispatch, useSelector } from "react-redux";
import answerValidationService from "../../services/answerValidation/AnswerValidationService";
import AppContext from "../../contexts/AppContext";
import GLOBAL from "../../global";

const BillingAddress = ({ isLoadingCountry, setIsLoadingCountry }) => {
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    const { userInfo} = useContext(AppContext);

    const directToConsumerState = useSelector((state) => state.directToConsumer);
    const name = directToConsumerState?.name || {};
    const email = directToConsumerState?.email || {};
    const countriesValue = directToConsumerState?.countries.value || [];
    const countryStatesValue = directToConsumerState?.countryStates.value || {};
    const selectedCountry = directToConsumerState?.selectedCountry || {};
    const statesValue = directToConsumerState?.states.value || [];
    const postCode = directToConsumerState?.postCode || {};
    const streetAddress = directToConsumerState?.streetAddress || {};
    const selectedState = directToConsumerState?.selectedState || {};
    const isClickedPlaceOrder = directToConsumerState?.isClickedPlaceOrder || false;

    const patientEmail = (GLOBAL.USER_INFO && GLOBAL.USER_INFO["EmailAddress"]) || "";

    useEffect(() => {
        getAllCountriesWithStates().then(() => {
            dispatch(setValue({ key: 'name', value: userInfo?.FullName || '' }));
            dispatch(setValue({ key: 'email', value: patientEmail || '' }));
        });
    }, []);

    const getAllCountriesWithStates = async () => {
        let response = await DirectToConsumerService.getAllCountryState();

        if (response.success) {
            const data = response.data || [];
            const { countries, countryStates } = extractCountryAndStates(data);
            dispatch(setCountries(countries));
            dispatch(setCountryStates(countryStates));

            // Preselect "United States" if available; otherwise, preselect the first country
            const defaultCountry = countries.includes("United States") ? "United States" : countries[0];
            if (defaultCountry) {
                handleOnChangeAnswer('selectedCountry', defaultCountry);
                dispatch(setStates(countryStates[defaultCountry]));
            }
        } else {
            dispatch(setCountries([]));
            dispatch(setCountryStates({}));
            setIsError(true);
        }

        setIsLoadingCountry(false);
    };

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        handleOnChangeAnswer('selectedCountry', selectedCountry);
        setStates(countryStatesValue[selectedCountry] || []);
    };

    const handleOnChangeAnswer = (key, value) => {
        dispatch(setValue({ key, value }));
        if(isClickedPlaceOrder) {
            let validation = answerValidationService.validate("", directToConsumerState[key]?.responseType, value);
            dispatch(setError({
                name: key,
                error: {
                    isValid: validation.valid,
                    message: validation.message
                }
            }))
        }
    }

    return (
        <div className="billing-address">
            {
                (isLoadingCountry|| isError)
                    ?
                    (
                        <div className="position-center">
                            {isLoadingCountry && <i className="fa fa-spinner fa-spin kt-font-primary font-size-4-rem"></i>}
                            {isError && <div className="font-size-2-rem">Error occurred while fetching data</div>}
                        </div>
                    )
                    : (
                        <div>
                            <h5 className="billing-address__title">Billing Address</h5>

                            <div className="billing-address__form">
                                <div className="billing-address__field">
                                    <label className="billing-address__label">Email</label>
                                    <div className="billing-address__input-group">
                                        <input
                                            type="email"
                                            className="billing-address__input"
                                            value={email?.value || ''}
                                            onChange={(e) => handleOnChangeAnswer('email', e.target.value)}
                                        />
                                        {isClickedPlaceOrder && !email?.error?.isValid && (
                                            <span
                                                className="form-text text-muted kt-font-danger">* {email?.error?.message || ''}</span>
                                        )}
                                    </div>
                                </div>


                                <div className="billing-address__field">
                                    <label className="billing-address__label">Name</label>
                                    <div className="billing-address__input-group">
                                        <input
                                            type="text"
                                            className="billing-address__input"
                                            value={name?.value || ''}
                                            onChange={(e) => handleOnChangeAnswer('name', e.target.value)}
                                        />
                                        {isClickedPlaceOrder && !name?.error?.isValid && (
                                            <span
                                                className="form-text text-muted kt-font-danger">* {name?.error?.message || ''}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="billing-address__field">
                                    <label className="billing-address__label">Country</label>
                                    <div className="billing-address__input-group">
                                        <select
                                            className="billing-address__select"
                                            value={selectedCountry?.value || ''}
                                            onChange={handleCountryChange}
                                        >
                                            <option value="" disabled>
                                                Select a country
                                            </option>
                                            {countriesValue.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                        {isClickedPlaceOrder && !selectedCountry?.error?.isValid && (
                                            <span
                                                className="form-text text-muted kt-font-danger">* {selectedCountry?.error?.message || ''}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="billing-address__section">
                                {/*<p className="billing-address__subtitle">Billing Address</p>*/}

                                <div className="billing-address__field">
                                    <label className="billing-address__label">State</label>
                                    <div className="billing-address__input-group">
                                        <select
                                            className="billing-address__select"
                                            value={selectedState?.value || ''}
                                            onChange={(e) => handleOnChangeAnswer('selectedState', e.target.value)}
                                        >
                                            <option value="" disabled>
                                                Select a state
                                            </option>
                                            {statesValue.map((state, index) => (
                                                <option key={index} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select>
                                        {isClickedPlaceOrder && !selectedState?.error?.isValid && (
                                            <span
                                                className="form-text text-muted kt-font-danger">* {selectedState?.error?.message || ''}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="billing-address__field">
                                    <label className="billing-address__label">Post Code</label>
                                    <div className="billing-address__input-group">
                                        <input
                                            type="text"
                                            className="billing-address__input"
                                            value={postCode?.value || ''}
                                            onChange={(e) => handleOnChangeAnswer('postCode', e.target.value)}
                                        />
                                        {(isClickedPlaceOrder && !postCode?.error?.isValid) && (
                                            <span
                                                className="form-text text-muted kt-font-danger">* {postCode?.error?.message || ''}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="billing-address__field">
                                    <label className="billing-address__label">Street Address</label>
                                    <div className="billing-address__input-group">
                                        <input
                                            type="text"
                                            className="billing-address__input"
                                            value={streetAddress?.value || ''}
                                            onChange={(e) => handleOnChangeAnswer('streetAddress', e.target.value)}
                                        />
                                        {isClickedPlaceOrder && !streetAddress?.error?.isValid && (
                                            <span
                                                className="form-text text-muted kt-font-danger">* {streetAddress?.error?.message || ''}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }

        </div>
    );
};

export default BillingAddress;