import React, { Component } from 'react';
import AppContext from './AppContext';
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";
import { clearUrlParams } from "../utils/Utils";
import storageService from "../utils/StorageService";

//TODO: Will replaced this with redux
class AppProvider extends Component {
    state = {
        authenticated: false,
        userInfo: null,
        isOpenPrecallTestBot: false,
        isAudioPlayEnabled: GLOBAL.AUDIO_PLAY_ENABLED,
        testId: "",
        isFullScreenVideo: false,
        isVideoUnmute: true,
        isAudioUnmute: false,
        biometricSelectedExercise: null,
        blockedUI: false,
        selectedNextMovement: false,
        startMovement: false,
        retakeLastMovement: false,
        videoRecordingPurpose: CONSTANT.VIDEO_RECORDING_PURPOSE.OTHER,
        isNextMovementSelected: false,
        exerciseGifUrl: '',
        exerciseGuideline: '',
        showTakeAssessmentModal: false,
        showLeftSideMenuInMobile: false,
        showTopNavigationInMobile: false,
        telemedicine: {
            isTelemedicineModalOpen: false,
            onCloseCallback: null,
            onInitializeCallback: null,
            telemedicineKeys: {
                invitationId: null,
                opentokApiKey: null,
                opentokToken: null,
                opentokSessionId: null,
                bodyRegionList: []
            },
        },
        assessmentTypeForMyAssessmentPage: {
            isFollowUp: false,
            isLastIncompletedAssessments: false,
        },
        assessmentDataForEditAssessment: {
            selectedTab: CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key, //Group id of groups such as DEMO, ADL, MEDICAL, CC, BIO, default DEMO
        }
    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const testId = localStorage.getItem("testId");
        if (userInfo) {
            GLOBAL.USER_INFO = userInfo;
            this.authenticate(true);
            this.storeUserInfo(userInfo);
            this.storeTestId(testId);
        }
    }

    setBlockedUI = (blockedUI) => {
        this.setState({ blockedUI: blockedUI });
    };

    selectNextMovement = (selectedNextMovement) => {
        this.setState({ selectedNextMovement: selectedNextMovement });
    };

    setStartMovement = (startMovement) => {
        this.setState({ startMovement: startMovement });
    };

    authenticate = (authenticated) => {
        this.setState({ authenticated: authenticated });
    };

    deAuthenticate = () => {
        ////TODO: HAVE TO DECOUPLE Permissions from GLOBAL.USER_INFO
        this.setState({ authenticated: false, userInfo: { Permissions: GLOBAL.USER_INFO?.Permissions || {}} });
        GLOBAL.USER_INFO = { Permissions: GLOBAL.USER_INFO?.Permissions || {}};
        // this.setState({ authenticated: false, userInfo: null });
        // GLOBAL.USER_INFO = null;
        GLOBAL.ASSESSMENT = null;
        GLOBAL.OUTCOME_ASSESSMENT = [];
        GLOBAL.SAVED_ANSWERS = [];
        GLOBAL.ALREADY_CALLED_API = false;
        GLOBAL.MOVEMENT_CANCEL = false;
        GLOBAL.SAVE_MOVEMENTS = [];
        GLOBAL.BIOMETRIC_PROGRESS = 0;
        GLOBAL.IS_WELCOME_VOICE_PLAYED = false;
        GLOBAL.SHOULDER_TO_SHOULDER_DISTANCE = '';

        clearUrlParams();

        localStorage.removeItem("userInfo");
        storageService.removeItem(CONSTANT.JWT.TOKEN_KEY);
        storageService.removeItem(CONSTANT.REFRESH_TOKEN.KEY_NAME);
    };

    storeUserInfo = (userInfo) => {
        this.setState({ userInfo: userInfo });
    };

    storeTestId = (testId) => {
        this.setState({ testId: testId });
    };

    storeUserInfoToLocalStorage = () => {
        localStorage.removeItem("userInfo");
        localStorage.setItem("userInfo", JSON.stringify(GLOBAL.USER_INFO));
    };

    storeTestIdToLocalStorage = (testId) => {
        localStorage.removeItem("testId");
        localStorage.setItem("testId", testId);
    }

    openPrecallTestBot = (open = false) => {
        this.setState({ isOpenPrecallTestBot: open });
    };

    audioPlayEnabled = (open = true) => {
        this.setState({ isAudioPlayEnabled: open });
    };

    toggleFullScreenVideo = (value) => {
        this.setState({ isFullScreenVideo: value });
    };

    toggleVideoElementDisabled = (value) => {
        this.setState({ isVideoElementDisabled: value });
    };

    toggleVideoUnmute = (value) => {
        this.setState({ isVideoUnmute: value });
    };

    toggleAudioUnmute = (value) => {
        this.setState({ isAudioUnmute: value });
    };

    storeBiometricSelectedExercise = (exercise) => {
        this.setState({ biometricSelectedExercise: exercise });
    };

    storeVideoRecordingPurpose = (value) => {
        this.setState({ videoRecordingPurpose: value });
    };

    setRetakeLastMovement = (retakeLastMovement) => {
        this.setState({ retakeLastMovement: retakeLastMovement });
    };

    toggleShowTakeAssessmentModal = () => {
        this.setState(prevState => ({
            ...prevState,
            showTakeAssessmentModal: !prevState.showTakeAssessmentModal
        }))
    }

    closeShowTakeAssessmentModal = () => {
        this.setState(prevState => ({
            ...prevState,
            showTakeAssessmentModal: false
        }))
    }

    toggleShowLeftSideMenuInMobile = () => {
        this.setState((prevState => ({
            ...prevState,
            showLeftSideMenuInMobile: !prevState.showLeftSideMenuInMobile
        })))
    }

    toggleShowTopNavigationInMobile = () => {
        this.setState((prevState => ({
            ...prevState,
            showTopNavigationInMobile: !prevState.showTopNavigationInMobile
        })))
    }

    // setIsNextMovementSelected = () => {
    //     this.setState({isNextMovementSelected: !isNextMovementSelected});
    // }

    setExerciseGifUrl = (gifUrl) => {
        this.setState({exerciseGifUrl: gifUrl});
    }

    setExerciseGuideline = (guideline) => {
        this.setState({exerciseGuideline: guideline});
    }

    toggleShowTelemedModal = () => {
        this.setState((prevState) => ({
            ...prevState,
            telemedicine: {
                ...prevState.telemedicine,
                isTelemedicineModalOpen: !prevState.telemedicine.isTelemedicineModalOpen
            }
        }))
    }

    setTelemedicineProperties = (invitationId, bodyRegionList, onInitialize, onClose) => {
        this.setState((prevState) => ({
            ...prevState,
            telemedicine: {
                ...prevState.telemedicine,
                onCloseCallback: onClose,
                onInitializeCallback: onInitialize,
                telemedicineKeys: {
                    ...prevState.telemedicine.telemedicineKeys,
                    invitationId: invitationId,
                    bodyRegionList: bodyRegionList
                }
            }
        }))
    }

    setOpenTokApiKey = (keys) => {
        this.setState((prevState) => ({
            ...prevState,
            telemedicine: {
                ...prevState.telemedicine,
                telemedicineKeys: {
                    ...prevState.telemedicine.telemedicineKeys,
                    opentokApiKey: keys?.opentokApiKey,
                    opentokToken: keys?.opentokToken,
                    opentokSessionId: keys?.opentokSessionId,

                }
            }
        }))
    }

    resetTelemedicineKeys = () => {
        this.setState((prevState) => ({
            ...prevState,
            telemedicine: {
                ...prevState.telemedicine,
                telemedicineKeys: {
                    ...prevState.telemedicine.telemedicineKeys,
                    invitationId: null,
                    opentokApiKey: null,
                    opentokToken: null,
                    opentokSessionId: null,
                    bodyRegionList: []
                }
            }
        }))
    }

    // Method to open the Telemedicine modal and set the callbacks
    openTelemedicineModal = () => {
        this.setState(prevState => ({
            ...prevState,
            telemedicine: {
                ...prevState.telemedicine,
                isTelemedicineModalOpen: !prevState.telemedicine.isTelemedicineModalOpen,
                // onCloseCallback: onClose,
                // onInitializeCallback: onInitialize,
            }
        }));
    };

    // Method to close the Telemedicine modal and call the onClose callback
    closeTelemedicineModal = () => {
        const { onCloseCallback } = this.state.telemedicine;
        if (onCloseCallback) {
            onCloseCallback();
        }
        this.setState(prevState => ({
            ...prevState,
            telemedicine: {
                ...prevState.telemedicine,
                isTelemedicineModalOpen: false,
                onCloseCallback: null,
                onInitializeCallback: null,
            }
        }));
    };

    setFollowupForAssessmentPage = (assessmentType) => {
        this.setState(prevState => ({
            ...prevState,
            assessmentTypeForMyAssessmentPage: {
                ...prevState.assessmentTypeForMyAssessmentPage,
                isFollowUp: assessmentType,
                isLastIncompletedAssessments: false
            }
        }))
    }

    setLastIncompletedAssessmentsForAssessmentPage = (assessmentType) => {
        this.setState(prevState => ({
            ...prevState,
            assessmentTypeForMyAssessmentPage: {
                ...prevState.assessmentTypeForMyAssessmentPage,
                isLastIncompletedAssessments: assessmentType,
                isFollowUp: false
            }
        }))
    }

    resetAssessmentTypeForMyAssessmentPage = () => {
        this.setState(prevState => ({
            ...prevState,
            assessmentTypeForMyAssessmentPage: {
                isFollowUp: false,
                isLastIncompletedAssessments: false,
            }
        }))
    }

    setSelectedTabForEditAssessment = (selectedTab = 1) => {
        this.setState(prevState => ({
            ...prevState,
                assessmentDataForEditAssessment: {
                    selectedTab: selectedTab
            }
        }))
    }

    resetSelectedTabForEditAssessment = () => {
        this.setState(prevState => ({
            ...prevState,
            assessmentDataForEditAssessment: {
                selectedTab: CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
            }
        }))
    }

    render() {
        const { children } = this.props;
        return (
            <AppContext.Provider value={{
                ...this.state,
                authenticate: this.authenticate,
                deAuthenticate: this.deAuthenticate,
                storeUserInfo: this.storeUserInfo,
                storeUserInfoToLocalStorage: this.storeUserInfoToLocalStorage,
                storeTestId: this.storeTestIdToLocalStorage,
                openPrecallTestBot: this.openPrecallTestBot,
                audioPlayEnabled: this.audioPlayEnabled,
                toggleFullScreenVideo: this.toggleFullScreenVideo,
                toggleVideoUnmute: this.toggleVideoUnmute,
                toggleAudioUnmute: this.toggleAudioUnmute,
                storeBiometricSelectedExercise: this.storeBiometricSelectedExercise,
                storeVideoRecordingPurpose: this.storeVideoRecordingPurpose,
                setBlockedUI: this.setBlockedUI,
                selectNextMovement: this.selectNextMovement,
                setStartMovement: this.setStartMovement,
                setRetakeLastMovement: this.setRetakeLastMovement,
                toggleVideoElementDisabled: this.toggleVideoElementDisabled,
                setIsNextMovementSelected: this.setIsNextMovementSelected,
                setExerciseGifUrl: this.setExerciseGifUrl,
                setExerciseGuideline: this.setExerciseGuideline,
                toggleShowTakeAssessmentModal: this.toggleShowTakeAssessmentModal,
                setOpenChatBot: this.setOpenChatBot,
                // setBotOptions: this.setBotOptions,
                toggleShowLeftSideMenuInMobile: this.toggleShowLeftSideMenuInMobile,
                toggleShowTopNavigationInMobile: this.toggleShowTopNavigationInMobile,
                toggleShowTelemedModal: this.toggleShowTelemedModal,
                setTelemedicineProperties: this.setTelemedicineProperties,
                setOpenTokApiKey: this.setOpenTokApiKey,
                resetTelemedicineKeys: this.resetTelemedicineKeys,
                openTelemedicineModal: this.openTelemedicineModal,
                closeTelemedicineModal: this.closeTelemedicineModal,
                closeShowTakeAssessmentModal: this.closeShowTakeAssessmentModal,
                setFollowupForAssessmentPage: this.setFollowupForAssessmentPage,
                setLastIncompletedAssessmentsForAssessmentPage: this.setLastIncompletedAssessmentsForAssessmentPage,
                resetAssessmentTypeForMyAssessmentPage: this.resetAssessmentTypeForMyAssessmentPage,
                setSelectedTabForEditAssessment: this.setSelectedTabForEditAssessment,
                resetSelectedTabForEditAssessment: this.resetSelectedTabForEditAssessment
            }}>
                {children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;
