import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import { Trans } from "react-i18next";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import { playAudio } from "../../../utils/Utils";
import routes from "../../../constants/Routes";
import GLOBAL from "../../../global";
import CONSTANT from "../../../constants/Constant";

import AppContext from "../../../contexts/AppContext";
import DefaultUserPic from "../../../assets/img/users/img.png";
import DefaultUserColorPic from "../../../assets/img/users/defaultColorImg.svg";

import flagUsa from "../../../assets/img/flag/flag-usa.svg";
import flagSpain from "../../../assets/img/flag/flag-spain.svg";

import Language from "../../../assets/img/utils/language.png";
import MovementVoice from "../MovementVoice";
import useSweetAlert from "../sweetAlertComponent/useSweetAlert";
import authService from "../../../services/AuthService";
import {redirectTo} from "../chatbotNew/ChatServices";
import Header from "./Header";

/**
 * Main header component for the application.
 *
 * This header is displayed across the entire site except for the login, register, and package pages,
 * which have their own dedicated headers. The `MainHeaderWrapper` dynamically adjusts its content
 * based on the user's authentication status and preferences such as language and audio settings.
 *
 * @returns {JSX.Element} The main header component.
 */

const MainHeaderWrapper = () => {

    const {
        authenticated,
        userInfo,
        deAuthenticate,
        isAudioPlayEnabled,
        audioPlayEnabled,
        // toggleShowLeftSideMenuInMobile,
        toggleShowTopNavigationInMobile
    } = useContext(AppContext);
    const { showSweetAlert, hideSweetAlert, setOkLoading } = useSweetAlert();
    const [languageDropdownShow, setLanguageDropdownShow] = useState(false);
    const [profileDropdownShow, setProfileDropdownShow] = useState(false);
    // const [showOption, setShowOption] = useState(false)
    const [language, setLanguage] = useState(flagUsa);

    // const profileOptionsHandler = (option, value) => {
    //     const profileOptionsCopy = {...profileOptions, [option]: !value}
    //     setProfileOptions(profileOptionsCopy)
    // }

    const onClickSignOut = () => {
        playAudio('remove').then();
        GLOBAL.AUDIO_PLAY_ENABLED = false;
        audioPlayEnabled && audioPlayEnabled(false);
        deAuthenticate();
        window.location.replace(`/#${routes.landing}`)
    };

    const onChangeLanguage = (lng) => e => {
        e.preventDefault();

        if (lng === CONSTANT.LANGUAGES.ENGLISH.value) {
            setLanguage(flagUsa);

        } else if (lng === CONSTANT.LANGUAGES.SPANISH.value) {
            setLanguage(flagSpain);
        }

        i18n.changeLanguage(lng).then();
    };

    // const onClickShowLangKey = () => {
    //     i18n.changeLanguage('cimode').then();
    // };

    const languageDropdownToggle = (e) => {
        e.preventDefault();

        setProfileDropdownShow(false);
        setLanguageDropdownShow(!languageDropdownShow);
    }

    const profileDropdownToggle = (e) => {
        e.preventDefault();

        setLanguageDropdownShow(false);
        setProfileDropdownShow(!profileDropdownShow);
    };

    // const showOptionHandler = () => {
    //     setShowOption(prevState => !prevState)
    //     setProfileDropdownShow(false)
    // }

    const onChangeAudioPlay = () => {
        playAudio('remove').then();
        GLOBAL.AUDIO_PLAY_ENABLED = !isAudioPlayEnabled;
        audioPlayEnabled && audioPlayEnabled(!isAudioPlayEnabled);
    };

    const onClickDeleteProfile = () => {
        setProfileDropdownShow(false);

        showSweetAlert({
            message: 'Are you sure you want to delete your profile?',
            type: CONSTANT.ALERT_TYPE.WARNING,
            showConfirmBtn: true,
            showCancelBtn: true,
            confirmBtnText: "Yes",
            cancelBtnText: "No",
            textWhileProcessingConfirmBtn: "Deleting...",
            onConfirm: async () => {
                setOkLoading(true);
                await authService.deleteProfile();
                setOkLoading(false);
                hideSweetAlert();
                redirectTo(routes.landing);
            },
            onCancel: () => {
                hideSweetAlert();
            },
        })
    }

    return (
        <Header>
            <div className="kt-header__topbar">
                {authenticated && (
                    <>
                        <UncontrolledDropdown
                            className="kt-margin-l-40 kt-header__topbar-item kt-header__topbar-item--langs"
                            isOpen={languageDropdownShow}
                            toggle={languageDropdownToggle}>
                            <DropdownToggle tag="a" className="kt-header__topbar-wrapper">
                        <span className="kt-header__topbar-icon">
                            <img src={Language} alt="Language" style={{width: "3rem", height: "3rem"}}/>
                        </span>
                            </DropdownToggle>
                            <DropdownMenu
                                className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim"
                                x-placement="bottom-end" style={{transform: "translate3d(-140px, 0px, 0px)"}}>
                                <DropdownItem className="kt-nav__item kt-nav__item--active">
                                    <a href={"!#"} rel="noopener noreferrer" className="kt-nav__link"
                                       onClick={onChangeLanguage(CONSTANT.LANGUAGES.ENGLISH.value)}>
                                <span className="kt-nav__link-icon">
                                    <img src={flagUsa} alt="Flag Pic"/>
                                </span>
                                        <span className="kt-nav__link-text">English</span>
                                    </a>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <div className="kt-header__topbar-item">
                            <div className="kt-header__topbar-wrapper">
                                <MovementVoice isAudioPlayEnabled={isAudioPlayEnabled}
                                               onChangeAudioPlay={onChangeAudioPlay}/>
                                {/*<EmmaVoice isAudioPlayEnabled={isAudioPlayEnabled} onChangeAudioPlay={onChangeAudioPlay} voiceId="checkbox-toggle-pill" />*/}
                            </div>
                        </div>


                        <UncontrolledDropdown
                            className="kt-header__topbar-item kt-header__topbar-item--user"
                            isOpen={profileDropdownShow}
                            toggle={profileDropdownToggle}>

                            <DropdownToggle tag="a" className="kt-header__topbar-wrapper">
                            <span className="kt-header__topbar-icon  kt-img-rounded">
                            <img className="kt-img-rounded" alt="Pic"
                                 src={GLOBAL.USER_INFO?.['ProfilePic'] ? GLOBAL.USER_INFO?.['ProfilePic'] : DefaultUserPic}/>
                            </span>
                            </DropdownToggle>

                            <DropdownMenu
                                className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl"
                                x-placement="bottom-end" style={{transform: "translate3d(-245px, 0px, 0px)"}}>
                                <div className="dashboard-header-user-profile">
                                    <div className="kt-user-card__avatar">
                                        <img className="kt-hidden- kt-img-rounded"
                                             style={{width: "51px", height: "51px"}} alt="Pic"
                                             src={GLOBAL.USER_INFO?.['ProfilePic'] ? GLOBAL.USER_INFO?.['ProfilePic'] : DefaultUserColorPic}/>
                                        <span
                                            className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden">S</span>
                                    </div>
                                    <div className="dashboard-header-user-profile-name ml-3">
                                        {userInfo && (userInfo.FullName)}
                                    </div>
                                </div>

                                <div className="dashboard-header-user-profile cursor-pointer"
                                     onClick={onClickDeleteProfile}
                                >
                                    <div className="dashboard-header-user-profile-name ml-3">
                                        <span>Delete My Profile</span>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}>

                                    {/*<UncontrolledDropdown*/}
                                    {/*    className="kt-header__topbar-item kt-header__topbar-item--user dashboard-header-user-icon mr-0 w-90"*/}
                                    {/*    isOpen={profileOptions.myProfile}*/}
                                    {/*    isOpen={profileOptions.myProfile}*/}
                                    {/*    direction="left"*/}
                                    {/*    toggle={() => profileOptionsHandler("myProfile", false)}>*/}
                                    {/*    <DropdownToggle tag="a" className="kt-header__topbar-wrapper w-100">*/}
                                    {/*        <div className="dashboard-header-profile kt-notification__item w-100">*/}
                                    {/*            <div className="kt-notification__item-icon kt-mt-5">*/}
                                    {/*                <i className="flaticon2-calendar-3 kt-font-success"/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="header-profile-line"></div>*/}
                                    {/*            <div className="kt-notification__item-details kt-ml-10">*/}
                                    {/*                <div className="kt-notification__item-title kt-font-bold">*/}
                                    {/*                    <Trans i18nKey="common.navbar.my-profile"/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="kt-notification__item-time" style={{fontSize: "12px"}}>*/}
                                    {/*                    <Trans i18nKey="common.navbar.account-settings-and-more"/>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </DropdownToggle>*/}
                                    {/*</UncontrolledDropdown>*/}

                                    {/*<UncontrolledDropdown*/}
                                    {/*    className="kt-header__topbar-item kt-header__topbar-item--user dashboard-header-user-icon mr-0 w-90"*/}
                                    {/*    isOpen={profileOptions.changePassword}*/}
                                    {/*    direction="left"*/}
                                    {/*    toggle={() => profileOptionsHandler("changePassword", profileOptions.changePassword)}>*/}
                                    {/*    <DropdownToggle tag="a" className="kt-header__topbar-wrapper w-100">*/}
                                    {/*        <div className="kt-notification__item dashboard-header-profile w-100" >*/}
                                    {/*            <div className="kt-notification__item-icon kt-mt-5">*/}
                                    {/*                <i className="flaticon2-notepad kt-font-warning"/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="header-profile-line"></div>*/}
                                    {/*            <div className="kt-notification__item-details kt-ml-10">*/}
                                    {/*                <div className="kt-notification__item-title kt-font-bold">*/}
                                    {/*                    <Trans i18nKey="common.navbar.change-password"/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="kt-notification__item-time" style={{fontSize: "12px"}}>*/}
                                    {/*                    <Trans i18nKey="common.navbar.change-your-password"/>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </DropdownToggle>*/}
                                    {/*    <DropdownMenu className="po">*/}
                                    {/*        <div className="user-profile-container">*/}
                                    {/*            <div>*/}
                                    {/*                <div className="d-flex justify-content-between">*/}
                                    {/*                    <img src={crossButton} className="cursor-pointer"*/}
                                    {/*                         onClick={() => profileOptionsHandler("changePassword", profileOptions.changePassword)} alt="Close Button"/>*/}
                                    {/*                    <img src={editButton} alt="edit"/>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="d-flex justify-content-center align-items-center flex-column">*/}
                                    {/*                    <img src={lock} alt="Lock"/>*/}
                                    {/*                    <p>Change Your Password</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="user-profile-input-box-container" >*/}
                                    {/*                <InputText placeholder="Old Password" />*/}
                                    {/*                <InputText placeholder="New Password" />*/}
                                    {/*                <InputText placeholder="Confirm Password" />*/}
                                    {/*                <div style={{marginTop: "3.5rem"}}>*/}
                                    {/*                    <button className="button rounded-btn user-profile-btn">Save</button>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </DropdownMenu>*/}
                                    {/*</UncontrolledDropdown>*/}
                                    {/*<Link className="kt-notification__item dropdown-item d-none" to={routes.mySubscription}>*/}
                                    {/*    <div className="kt-notification__item-icon kt-mt-5">*/}
                                    {/*        <i className="flaticon2-notepad kt-font-warning"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="kt-notification__item-details kt-ml-10">*/}
                                    {/*        <div className="kt-notification__item-title kt-font-bold">*/}
                                    {/*            <Trans i18nKey="common.navbar.my-subscription"/>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="kt-notification__item-time">*/}
                                    {/*            <Trans i18nKey="common.navbar.list-of-subscriptions"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</Link>*/}
                                    {/*<DropdownItem className="kt-notification__item d-none"*/}
                                    {/*              onClick={onClickShowLangKey}>*/}
                                    {/*    <div className="kt-notification__item-icon kt-font-info kt-mt-5">*/}
                                    {/*        <i className="flaticon2-list-2"/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="kt-notification__item-details kt-ml-10">*/}
                                    {/*        <div className="kt-notification__item-title kt-font-bold">*/}
                                    {/*            View Language Keys*/}
                                    {/*        </div>*/}
                                    {/*        <div className="kt-notification__item-time">*/}
                                    {/*            This will show the language the keys*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</DropdownItem>*/}

                                    {/* Will be hidden for desktop and will show at mobile to tab */}
                                    <div className="logout_btn_profile pt-4">
                                        <div className="kt-header__topbar-item">
                                            <div className="kt-header__topbar-wrapper mmh-logout">
                                                <button className="button rounded-btn dashboard-header-logout-btn"
                                                        onClick={onClickSignOut}>
                                                    <Trans i18nKey="common.actions.logout"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <div className="d-flex">
                            {/*<div className="kt-header-mobile__toolbar">*/}
                            {/*    <button id="kt_aside_mobile_toggler"*/}
                            {/*            className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"*/}
                            {/*            onClick={toggleShowLeftSideMenuInMobile}>*/}
                            {/*        <span></span>*/}
                            {/*    </button>*/}
                            {/*</div>*/}

                            <div className="kt-header-mobile__toolbar">
                                <button id="kt_aside_mobile_toggler"
                                        className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
                                        onClick={toggleShowTopNavigationInMobile}>
                                    <span></span>
                                </button>
                            </div>
                        </div>

                        {/* Will show in desktop and will be hidden for mobile to tab */}
                        <div className="kt-header__topbar-item logout_btn">
                            <div className="kt-header__topbar-wrapper mmh-logout">
                                <button className="button rounded-btn dashboard-header-logout-btn"
                                        onClick={onClickSignOut}>
                                    <Trans i18nKey="common.actions.logout"/>
                                </button>
                            </div>
                        </div>

                    </>
                )}
            </div>
        </Header>
)
};

export default MainHeaderWrapper;