import { createAsyncThunk } from "@reduxjs/toolkit";
import ExamService from "../../services/ExamService";
import { setGroupCompleted, updateCCQuestionAnswer } from "./editAssessmentSlice";
import CONSTANT from "../../constants/Constant";

export const fetchEditAssessmentDataAndInitData = createAsyncThunk(
    'assessment/fetchData',
    async (payload, thunkAPI) => {
        try {
            const response = await ExamService.getEditAssessmentData(payload);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCCQuestionAnswerAndSetCompletion = (answerId, answerValue) => (dispatch, getState) => {
    // Dispatch the action to update the CC question
    dispatch(updateCCQuestionAnswer({ answerId, answerValue }));

    // Access the current state
    const state = getState();
    const groupId = CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key;

    // Logic to determine if the group is completed
    const ccQuestion = state.editAssessment.ccQuestion;
    const isCompleted = ccQuestion?.answerId && ccQuestion?.answerValue;

    // Dispatch the action to mark the group as completed if applicable
    if (isCompleted) {
        dispatch(setGroupCompleted({ groupId, isCompleted: true }));
    }
};