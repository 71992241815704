import React, { useContext, useEffect, useState } from 'react';
import PackageTable from "../shared/table/PackageTable";
import MainHeaderWrapper from "../shared/layout/MainHeaderWrapper";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";
import CONSTANT from "../../constants/Constant";
import Footer from "../shared/layout/Footer";
import DirectToConsumerService from "../../services/DirectToConsumerService";
import { toast } from "react-toastify";
import { makeSerializable } from "../../utils/Utils";
import OrderHistoryData from "../../models/PackageOrderHistoryData";
import packageHistoryIcon from '../../assets/svg/Packages/package_history.svg'
import packagesIcon from '../../assets/svg/Packages/packages.svg'
import Packages from "../package/Packages";
import routes from "../../constants/Routes";
import useRedirect from "../../hooks/useRedirect";
import useD2C from "../../hooks/useD2C";

const tabs = {
    BILLING_HISTORY: 'billing_history',
    PACKAGES: 'packages'
}

const Plans = () => {
    const { handleIsD2CEnabled } = useD2C();
    const [packages, setPackages] = useState([]);
    const [activeTab, setActiveTab] = useState(tabs.PACKAGES);

    const { redirect } = useRedirect();

    useEffect(() => {
        const isRedirected = handleIsD2CEnabled();
        if(isRedirected) return;

        handleGetOrderHistory();
    }, []);

    const handleGetOrderHistory = async () => {
        let response = await DirectToConsumerService.orderHistory();
        let tempPackages = [];

        if(response.success) {
            tempPackages = response.data.map((packageData) => {
                return makeSerializable(new OrderHistoryData(packageData));
            })
            setPackages(tempPackages);
        } else {
            toast.error("Internal Server Error, Please refresh the page");
        }
    }

    const isActiveTab = (tab) => {
        return activeTab === tab;
    }

    const handleOnClickGetStarted = (packageData) => {
        redirect(`${routes.packageDetails}?${CONSTANT.QUERY_PARAM.PACKAGE_ID}=${packageData?.gId || 0}`);
    }

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <MainHeaderWrapper/>
                    <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.PACKAGE_HISTORY}>


                        <div className="w-100 my-3 mx-5 d-flex justify-content-center align-items-center">
                            <div className="col-12 col-md-12 col-lg-10">
                                <div className="tabs-wrapper">
                                    <div className="tabs-container">
                                        <div
                                            className=
                                                {
                                                    `tabs-container__tab tabs-container__tab--history
                                                    ${isActiveTab(tabs.BILLING_HISTORY) ? 'tabs-container__tab--active' : ''}
                                                    `
                                                }
                                            onClick={() => setActiveTab(tabs.BILLING_HISTORY)}
                                        >
                                            <img
                                                className={`tabs-container__icon ${!isActiveTab(tabs.BILLING_HISTORY) ? 'tabs-container__icon--inactive' : ''}`}
                                                src={packageHistoryIcon}
                                                 alt="package_history"
                                            />
                                            <p className="tabs-container__title">Purchase History</p>
                                        </div>

                                        <div
                                            className=
                                                {
                                                    `tabs-container__tab tabs-container__tab--history
                                                    ${isActiveTab(tabs.PACKAGES) ? 'tabs-container__tab--active' : ''}
                                                    `
                                                }
                                            onClick={() => setActiveTab(tabs.PACKAGES)}
                                        >
                                            <img
                                                className={`tabs-container__icon ${!isActiveTab(tabs.PACKAGES) ? 'tabs-container__icon--inactive' : ''}`}
                                                src={packagesIcon}
                                                alt="package_history"
                                            />
                                            <p className="tabs-container__title">Plans</p>
                                        </div>
                                    </div>
                                </div>


                                {activeTab === tabs.BILLING_HISTORY && (
                                    <PackageTable
                                        packages={packages}
                                    />
                                )}
                                {activeTab === tabs.PACKAGES && (
                                    <Packages
                                        bgClass={"bg-transparent"}
                                    />
                                )}
                            </div>
                        </div>

                    </BodyWithTopMenu>
                    <Footer/>
                </div>
            </div>
        </div>
    )
};

export default Plans;
