import React, {useContext, useEffect, useState} from 'react';
import TelemedicineDetails from "./TelemedicineDetails";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import GLOBAL from "../../../global"
import routes from "../../../constants/Routes";
import {getAllExerciseGuideline, getTelemedSessionId} from "../MovementModal/Services/AssessementService";
import {toast} from "react-toastify";
import AppContext from "../../../contexts/AppContext";
import WorkoutPng from "../../../assets/img/movement/workout.png";

const Telemedicine = () => {
    const { telemedicine, toggleShowTelemedModal, setOpenTokApiKey, openTelemedicineModal } = useContext(AppContext);
    const { telemedicineKeys, isTelemedicineModalOpen, onInitializeCallback} = telemedicine;
    const [isSelectedTrainingMovementGide, setIsSelectedTrainingMovementGide] = useState(false);

    const biometricExercisesGuideline = React.useRef(null);

    useEffect(() =>{
        if(telemedicineKeys?.invitationId && !telemedicineKeys?.opentokApiKey) {
            initTelemedicine();
        }
    }, [telemedicineKeys?.invitationId]);

    const initTelemedicine = async () => {
        let response = await getTelemedSessionId(telemedicineKeys?.invitationId);

        if(response?.success){
            let exerciseGuidelineAll = await getAllExerciseGuideline();

            if(exerciseGuidelineAll && exerciseGuidelineAll?.success){
                let tempKey = {};
                biometricExercisesGuideline.current = exerciseGuidelineAll?.data;

                tempKey.opentokApiKey = response?.data?.apiKey;
                tempKey.opentokToken = response?.data?.token;
                tempKey.opentokSessionId = response?.data?.sessionId;

                setOpenTokApiKey(tempKey);
                openTelemedicineModal();
                onInitializeCallback && onInitializeCallback();

            }
        }
        else{
            toast.error(response?.error);
        }
    }

    const closeBtn = <div className="movement-modal-header-element2">
        <div className="kt-header__topbar-wrapper mmh-logout">
            <button className="button rounded-btn bg-color-red" onClick={toggleShowTelemedModal}>
                Leave
            </button>
        </div>
    </div>

    return (
        <div className="">
            <Modal isOpen={isTelemedicineModalOpen}
               backdrop={'static'}
               className="modal-xl height-100svh kt-m0"
               modalClassName="mmh-movement-modal height-100svh">
                <div className="telemedicine_modal-container height-100svh">
                    <ModalHeader className="movement-modal" close={closeBtn} toggle={toggleShowTelemedModal}>
                        <div className="kt-header__brand ml-4">
                            <div className="kt-header__brand-logo">
                                <Link to={routes.dashboard}>
                                    {GLOBAL.LOGO_URL ? (<img src={GLOBAL.LOGO_URL} height="50" alt="MMH Logo"/>) : (
                                        <img src={GLOBAL.WHITE_LOGO_URL} height="50" alt="MMH Logo"/>)}
                                </Link>
                            </div>
                        </div>
                        <button
                            className="button rounded-btn bg-color-bright-light-green training-movement-btn"
                            onClick={() => setIsSelectedTrainingMovementGide((preValue) => !preValue)}
                        >
                            <img src={WorkoutPng} alt="Tranning movement"/>
                            <p>
                                Training Movement
                            </p>
                        </button>
                    </ModalHeader>
                    <ModalBody>
                        <TelemedicineDetails
                            biometricExercisesGuideline={biometricExercisesGuideline.current}
                            isSelectedTrainingMovementGide={isSelectedTrainingMovementGide}
                            setIsSelectedTrainingMovementGide={setIsSelectedTrainingMovementGide}
                            bodyRegionList={telemedicineKeys?.bodyRegionList}
                        />
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}

export default Telemedicine;
