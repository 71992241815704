// import "./SignUp.scss";
import "../../assets/sass/components/signUp/_signUp.scss";

import React, { useContext, useEffect, useState } from "react";
import {Redirect, useLocation} from "react-router-dom";
// import momentTZ from "moment-timezone";
import { Trans } from "react-i18next";

import routes from "../../constants/Routes";
import authService from "../../services/AuthService";
import GLOBAL from "../../global";
import AppContext from "../../contexts/AppContext";
import CONSTANT from "../../constants/Constant";
import DisplayUrlContent from "../shared/DisplayUrlContent";
import {getUserIdentification, makeSerializable, prepareEnabledSubscribedFeatures} from "../../utils/Utils";
import SignUp1 from "./SignUp1";
import { invalidFormElement } from "../../utils/DefaultInitialization";
import CreditCardForm from "./CreditCardForm";
import storageService from "../../utils/StorageService";
import useRedirectToBillingPage from "../../hooks/useRedirectToBillingPage";
import useRedirect from "../../hooks/useRedirect";
// import cardService from "../../services/CardService";

function SignUp() {
    const { authenticate, deAuthenticate, storeUserInfo, storeUserInfoToLocalStorage } = useContext(AppContext);
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);
    // const [redirectToScreening, setRedirectToScreening] = useState(false);
    const [signUpCreated, setSignUpCreated] = useState(false);
    const [termOfServiceModal, setTermOfServiceModal] = useState(false);
    const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);

    const { handleRedirectToBillingPageIfFromPackagePage } = useRedirectToBillingPage();
    const { redirectKeepingQueryParams } = useRedirect();
    const location = useLocation();

    const onSubmitSignUp = async (values, setIsSubmitting) => {
        // if (!GLOBAL.URL_PARAMS.INVITATION_ID) return;
        setIsSubmitting(true);
        setErrorMessage('');

        let query = new URLSearchParams(location?.search);
        let packageIdFromUrl = query.get(CONSTANT.QUERY_PARAM.PACKAGE_ID) || '';

        const tempValues = { ...values };
        tempValues.EmailAddress = tempValues.Email;
        tempValues.TimeZone = tempValues.TimeZone;
        tempValues.Height = +tempValues.height_feet * 12 + +tempValues.height_inch;
        delete tempValues.height_feet
        delete tempValues.height_inch
        // tempValues.TimeZone = momentTZ.tz.guess();
        tempValues.InvId = GLOBAL.URL_PARAMS.INVITATION_ID;

        delete tempValues.Email;
        delete tempValues.AcceptTerms;

        tempValues.IsD2CRegister = isD2CRegister();
        tempValues.PackageId = packageIdFromUrl;


        const response = await authService.register(tempValues);
        const { data, success, error } = response;

        if (!success) {
            setErrorMessage(error);
            setIsSubmitting(false);
            return;
        }

        storageService.addItem(CONSTANT.JWT.TOKEN_KEY, data?.Token);
        storageService.addItem(CONSTANT.REFRESH_TOKEN.KEY_NAME, data?.RefreshToken);

        const userInfo = data || '{}';
        const isProvider = userInfo['RoleName'] === CONSTANT.ROLE.PROVIDER;

        const subscriptionFeatures = await getSubscriptionFeatures(userInfo, isProvider);
        userInfo.Permissions = prepareEnabledSubscribedFeatures(subscriptionFeatures) || {};

        // if (userInfo.Permissions.IsNoFaultEnabled && !userInfo['IsIntakeSigned']) {
        //     window.location.href = `https://${GLOBAL.TENANT}.${CONSTANT.URLS.BASE_URL}/intake/info?patientId=${userInfo['ContactId']}&redirect=self&type=inclinic&invid=${GLOBAL.URL_PARAMS.INVITATION_ID}`;
        //     return;
        // }

        setIsSubmitting(false);
        setSignUpCreated(true);
        GLOBAL.USER_INFO = userInfo;
        storeUserInfo(GLOBAL.USER_INFO);
        storeUserInfoToLocalStorage();
        authenticate(true);

        if(handleRedirectToBillingPageIfFromPackagePage()) {
            return;
        }

        if(!GLOBAL?.IS_SUBSCRIPTION_ENABLED) {
            setRedirectToDashboard(true);
        } else {
            setShowCreditCardForm(true);
        }
    };

    const isD2CRegister = () => {
        let query = new URLSearchParams(location?.search);
        let priceFromUrl = query.get(CONSTANT.QUERY_PARAM.PRICE) || '';
        let assessmentNameFromUrl = query.get(CONSTANT.QUERY_PARAM.ASSESSMENT_NAME) || '';
        let packageIdFromUrl = query.get(CONSTANT.QUERY_PARAM.PACKAGE_ID) || '';
        let bodyRegionIdsFromUrl = query.get(CONSTANT.QUERY_PARAM.BODY_REGION_IDS) || '';

        let bodyRegionList = bodyRegionIdsFromUrl.split(',').map(Number).filter(Boolean) || [];

        let isPriceFromUrlNotEmpty = priceFromUrl !== '';
        let isPriceFromUrlNotZero = priceFromUrl !== '0';
        let isPriceFromUrlNotNaN = !isNaN(priceFromUrl);
        let isAssessmentNameFromUrlNotEmpty = assessmentNameFromUrl !== '';
        let isPackageIdFromUrlNotEmpty = packageIdFromUrl !== '';
        let isBodyRegionIdsFromUrlNotEmpty = bodyRegionIdsFromUrl !== '';
        let isBodyRegionListNotEmpty = bodyRegionList.length > 0;
        let isBodyRegionListLengthGreaterThanOrEqualToOne = bodyRegionList.length >= 1;

        if(isPriceFromUrlNotEmpty &&
            isPriceFromUrlNotZero &&
            isPriceFromUrlNotNaN &&
            isAssessmentNameFromUrlNotEmpty &&
            isPackageIdFromUrlNotEmpty &&
            isBodyRegionIdsFromUrlNotEmpty &&
            isBodyRegionListNotEmpty &&
            isBodyRegionListLengthGreaterThanOrEqualToOne)
        {
            return true;
        }

        return false;
    }

    // const onSubmitCreditCardForm = async (values, actions) => {
    //     setErrorMessage('');
    //
    //     const tempValues = { ...values };
    //     const userInfo = GLOBAL.USER_INFO;
    //     tempValues.PatientId = userInfo?.ContactId;
    //
    //     const response = await cardService.saveCreditCard(tempValues);
    //     const { data, success, error } = response;
    //
    //     if (!success) {
    //         setErrorMessage(error);
    //         actions.setSubmitting(false);
    //         return;
    //     }
    //     setRedirectToDashboard(true);
    // }

    const getSubscriptionFeatures = async (userInfo, isProvider) => {
        let role = '';
        if (isProvider) {
            role = 'ReferringProvider'
        } else {
            role = 'Patient'
        }
        const payload = { Role: role }
        const response = await authService.getSubscriptionFeature(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error)
            return;
        }

        const subscriptionFeatures = data || '{}';
        GLOBAL.SUBSCRIPTION_FEATURES = subscriptionFeatures.Features;
        return subscriptionFeatures.Features;
    }

    const onClickSignIn = (e) => {
        e.preventDefault();
        // setRedirectToLogin(true);
        redirectKeepingQueryParams(routes.signIn);
    };

    const onClickTermOfServiceToggle = (e) => {
        e.preventDefault();
        setTermOfServiceModal(!termOfServiceModal);
    };

    useEffect(() => {
        deAuthenticate();

        // eslint-disable-next-line
    }, []);

    if (redirectToLogin) {
        return <Redirect to={`${routes.landing}`}/>;
    }

    if (redirectToDashboard) {
        return <Redirect to={`${routes.dashboard}`}/>;
    }

    // if (redirectToScreening) {
    //     return <Redirect to={`${routes.newScreenings}`}/>;
    // }

    const onClickPrivacyPolicyToggle = (e) => {
        e.preventDefault();
        setPrivacyPolicyModal(prevState => !prevState);
    }

    // noinspection CheckTagEmptyBody
    return (
        <>
            <div>
                <div className="d-flex justify-content-center align-items-center">
                    {!showCreditCardForm
                        ? (
                            <SignUp1
                                onSubmitSignUp={onSubmitSignUp}
                                invalidFormElement={invalidFormElement}
                                onClickTermOfServiceToggle={onClickTermOfServiceToggle}
                                onClickPrivacyPolicyToggle={onClickPrivacyPolicyToggle}
                                signUpCreated={signUpCreated}
                                errorMessage={errorMessage}
                                isD2CRegistration={isD2CRegister()}
                            >
                                <div className="kt-login__account mmh-login-account text-center cursor-pointer"
                                     onClick={onClickSignIn}>
                                <span className="kt-login__account-msg">
                                    Are you a registered {getUserIdentification()}?
                                </span>
                                    &nbsp;&nbsp;
                                    <a
                                        href={"!#"}
                                        rel="noopener noreferrer"
                                        className="kt-login__account-link">
                                        <Trans i18nKey="common.form.sign-in"/>
                                    </a>
                                </div>
                            </SignUp1>
                        ) : (
                            <CreditCardForm
                                // onSubmitCreditCardForm={onSubmitCreditCardForm}
                                // invalidFormElement={invalidFormElement}
                                // errorMessage={errorMessage}
                        >
                            </CreditCardForm>
                        )
                    }
                </div>
            </div>
            {termOfServiceModal && (
                <DisplayUrlContent
                    open={termOfServiceModal}
                    url="https://vatb.injurycloud.com/account/termsofservice"
                    onToggleModal={onClickTermOfServiceToggle}>
                </DisplayUrlContent>
            )}
            {privacyPolicyModal && (
                <DisplayUrlContent
                    headerTitle={"Privacy Policy"}
                    open={privacyPolicyModal}
                    url="https://vatb.injurycloud.com/account/privacypolicy"
                    onToggleModal={(e) => onClickPrivacyPolicyToggle(e, "privacyPolicy")}>
                </DisplayUrlContent>
            )}
        </>
    );
}

export default SignUp;
