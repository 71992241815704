import axios from "./Axios";
import { success, error } from "./ResponseHandler";
import CONSTANT from "../constants/Constant";
import GLOBAL from "../global";

const { REACT_APP_PAYMENT_API_URL } = process.env;
const CREATE_CHECKOUT_SESSION = REACT_APP_PAYMENT_API_URL + `/api/${CONSTANT.CONTROLLER.PAYMENT}/create-checkout-session`;

class Payment {
    async createCheckoutSession(payload = {}) {
        try {
            payload.tenant = GLOBAL.TENANT;
            const response = await axios.post(CREATE_CHECKOUT_SESSION, payload);
            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new Payment();