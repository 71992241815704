import './App.scss';

import React, { Component, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

import AppRoutes from './AppRoutes';
import AppContext from "../contexts/AppContext";
import AppLoading from "./shared/loading/AppLoading";
import { getSubdomain, getUrlParameterByName } from "../utils/Utils";
import GLOBAL from "../global";
import CONSTANT from "../constants/Constant";
import authService from "../services/AuthService";
import storageService from "../utils/StorageService";
import exerciseService from "../services/ExerciseService";
import TakeAssessmentModal from "./shared/modal/TakeAssessmentModal";
import Telemedicine from "./shared/Telemedicine/Telemedicine";
import SweetAlertContainer from "./shared/sweetAlertComponent/SweetAlertContainer";
import { CallbackProvider } from "../contexts/CallbackContext";
import ChatBotWrapper from "./shared/chatbotNew/ChatBotWrapper";
import IntakeFormWrapper from "./shared/intake-form/IntakeFormWrapper/IntakeFormWrapper";
// import LiveChat from "./shared/LiveChat";

// import firebase from "./../firebase";

class App extends Component {
    static contextType = AppContext;

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = {
            appReady: false,
            hasError: false
        };

        this.getUrlParameters();
    }

    getUrlParameters() {
        GLOBAL.URL_PARAMS.IS_INTAKE_SIGNED = getUrlParameterByName('isintakesigned') === 'true';
        GLOBAL.URL_PARAMS.REDIRECT_TYPE = getUrlParameterByName('type') || '';
        GLOBAL.URL_PARAMS.REFER_TYPE = getUrlParameterByName('refertype') || '';
        GLOBAL.URL_PARAMS.INVITATION_ID = getUrlParameterByName('invid') || null;
        GLOBAL.URL_PARAMS.EMAIL_ADDRESS = getUrlParameterByName('email') || '';
        GLOBAL.URL_PARAMS.AUTO_LOGIN = getUrlParameterByName('autologin') === 'true';
        GLOBAL.URL_PARAMS.PATIENT_ID = getUrlParameterByName('patientid') || null;
        GLOBAL.URL_PARAMS.IS_FOLLOWUP = getUrlParameterByName('isfollowup') || '';
        GLOBAL.URL_PARAMS.TEST_ID = getUrlParameterByName('testid') || '';
        GLOBAL.URL_PARAMS.IS_REPORT_DOWNLOAD = getUrlParameterByName('isReportDownload') === 'true';

        let bodyRegionList = getUrlParameterByName('bodyRegionList') || '';
        bodyRegionList = bodyRegionList.split(',');
        GLOBAL.URL_PARAMS.BODY_REGION_LIST = bodyRegionList.map(x => +x);

        if (GLOBAL.URL_PARAMS.EMAIL_ADDRESS) {
            storageService.removeItem('providerLogin');
            storageService.addItem('providerLogin', 'false');
        }

        if (GLOBAL.URL_PARAMS.REFER_TYPE.toLowerCase() === CONSTANT.REDIRECT_TYPE.INCLINIC
            || GLOBAL.URL_PARAMS.REFER_TYPE.toLowerCase() === CONSTANT.REDIRECT_TYPE.INCLINIC_ENCOUNTER) {
            GLOBAL.AUDIO_PLAY_ENABLED = false;
        }
    }

    async _getMovementsGeneralAudios() {
        const response = await exerciseService.getGeneralAudios();
        const { success, data, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        let tempData = JSON.parse(data || []);

        GLOBAL.GENERAL_AUDIOS = tempData['GeneralAudios'] || [];
    };

    async _getTenantInfo() {
        const response = await authService.getTenantInfo();
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        return data || '[]';
    }

    async _getTenantFeature() {
        const response = await authService.getTenantFeature();
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        const tenantFeatures = data || '[]';
        GLOBAL.TENANT_FEATURES = tenantFeatures;
        return tenantFeatures;
    }

    _enableTenantFeatures(tenantFeatures) {
        const arrayOfFeatureEnable = Object.entries(CONSTANT.FEATURE_ENABLE);

        for (const [key] of arrayOfFeatureEnable) {
            for (let i = 0; i < tenantFeatures.length; i++) {
                if (key === tenantFeatures[i].Name) {
                    CONSTANT.FEATURE_ENABLE[key] = tenantFeatures[i]['IsEnabled'];
                }
            }
        }
    }

    async _getSubscriptionFeatures(userInfo, isProvider) {
        let role = '';
        if (isProvider) {
            role = CONSTANT.ROLE.REFERRING_PROVIDER
        } else {
            role = CONSTANT.ROLE.PATIENT
        }

        const payload = { Role: role };
        const response = await authService.getSubscriptionFeature(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        const subscriptionFeatures = data || '{}';
        GLOBAL.SUBSCRIPTION_FEATURES = subscriptionFeatures.Features;
        return subscriptionFeatures.Features;
    }

    async componentDidMount() {
        GLOBAL.DEVICE_WIDTH = +(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });

        // const context = this.context;
        this.setState({ appReady: false });
        GLOBAL.TENANT = getSubdomain() || GLOBAL.TENANT;
        const tenantInfo = await this._getTenantInfo();

        if (tenantInfo && tenantInfo.length > 0) {
            GLOBAL.LOGO_URL = tenantInfo[0]['LogoUrl'];
            let tenantInfoObj = tenantInfo[0] || {};
            GLOBAL.IS_SUBSCRIPTION_ENABLED = tenantInfoObj?.IsSubscriptionEnabled || false;
        }

        const tenantFeatures = await this._getTenantFeature();
        if (tenantFeatures && tenantFeatures.length > 0) {
            this._enableTenantFeatures(tenantFeatures);
            let userInfo = {};
            userInfo.Permissions = CONSTANT.FEATURE_ENABLE;
            GLOBAL.USER_INFO = {
                ...GLOBAL.USER_INFO,
                Permissions: userInfo?.Permissions
            };
            this.context.storeUserInfo(GLOBAL.USER_INFO);
        }

        if (GLOBAL.GENERAL_AUDIOS.length === 0) {
            // noinspection ES6MissingAwait
            this._getMovementsGeneralAudios();
        }

        this.setState({ appReady: true });
    }

    componentDidCatch(error, info) {
        console.log(error, info);
    }

    render() {
        const { appReady, hasError } = this.state;
        if (!appReady || hasError) return <AppLoading/>;

        return (
            <CallbackProvider>
                <Suspense fallback={<AppLoading/>}>
                    <ClearBrowserCacheBoundary auto fallback={<AppLoading/>} duration={60000}>
                        <AppRoutes />
                        <ToastContainer position="top-right" autoClose={3000} closeOnClick hideProgressBar={false}/>
                        <TakeAssessmentModal />
                        <ChatBotWrapper />
                        <Telemedicine />
                        <SweetAlertContainer />
                        <IntakeFormWrapper />
                        {/*<LiveChat />*/}
                    </ClearBrowserCacheBoundary>
                </Suspense>
            </CallbackProvider>
        );
    }
}

export default App;
