import { useContext } from "react";
import AppContext from "../contexts/AppContext";
import useRedirect from "./useRedirect";
import routes from "../constants/Routes";
import GLOBAL from "../global";

const useD2C = () => {
    const { userInfo, authenticated } = useContext(AppContext);
    const { redirect } = useRedirect();
    const IsD2CEnabled = userInfo?.Permissions?.IsD2CEnabled || false;

    const handleIsD2CEnabled = () => {
        let isRedirected = false;

        if(!IsD2CEnabled) {
            if(authenticated) {
                redirect(routes.dashboard);
            } else {
                redirect(routes.signIn);
            }

            isRedirected = true;
        }

        return isRedirected;
    }

    return { IsD2CEnabled, handleIsD2CEnabled };
}

export default useD2C;