export function extractCountryAndStates(data) {
    if (!data || data.length === 0) {
        return { countries: [], countryStates: {} };
    }

    const countriesSet = new Set();
    const countryStatesMap = {};

    data.forEach(item => {
        const country = item?.CountryName;
        const state = item?.State;

        // Add to the countries set
        countriesSet.add(country);

        // Initialize or append to the country's states list
        if (!countryStatesMap[country]) {
            countryStatesMap[country] = [];
        }
        countryStatesMap[country].push(state);
    });

    return {
        countries: Array.from(countriesSet), // Convert set to array
        countryStates: countryStatesMap     // Map of countries and their states
    };
}