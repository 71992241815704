import { saveNoCC_Complaint } from "../../shared/chatbotNew/ChatServices";
import { toast } from "react-toastify";
import routes from "../../../constants/Routes";
import useSweetAlert from "../../shared/sweetAlertComponent/useSweetAlert";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentGroupId, setCurrentSubGroupIndex } from "../../../features/editAssessment/editAssessmentSlice";
import CONSTANT from "../../../constants/Constant";
import { useHistory } from "react-router-dom";

const useCCInEditAssessment = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { showSweetAlert, hideSweetAlert, setNoLoading } = useSweetAlert();

    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const baseData = editAssessmentGroup?.baseData || {};
    const testId = baseData?.testId || "";

    const handleNoCC = async () => {
        setNoLoading(true);
        const response = await saveNoCC_Complaint(testId);
        const { data, error } = response;

        if (error) {
            console.error(error);
            toast.error("Internal Server Error. Please try again")
            return;
        }
        history.push(`${routes.myAssessments}/${false}`);
        hideSweetAlert();
    }

    const handleDirectUserToCCSection = () => {
        dispatch(setCurrentGroupId(CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key));
        dispatch(setCurrentSubGroupIndex(0));
        showSweetAlert({
            title: 'Attention',
            message: 'It is required to fill in chief complaint section.',
            type: CONSTANT.ALERT_TYPE.INFO,
            onConfirm: () => {
                hideSweetAlert();
            },
            showCancelBtn: false,
            showConfirmBtn: false,
            showOkBtn: true,
            confirmBtnText: 'OK',
        });
    }

    return {
        handleDirectUserToCCSection,
        handleNoCC
    }
}

export default useCCInEditAssessment;