import CONSTANT from "../../../constants/Constant";
import routes from "../../../constants/Routes";
import GLOBAL from "../../../global";
import Assessment from "../../../models/Assessment";
import { useDispatch, useSelector } from "react-redux";
import useSweetAlert from "../../shared/sweetAlertComponent/useSweetAlert";
import useIntakeFrom from "../../shared/intake-form/useIntakeFrom";
import {
    setOpenBiometricMovementAndCautionQuestionContainer
} from "../../../features/editAssessment/editAssessmentSlice";
import { getWaitingRoomId, sendEmailForJoinTelemedicineLater } from "../EditAssessmentService";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";
import { isEmptyObject } from "../../../utils/Utils";
import { saveIntake } from "../../shared/intake-form/intake-form-service";
import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import { useHistory } from "react-router-dom";
import useEditAssessment from "./useEditAssessment";
import { isAllGroupCompletedExceptOSW, isCCQuestionAnswered } from "../EditAssessmentUtils";
import useCCInEditAssessment from "./useCCInEditAssessment";

const useBiometricInEditAssessment = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { setTelemedicineProperties, resetTelemedicineKeys } = useContext(AppContext);
    const { showSweetAlert, hideSweetAlert, setNoLoading, setOkLoading } = useSweetAlert();
    const { handleOpenIntakeForm, handleCloseIntakeForm, handleSetJoiningTelemedicine, handleSetJoiningLaterTelemedicine } = useIntakeFrom();
    const { getAssessmentAvailabilityStatus } = useHandleExpiredAndUpcomingMeetingPopupMsg();
    const { showThankYouForYorResponsePopup, handleIsAllQuestionsOfGroupAnswered } = useEditAssessment();
    const { handleDirectUserToCCSection } = useCCInEditAssessment();

    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const groups = editAssessmentGroup?.groups || [];
    const baseData = editAssessmentGroup?.baseData || {};
    const ccQuestion = editAssessmentGroup?.ccQuestion || {};

    const invitationTypeName = baseData?.invitationTypeName || "";
    const testId = baseData?.testId || "";
    const invitationId = baseData?.invitationId || "";
    const bodyRegionList = baseData?.bodyRegionList || [];
    const meetingEndDate = baseData?.meetingEndDate || '';
    const meetingStartDate = baseData?.meetingStartDate || '';

    const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || false;

    const showPopupForBiometric = async () => {
        if (invitationTypeName?.toLowerCase() === CONSTANT.REDIRECT_TYPE.SELF) {
            hideSweetAlert(); //as do not close the sweetalert at showCCOrPainQuestionPopUp function

            showSweetAlert({
                title: 'Question',
                message: 'Do you want to perform biometric movement?',
                onConfirm: () => {
                    hideSweetAlert();
                    handleOpenMovementModal();
                },
                onCancel: () => {
                    hideSweetAlert();
                    history.push(`${routes.myAssessments}/${false}`);
                }
            })
        } else {
            if (invitationId) {
                await getWaitingRoomId();
                hideSweetAlert(); //as do not close the sweetalert at showCCOrPainQuestionPopUp function

                if (invitationTypeName?.toLowerCase() === CONSTANT.REDIRECT_TYPE.INCLINIC) {
                    showThankYouForYorResponsePopup();
                // in case of telemedicine
                } else if (invitationId) {
                    const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(meetingStartDate, meetingEndDate);
                    const shouldNotShowJoinTelemedicine =
                        (assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) ||
                        (assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY);

                    if (!isIntakeSigned && !shouldNotShowJoinTelemedicine) {
                        GLOBAL.ASSESSMENT = new Assessment({ TestId: testId });////TODO: will need to store those on redux

                        handleOpenIntakeForm({
                            bodyRegionList: bodyRegionList || [],
                            invitationTypeName: invitationTypeName || '',
                            onClickJoinTelemedicineNow: onClickJoinTelemedicineNow,
                            onClickJoinTelemedicineLater: onClickJoinTelemedicineLater
                        })
                    } else {
                        if (shouldNotShowJoinTelemedicine) {
                            history.push(`${routes.myAssessments}/${false}`);
                        } else {
                            showSweetAlert({
                                title: 'Question',
                                message: 'Do you want to join now?',
                                type: CONSTANT.ALERT_TYPE.SUCCESS,
                                showConfirmBtn: true,
                                showCancelBtn: true,
                                confirmBtnCss: "btn btn-success btn-wide",
                                cancelBtnCss: "btn btn-danger btn-wide kt-ml-20",
                                confirmBtnText: "JOIN NOW",
                                cancelBtnText: "JOIN LATER",
                                onConfirm: onClickJoinTelemedicineNow,
                                onCancel: () => {
                                    setNoLoading(true);
                                    onClickJoinTelemedicineLater();
                                }
                            })
                        }
                    }
                }
            }
        }
    }

    const onClickJoinTelemedicineLater = async (values = {}) => {
        if(!isIntakeSigned) handleSetJoiningLaterTelemedicine(true) //To show loading spinner on the button at intake form
        else setNoLoading(true); //To show loading spinner on the button of sweetalert

        let waitingRoomId = await getWaitingRoomId(testId, invitationId) || "";

        await sendEmailForJoinTelemedicineLater(waitingRoomId);
        if(!isIntakeSigned) handleSetJoiningLaterTelemedicine(false); //To show loading spinner on the button at intake form
        else setNoLoading(false); //To show loading spinner on the button of sweetalert

        handleCloseIntakeForm();
        showPopUpForJoinLater();
    };

    const afterInitializeTelemedicineModal = () => {
        hideSweetAlert();
        history.push(`${routes.myAssessments}/${false}`);
    }

    const afterCloseTelemedicineModal = () => {
        resetTelemedicineKeys();
        handleCloseIntakeForm();
    }

    const onClickJoinTelemedicineNow = async (values = {}) => {
        if(!isIntakeSigned) handleSetJoiningTelemedicine(true); //To show loading spinner on the button at intake form
        else setOkLoading(true); //To show loading spinner on the button of sweetalert

        if(!isEmptyObject(values)) {
            await saveIntake(values, testId);
        }

        setTelemedicineProperties(invitationId, bodyRegionList, afterInitializeTelemedicineModal, afterCloseTelemedicineModal);
    };

    const handleOpenMovementModal = () => {
        const doesAllGroupCompletedExceptOSW = isAllGroupCompletedExceptOSW(groups || []) || false;
        if (!doesAllGroupCompletedExceptOSW) {
            let isAllQuestionsOfGroupAnswered = false;
            for(let i = 0; i < groups.length; i++) {
                let groupId = groups[i]?.groupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
                if(groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                    if(!isCCQuestionAnswered(ccQuestion)) {
                        handleDirectUserToCCSection();
                        return;
                    }
                }

                if(![CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key, CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key].includes(groupId)) {
                    isAllQuestionsOfGroupAnswered = handleIsAllQuestionsOfGroupAnswered(groups[i]);
                }
                if(!isAllQuestionsOfGroupAnswered) {
                    return;
                }
            }
        }

        GLOBAL.ASSESSMENT = new Assessment({ TestId: testId });////TODO: will need to store those on redux
        dispatch(setOpenBiometricMovementAndCautionQuestionContainer(true));
    }

    const showPopUpForJoinLater = () => {
        showSweetAlert({
            title: CONSTANT.ALERT_TITLE.CONGRATULATIONS,
            message: 'You have successfully completed the questionnaire portion of the assessment. Your provider has been notified and will follow up with you in order to complete the last part of the assessment.',
            type: CONSTANT.ALERT_TYPE.SUCCESS,
            onConfirm: () => {
                hideSweetAlert();
                history.push(`${routes.myAssessments}/${false}`);
            },
            showCancelBtn: false,
            showConfirmBtn: false,
            showOkBtn: true,
            confirmBtnText: 'OK',
        });
    }

    return {
        showPopupForBiometric,
        handleOpenMovementModal,
        onClickJoinTelemedicineLater,
    }
}

export default useBiometricInEditAssessment;