import './MyCalendar.scss';
import React from "react";

import CONSTANT from "../../constants/Constant";

import MobileHeader from "../shared/layout/MobileHeader";
import MainHeaderWrapper from "../shared/layout/MainHeaderWrapper";
import Footer from "../shared/layout/Footer";
import BodyWithLeftMenu from "../shared/layout/BodyWithLeftMenu";
import MyCalenderDetails from "./MyCalenderDetails";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";

const MyCalendar = () => {

    return (
        <>
            {/*<MobileHeader/>*/}
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                        <MainHeaderWrapper/>
                        <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.MY_CALENDAR}>
                            <MyCalenderDetails/>
                        </BodyWithTopMenu>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyCalendar;
