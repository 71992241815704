import MainHeaderWrapper from "../shared/layout/MainHeaderWrapper";
import BillingAddress from "./BillingAddress";
import PlaceOrder from "./PlaceOrder";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CONSTANT from "../../constants/Constant";
import Payment from "../../services/Payment";
import GLOBAL from "../../global";
import answerValidationService from "../../services/answerValidation/AnswerValidationService";
import { initDirectToConsumer } from "../../features/directToConsumer/initDirectToConsumer";
import { setError, setIsClickedPlaceOrder } from "../../features/directToConsumer/directToConsumerSlice";
import React, { useEffect } from "react";
import PaymentSuccessPopup from "./PaymentSuccessPopup";
import useRedirect from "../../hooks/useRedirect";
import routes from "../../constants/Routes";
import DirectToConsumerService from "../../services/DirectToConsumerService";
import { setOpenChatBot } from "../../features/chat/chatbotSlice";
import { getRedirectType } from "../../utils/Utils";
import useD2C from "../../hooks/useD2C";

const Billing = () => {
    const location = useLocation();
    const { handleIsD2CEnabled } = useD2C();
    const dispatch = useDispatch();

    const { redirect } = useRedirect();

    const [isLoadingCountry, setIsLoadingCountry] = React.useState(true);
    const [isPlacingOrder, setIsPlacingOrder] = React.useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = React.useState(false);
    const [loadingStartNow, setLoadingStartNow] = React.useState(false);
    const [loadingStartLater, setLoadingStartLater] = React.useState(false);
    const directToConsumerState = useSelector((state) => state.directToConsumer);

    const selectedCountryValue = directToConsumerState?.selectedCountry?.value || '';
    const nameValue = directToConsumerState?.name?.value || '';
    const emailValue = directToConsumerState?.email?.value || '';
    const selectedStateValue = directToConsumerState?.selectedState?.value || '';
    const postCodeValue = directToConsumerState?.postCode?.value || '';
    const streetAddressValue = directToConsumerState?.streetAddress?.value || '';
    const isClickedPlaceOrder = directToConsumerState?.isClickedPlaceOrder || false;

    const patientEmail = (GLOBAL.USER_INFO && GLOBAL.USER_INFO["EmailAddress"]) || "";
    const patientId = GLOBAL?.USER_INFO?.ContactId || '';

    useEffect(() => {
        const isRedirected = handleIsD2CEnabled();
        if(isRedirected) return;

        handleSuccessfullyPlacedOrder();
    }, []);

    const handleSuccessfullyPlacedOrder = () => {
        let query = new URLSearchParams(location?.search);
        let success = query.get(CONSTANT.QUERY_PARAM.SUCCESS) || '';
        let orderId = query.get(CONSTANT.QUERY_PARAM.ORDER_ID) || '';

        if(success.toLowerCase() === 'true' && orderId) {
            setShowSuccessPopup(true);
        } else if(success.toLowerCase() === 'false' && orderId) {
            toast.error("Failed to place order, please try again later");
        }
    }

    const extractPriceFromUrl = () => {
        let query = new URLSearchParams(location?.search);
        let price = query.get(CONSTANT.QUERY_PARAM.PRICE) || 0;

        return price;
    }

    const extractAssessmentNameFromUrl = () => {
        let query = new URLSearchParams(location?.search);
        let assessmentName = query.get(CONSTANT.QUERY_PARAM.ASSESSMENT_NAME) || '';

        return assessmentName
    }

    const handleOnClickPlaceOrder = async () => {
        if(!isClickedPlaceOrder) dispatch(setIsClickedPlaceOrder(true));
        let doesAnyErrorExist = false;

        let initDirectToConsumerState = initDirectToConsumer;
        Object.entries(initDirectToConsumerState).forEach(([key, value]) => {
            if(initDirectToConsumer[key]?.responseType) {
                let validation = answerValidationService.validate("", initDirectToConsumer[key]?.responseType, directToConsumerState[key]?.value);
                if(!validation.valid) {
                    dispatch(setError({
                        name: key,
                        error: {
                            isValid: validation.valid,
                            message: validation.message
                        }
                    }))
                    doesAnyErrorExist = true;
                }
            }
        });

        if(doesAnyErrorExist) {
            return;
        }

        let query = new URLSearchParams(location?.search);
        let packageId = query.get(CONSTANT.QUERY_PARAM.PACKAGE_ID) || '';
        let orderId = query.get(CONSTANT.QUERY_PARAM.ORDER_ID) || '';
        let bodyRegionIdsFromUrl = query.get(CONSTANT.QUERY_PARAM.BODY_REGION_IDS) || '';
        let assessmentNameFromUrl = query.get(CONSTANT.QUERY_PARAM.ASSESSMENT_NAME) || '';

        if(!packageId || !bodyRegionIdsFromUrl || !assessmentNameFromUrl) {
            toast.error("Invalid url, failed to place order");
            return;
        }

        const frontendUrl = `${window.location.origin}/#${routes.billing}?` +
            `${CONSTANT.QUERY_PARAM.PACKAGE_ID}=${encodeURIComponent(packageId)}&` +
            `${CONSTANT.QUERY_PARAM.BODY_REGION_IDS}=${encodeURIComponent(bodyRegionIdsFromUrl)}&` +
            `${CONSTANT.QUERY_PARAM.ASSESSMENT_NAME}=${encodeURIComponent(assessmentNameFromUrl)}`;

        const payload = {
            email: patientEmail,
            billingEmail: emailValue,
            billingName: nameValue,
            packageId: packageId,
            orderId: orderId,
            billingCountry: selectedCountryValue,
            billingState: selectedStateValue,
            billingPostCode: postCodeValue,
            billingStreetAddress: streetAddressValue,
            frontendUrl: frontendUrl
        }

        setIsPlacingOrder(true);
        const response = await Payment.createCheckoutSession(payload);
        if (response.success) {
            if(response.data && response.data) {
                window.location.href = response.data;
            }
        } else {
            // toast.error("Failed to place order, please try again later");
        }
        setIsPlacingOrder(false);
    }

    const handleOnClickStartLater = async () => {
        setLoadingStartLater(true);
        let query = new URLSearchParams(location?.search);
        let orderId = query.get(CONSTANT.QUERY_PARAM.ORDER_ID) || '';

        if(!orderId) {
            toast.error("Order Id not found");
            return;
        }

        await DirectToConsumerService.invitationRequest({ OrderId : orderId });
        redirect(routes.dashboard);
        setLoadingStartLater(false);
    }

    const handleOnClickStartNow = async () => {
        setLoadingStartNow(true);
        let query = new URLSearchParams(location?.search);
        let orderId = query.get(CONSTANT.QUERY_PARAM.ORDER_ID) || '';
        let bodyRegionIdsFromUrl = query.get(CONSTANT.QUERY_PARAM.BODY_REGION_IDS) || '';

        let bodyRegionList = bodyRegionIdsFromUrl.split(',').map(Number).filter(Boolean) || [];

        if(!orderId) {
            toast.error("Order Id not found");
            return;
        }
        let response = await DirectToConsumerService.invitationRequest({ OrderId : orderId });
        if(response.success) {
            redirect(routes.dashboard);
            const data = response.data;

            if(data && data?.InvitationId && data?.InvitationTypeId && bodyRegionList.length > 0 && data?.MeetingStartDate && data?.MeetingEndDate) {
                dispatch(setOpenChatBot({
                    invitationData: {
                        InvitationId: data?.InvitationId,
                        BodyRegionList: bodyRegionList,
                        InvitationTypeName: getRedirectType(data?.InvitationTypeId),
                    },
                    patientId,
                    meetingStart: data?.MeetingEndDate,
                    meetingEnd: data?.MeetingStartDate
                }));
            } else {
                toast.error("Failed to start assessment, please try again later");
            }
        } else {
            toast.error("Failed to start assessment, please try again later");
        }
        setLoadingStartNow(false);
    }

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root font-family-outfit">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <MainHeaderWrapper />
                    <div className="kt-portlet bg-color-light-gray-1">
                        <div className="kt-portlet__body d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="row col-12 col-lg-11 font-family-outfit">
                                <div className="row font-family-outfit g-5 justify-content-center w-100">
                                    <div className="col-12 col-lg-5 mr-md-4 mr-lg-5">
                                        <BillingAddress
                                            isLoadingCountry={isLoadingCountry}
                                            setIsLoadingCountry={setIsLoadingCountry}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-3 mr-md-4 mr-lg-5">
                                        <PlaceOrder
                                            isLoadingCountry={isLoadingCountry}
                                            isPlacingOrder={isPlacingOrder}
                                            assessmentName={extractAssessmentNameFromUrl()}
                                            price={extractPriceFromUrl()}
                                            onClickPlaceOrder={handleOnClickPlaceOrder}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showSuccessPopup && (
                    <PaymentSuccessPopup
                        onClose={() => setShowSuccessPopup(false)}
                        onStartNow={handleOnClickStartNow}
                        onStartLater={handleOnClickStartLater}
                        loadingStartNow={loadingStartNow}
                        loadingStartLater={loadingStartLater}
                    />
                )
            }
        </div>
    )
}

export default Billing;