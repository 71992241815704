import CONSTANT from "../../constants/Constant";
import React, { Fragment } from "react";
import StepBar from "../shared/stepbar/StepBar";
import { useSelector } from "react-redux";
import EditAssessmentQuestionContainer from "./EditAssessmentQuestionContainer";
import EditAssessmentQuestionTab from "./EditAssessmentQuestionTab";
import EditAssessmentQuestionFooter from "./EditAssessmentQuestionFooter";

const EditAssessmentQuestionBody = ({ setSelectedElementForScroll }) => {
    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const groups = editAssessmentGroup?.groups;

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;


    return (
        <div className="kt-wizard-v4" style={{ margin: "3rem auto 0", width: "96%" }}>
            <div className="edit-assessment-stepbar-container">
                <StepBar groups={groups} showName={false} />
            </div>

            <EditAssessmentQuestionTab />

            <div className="kt-portlet h-90 position-relative shadow-none">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    <div className="kt-grid bg-white">
                        <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
                            <div className="kt-form w-98 kt-padding-5" noValidate="novalidate">
                                {currentGroupId && (
                                    <EditAssessmentQuestionContainer
                                        setSelectedElementForScroll={setSelectedElementForScroll}
                                    />
                                )}
                                <EditAssessmentQuestionFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAssessmentQuestionBody;