import React, {useState, useContext, useRef} from 'react'
import { format } from "date-fns";
import {useDispatch, useSelector} from "react-redux";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import ConfirmationDialog from "../shared/Popups/ConfirmationDialog";
import Info from "../../assets/img/utils/info.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import AppContext from "../../contexts/AppContext";
import Assessment from "../../models/Assessment";
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";
import routes from "../../constants/Routes";
import {setOpenChatBot} from "../../features/chat/chatbotSlice";
import {saveIntake} from "../shared/intake-form/intake-form-service";
import useIntakeFrom from "../shared/intake-form/useIntakeFrom";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";
import {convertBodyRegionStrIntoBodyRegionListArrayOfNumber} from "../../utils/Utils";

const AppointmentEventDetails = ({ appointmentDetails, close }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { setTelemedicineProperties, resetTelemedicineKeys } = useContext(AppContext);
    const { handleOpenIntakeForm, handleCloseIntakeForm, handleSetJoiningTelemedicine } = useIntakeFrom();
    const { getAssessmentAvailabilityStatus, handleOpenUpcomingOrExpiredPopupMessage } = useHandleExpiredAndUpcomingMeetingPopupMsg();

    const intakeFormState = useSelector(state => state.intakeForm);

    const joinNowBtnConfig = intakeFormState?.joinNowBtnConfig || {};
    const joiningTelemedicine = joinNowBtnConfig.joiningTelemedicine || false;

    const patientId = GLOBAL?.USER_INFO?.ContactId || '';
    const [selectedTelemedicineInvObj, setSelectedTelemedicineInvObj] = useState(null);
    const [showDetailInstruction, setShowDetailInstruction] = useState(false);
    let telemedicineJoinNowTextRef = useRef("");

    const onClickGoToRoom = (e, inv) => {
        e.preventDefault();
        const redirectType = inv['RedirectTypeText'];
        GLOBAL.URL_PARAMS.IS_INTAKE_SIGNED = inv['IsIntakeSigned'];
        GLOBAL.URL_PARAMS.PATIENT_ID = patientId;
        GLOBAL.URL_PARAMS.REDIRECT_TYPE = 'refer';
        GLOBAL.URL_PARAMS.INVITATION_ID = inv.InvitationId;
        GLOBAL.URL_PARAMS.BODY_REGION_LIST = inv.BodyRegionList;
        GLOBAL.URL_PARAMS.EMAIL_ADDRESS = inv.EmailAddress;
        GLOBAL.URL_PARAMS.REFER_TYPE = redirectType;
        GLOBAL.URL_PARAMS.IS_FOLLOWUP = inv['IsFollowup'];
        GLOBAL.URL_PARAMS.TEST_ID = inv.TestId;

        if(!inv.TestId) {
            dispatch(setOpenChatBot({
                invitationData: inv,
                patientId,
                meetingStart: inv?.MeetingStart,
                meetingEnd: inv?.MeetingEnd
            }));
            return;
        }
        const telemedicineStatus = getAssessmentAvailabilityStatus(inv?.MeetingStart, inv?.MeetingEnd);
        if (telemedicineStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) {
            telemedicineJoinNowTextRef.current = CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.MESSAGE;
        } else if(telemedicineStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) {
            telemedicineJoinNowTextRef.current = CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.MESSAGE;
        } else {
            telemedicineJoinNowTextRef.current = CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.MESSAGES.NO_JOIN_TELEMEDICINE;
        }

        setSelectedTelemedicineInvObj(inv);
    }

    const handleOnClickYesAtConfirmationDialog = (inv) => {
        let assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(inv?.MeetingStart, inv?.MeetingEnd);
        if(assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY || assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) {
            handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus, null, onClose);
        } else {
            onClickJoinTelemedicine(inv);
        }
    }

    const onClickJoinTelemedicine = (inv) => {
        let isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || '';
        if (!isIntakeSigned) {
            handleOpenIntakeForm({
                bodyRegionList: inv?.BodyRegionList || [],
                invitationTypeName: inv?.RedirectTypeText || '',
                showSubmitBtn: true,
                onClickSubmitIntakeForm: onClickSubmitIntakeForm,
                isFromCalendar: true,
            })
        } else {
            openTelemedicineModal();
        }
    }

    const openTelemedicineModal = () => {
        handleSetJoiningTelemedicine(true);
        const { InvitationId, BodyRegion } = appointmentDetails?.data;
        setTelemedicineProperties(InvitationId, convertBodyRegionStrIntoBodyRegionListArrayOfNumber(BodyRegion), onLoadTelemedicineModal, onClose);
    }

    const onClickSubmitIntakeForm = async (values) => {
        handleSetJoiningTelemedicine(true);
        GLOBAL.ASSESSMENT = new Assessment({ TestId: appointmentDetails.data?.TestId });
        const response = await saveIntake(values);
        const { data, success, error } = response;
        if(!success) {
            toast.error(error || 'Failed to save intake form');
            onClose();
            return;
        }
        openTelemedicineModal();
    }

    const onLoadTelemedicineModal = () => {
        handleSetJoiningTelemedicine(false);
        setSelectedTelemedicineInvObj(null);
    }

    const onClose = () => {
        GLOBAL.ASSESSMENT = null;
        handleCloseIntakeForm();
        resetTelemedicineKeys();
        close && close();
    }

    return (
        <>
            <div
                className="kt-widget kt-widget--user-profile-1 d-flex justify-content-center kt-padding-l-15 kt-padding-r-15">
                <div className="kt-widget__body w-75 text-center">
                    <div className="d-flex flex-column kt-widget__content">
                        <div className="kt-widget__info justify-content-start">
                            <span className="kt-widget__label w-30 text-right">Meeting Title:</span>
                            <span className="kt-widget__data text-left kt-ml-10">{appointmentDetails.meetingTitle}</span>
                        </div>
                        <div className="kt-widget__info justify-content-start">
                            <span className="kt-widget__label w-30 text-right">Provider Name:</span>
                            <span className="kt-widget__data text-left kt-ml-10">{appointmentDetails.providerName}</span>
                        </div>
                        <div className="kt-widget__info justify-content-start">
                            <span className="kt-widget__label w-30 text-right">Provider Email:</span>
                            <span className="kt-widget__data text-left kt-ml-10">{appointmentDetails.providerEmail}</span>
                        </div>
                        <div className="kt-widget__info justify-content-start">
                            <span className="kt-widget__label w-30 text-right">Meeting Date:</span>
                            <span className="kt-widget__data text-left kt-ml-10">
                            {format(new Date(appointmentDetails.meetingDate), CONSTANT.DATE_FORMAT.MMM_DD_YYYY)}
                        </span>
                        </div>
                        <div className="kt-widget__info justify-content-start">
                            <span className="kt-widget__label w-30 text-right">Start Time:</span>
                            <span className="kt-widget__data text-left kt-ml-10">
                            {format(new Date(appointmentDetails.startTime), CONSTANT.DATE_FORMAT.HH_MM_P_A)}
                        </span>
                        </div>
                        <div className="kt-widget__info justify-content-start">
                            <span className="kt-widget__label w-30 text-right">End Time:</span>
                            <span className="kt-widget__data text-left kt-ml-10">
                            {format(new Date(appointmentDetails.endTime), CONSTANT.DATE_FORMAT.HH_MM_P_A)}
                        </span>
                        </div>
                    </div>
                    <a rel="noopener noreferrer"
                       href={"!#"}
                       onClick={(e) => onClickGoToRoom(e, appointmentDetails.data)}
                       target="_blank"
                       className="btn btn-success kt-mr-10">
                        <i className="fa fa-handshake"/>
                        Meet now
                    </a>
                    <button className="btn btn-danger btn-outline-hover-danger btn-elevate btn-square" onClick={close}>
                        <i className="fa fa-times"/>
                        Close
                    </button>
                </div>
            </div>
            <ConfirmationDialog
                headerText="Update Your Information?"
                show={selectedTelemedicineInvObj !== null}
                cancelText="Update My Background"
                confirmText={`${telemedicineJoinNowTextRef.current}`}
                showLoadingInConform={joiningTelemedicine}
                onConfirm={() => handleOnClickYesAtConfirmationDialog(selectedTelemedicineInvObj)} /// Confirm Action =>No, Join Telemedicine
                toggleDialog={() => {
                    history.push(`${routes.editAssessment}/${selectedTelemedicineInvObj.TestId}`)
                }} /// Cancel handler =>Update My Background
                onClickHeaderClose={() => setSelectedTelemedicineInvObj(null)}>
                <p>Would you like to update your background information (Activities of Daily Living, Medical History, Chief Complaints)?
                    <span onClick={() => setShowDetailInstruction(true)} className="edit-assessment-info-container kt-ml-10">
                        <img src={Info} alt="Information" className='tele_eye_btn cursor-pointer' />
                    </span>
                </p>
            </ConfirmationDialog>
            <SweetAlert
                confirmBtnCssClass="btn btn-sm btn-success"
                showConfirm= {true}
                showCancel={false}
                show={showDetailInstruction}
                title=""
                type="info"
                onConfirm={() => setShowDetailInstruction(false)}
                confirmBtnText="Ok"
                closeOnClickOutside={false}>
                <div className='tele_sweetalert_message'>
                    If there have been any notable changes to your weight, daily activities, or your acute or chronic chief complaints,
                    please update these entries by clicking “Update My Background”
                </div>
            </SweetAlert>
        </>
    )
}

export default AppointmentEventDetails;
