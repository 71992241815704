import CONSTANT from "../../constants/Constant";

export const initDirectToConsumer = {
    countries: {
        value: [],
        error: {
            isValid :true,
            message :""
        }
    },
    countryStates: {
        value: {},
        error: {
            isValid :true,
            message :""
        },
    },
    states: {
        value: [],
        error: {
            isValid :true,
            message :""
        }
    },
    email: {
        value: '',
        error: {
            isValid :true,
            message :""
        },
        responseType: CONSTANT.ELEMENT_TYPES.EMAIL
    },
    name: {
        value: '',
        error: {
            isValid :true,
            message :""
        },
        responseType: CONSTANT.ELEMENT_TYPES.TEXT
    },
    selectedCountry: {
        value: '',
        error: {
            isValid :true,
            message :""
        },
        responseType: CONSTANT.ELEMENT_TYPES.SELECT
    },
    selectedState: {
        value: '',
        error: {
            isValid :true,
            message :""
        },
        responseType: CONSTANT.ELEMENT_TYPES.SELECT
    },
    postCode: {
        value: '',
        error: {
            isValid :true,
            message :""
        },
        responseType: CONSTANT.ELEMENT_TYPES.TEXT
    },
    streetAddress: {
        value: '',
        error: {
            isValid :true,
            message :""
        },
        responseType: CONSTANT.ELEMENT_TYPES.TEXT
    },
    isClickedPlaceOrder: false
}