import React, { useEffect, useMemo, useRef, useState } from "react";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import MmhDataTable from "../shared/table/datatable/MmhDataTable";
import Refresh from "../shared/Refresh";
import ReactPaginate from "react-paginate";
import { getArrayFromObject } from "../../utils/Utils";
import cardService from "../../services/CardService";
import ConfirmationDialog from "../shared/Popups/ConfirmationDialog";

const MyCardsDetails = () => {
    const [creditCards, setCreditCards] = useState([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const selectedId = useRef('');

    const [loading, setLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [myPageSize, setMyPageSize] = useState(CONSTANT.PAGE_SIZE.TEN.value);
    const [myPageNumber, setMyPageNumber] = useState(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);


    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchMyCards();

        return () => {
            // subscription.current = false;
            GLOBAL.ASSESSMENT = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);

    const handlePageSize = (event) => {
        setMyPageSize(event.target.value);
        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
    }

    const fetchMyCards = async () => {
        setLoading(true);
        let payload = { };
        const response = await cardService.getCreditCards(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            setLoading(false);
            return [];
        }

        // if (subscription.current) {
        setLoading(false);
        const tempData = data || '[]';
        setCreditCards(tempData);
        setTotalRecord(tempData.length);
        // }
    };

    const deleteCard = async () => {
        setIsDeleting(true);
        let payload = { };
        payload.Id = selectedId.current;
        const response = await cardService.deleteCreditCard(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);
            setLoading(false);
            clearSelectedCard();
            setIsDeleting(false);
            return;
        }

        const filteredData = creditCards.filter((item) => item.Id !== selectedId.current);
        setCreditCards(filteredData);
        clearSelectedCard();
        setIsDeleting(false);
    }

    const clearSelectedCard = () => {
        selectedId.current = '';
        setShowConfirmationDialog(false);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original.Name}`;
                }
            },
            {
                Header: 'Last 4 Digits',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original.Last4}`;
                }
            },
            {
                Header: 'Type',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original.Type}`;
                }
            },
            {
                Header: 'Address Line 1',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original.AddressLine1}`;
                }
            },
            {
                Header: 'Expire',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original.ExpirationMonth}/${original.ExpirationYear}`;
                }
            },
            {
                Header: 'Delete',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return (
                        <button type="button" className="my_painlog_add_button  kt-mr-10"
                            onClick={() => {
                                selectedId.current = original.Id;
                                setShowConfirmationDialog(true);
                            }}>
                            Delete
                        </button>
                    )
                    // return format(new Date(original['CreatedOn']) || new Date(), CONSTANT.DATE_FORMAT.MMM_DD_YYYY);
                }
            },

        ],

        // eslint-disable-next-line
        []
    );

    return (
        <>
            <div className="kt-portlet kt-portlet--bordered my_painlog w-100-percent">
                <div className="kt-portlet__body">
                    <div className="d-flex justify-content-end my_painlog_details_filter-list">
                        {/*<button type="button" className="my_painlog_add_button  kt-mr-10"*/}
                        {/*        // onClick={addPainlog}*/}
                        {/*>*/}
                        {/*    Add Painlog*/}
                        {/*</button>*/}
                        <Refresh refreshFunction={fetchMyCards}/>
                    </div>
                    {loading && (
                        <div className="alert alert-secondary kt-mb-0 kt-mt-10" role="alert">
                            <div className="alert-icon">
                                <i className="flaticon-warning kt-font-brand"/>
                            </div>
                            <div className="alert-text">
                                Please wait, loading painlogs &nbsp;&nbsp;
                                <i className="fa fa-spinner fa-spin"/>
                            </div>
                        </div>
                    )}
                    {!loading && (
                        <MmhDataTable
                            columns={columns}
                            data={creditCards}
                            textAlign="dummy"
                        />
                    )}

                    {/*pagination */}
                    <div className="mt-2  d-flex justify-content-between">
                        <div>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(totalRecord / myPageSize)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) => {
                                    setMyPageNumber(data.selected + 1)
                                }}
                                containerClassName={"pagination  justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item "}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={(myPageNumber - 1)}
                            />
                        </div>
                        <div className="justify-content-end">
                            <span
                                className="mr-2 kt-datatable__pager-detail color-primary">Showing {((myPageNumber * myPageSize) - myPageSize) + 1} to {(myPageNumber * myPageSize)} of {totalRecord} entries</span>
                            <select
                                className="form-control-sm color-primary"
                                onChange={handlePageSize}
                                value={myPageSize}>
                                {getArrayFromObject(CONSTANT.PAGE_SIZE)?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    {/*pagination*/}

                </div>
            </div>

            <ConfirmationDialog
                confirmText="Yes"
                cancelText="No"
                show={showConfirmationDialog}
                onClickHeaderClose={clearSelectedCard}
                toggleDialog={clearSelectedCard}
                onConfirm={deleteCard}
                showLoadingInConform={isDeleting}
            >
                Are you sure you want to delete this card?
            </ConfirmationDialog>
        </>
    )
};

export default MyCardsDetails;
