import PackageHeaderWrapper from "../shared/layout/PackageHeaderWrapper";
import PackageDetails from "./PackageDetails";
import PackageOverview from "./PackageOverview";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DirectToConsumerService from "../../services/DirectToConsumerService";
import PackageData from "../../models/PackageData";
import { makeSerializable } from "../../utils/Utils";
import AppContext from "../../contexts/AppContext";
import routes from "../../constants/Routes";
import useRedirect from "../../hooks/useRedirect";
import CONSTANT from "../../constants/Constant";
import useD2C from "../../hooks/useD2C";

const PackageDetailsPageWrapper = () => {
    const { authenticated } = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();
    const { handleIsD2CEnabled } = useD2C();
    const [isLoadingNotification, setIsLoadingNotification] = useState(true);
    const [isError, setIsError] = useState(false);
    const [packageData, setPageData] = useState(makeSerializable(new PackageData()));

    const { redirectKeepingQueryParams } = useRedirect();

    useEffect(() => {
        const isRedirected = handleIsD2CEnabled();
        if(isRedirected) return;

        handleGetPackageById();
    }, []);

    const handleGetPackageById = async () => {
        let query = new URLSearchParams(location?.search);
        let packageId = query.get(CONSTANT.QUERY_PARAM.PACKAGE_ID) || '';
        let tempPackageDataMappedWithDTO = makeSerializable(new PackageData());

        if (packageId) {
            let tempPackageData = await DirectToConsumerService.getPackageById({ GId: packageId } );
            if(tempPackageData.success) {
                tempPackageDataMappedWithDTO = new PackageData(tempPackageData.data);
                setPageData(makeSerializable(tempPackageDataMappedWithDTO));
            } else {
                setIsError(true);
            }
            setIsLoadingNotification(false);

            if(tempPackageDataMappedWithDTO?.bodyRegionIds && tempPackageDataMappedWithDTO?.price && tempPackageDataMappedWithDTO?.title) {
                query.set('BodyRegionIds', tempPackageDataMappedWithDTO?.bodyRegionIds || '');
                query.set(CONSTANT.QUERY_PARAM.PRICE, tempPackageDataMappedWithDTO?.price || 0);
                query.set(CONSTANT.QUERY_PARAM.ASSESSMENT_NAME, tempPackageDataMappedWithDTO?.title || '');
                history.replace({ search: query.toString() });
            }
        }
    }

    const handleOnClickGetStarted = () => {
        if(authenticated) {
            redirectKeepingQueryParams(routes.billing);
        } else {
            redirectKeepingQueryParams(routes.signUp);
        }
    }

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root font-family-outfit">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <PackageHeaderWrapper />
                    {
                        (isLoadingNotification || isError)
                            ?
                                (
                                    <div className="position-center">
                                        {isLoadingNotification && <i className="fa fa-spinner fa-spin kt-font-primary font-size-4-rem"></i>}
                                        {isError && <div className="font-size-2-rem">Internal Server Error</div>}
                                    </div>
                            )
                            : (
                                <div className="kt-portlet bg-color-light-gray-1">
                                    <div
                                        className="kt-portlet__body d-flex justify-content-center align-items-center mt-xl-0">
                                        <div className="row col-12 col-lg-10 font-family-outfit">
                                            <div className="row font-family-outfit g-5 justify-content-center">
                                                <div className="col-12 col-lg-6 mr-md-4 mr-lg-5">
                                                    <div className="bg-color-white d-flex-center-center">
                                                        <img src={packageData?.imageUrl || ''} alt="image1" className="img-fluid w-40"/>
                                                    </div>

                                                    <PackageOverview
                                                        shortDescription={packageData?.shortDescription || ''}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <PackageDetails
                                                        title={packageData?.title || ''}
                                                        iconSrc={packageData?.shortImageUrl || ''}
                                                        price={packageData?.price || 0}
                                                        packageDetails={packageData?.packageDetails || ''}
                                                        onClickGetStarted={handleOnClickGetStarted}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default PackageDetailsPageWrapper;