import routes from "../constants/Routes";
import useRedirect from "./useRedirect";
import { useLocation } from "react-router-dom";
import CONSTANT from "../constants/Constant";

const useRedirectToBillingPage = () => {
    const location = useLocation();
    const { redirectKeepingQueryParams } = useRedirect();

    const handleRedirectToBillingPageIfFromPackagePage = () => {
        let query = new URLSearchParams(location?.search);
        let packageId = query.get(CONSTANT.QUERY_PARAM.PACKAGE_ID) || '';
        let bodyRegionListParam = query.get(CONSTANT.QUERY_PARAM.BODY_REGION_IDS) || '';
        let price = query.get(CONSTANT.QUERY_PARAM.PRICE) || '';
        let bodyRegionList = bodyRegionListParam.split(',').map(Number).filter(Boolean) || [];

        if(packageId && bodyRegionList.length > 0 && price > -1) {
            redirectKeepingQueryParams(routes.billing);
            return true
        }

        return false;
    }

    return {
        handleRedirectToBillingPageIfFromPackagePage
    }
}

export default useRedirectToBillingPage;