import PackageHeaderWrapper from "../shared/layout/PackageHeaderWrapper";
import React, { useEffect } from "react";
import Packages from "./Packages";
import useD2C from "../../hooks/useD2C";

const PackagesPageWrapper = () => {
    const { handleIsD2CEnabled } = useD2C();

    useEffect(() => {
        handleIsD2CEnabled();
    }, []);

    return (
        <div className="kt-grid kt-grid--hor kt-grid--root font-family-outfit">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <PackageHeaderWrapper />
                    <Packages />
                </div>
            </div>
        </div>
    )
}

export default PackagesPageWrapper;