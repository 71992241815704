import { isEmptyObject } from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";
import { setQuestionAnswer } from "../../features/editAssessment/editAssessmentSlice";
import routes from "../../constants/Routes";

export const getNoCCQuestion = (responses) => {
    if(isEmptyObject(responses)) {
        return false;
    }

    let noCCQuestion = null;
    responses.forEach((response) => {
        if(response?.answerTitle?.toString()?.toLowerCase().includes("no")) {
            noCCQuestion = response;
        }
    });

    return noCCQuestion;
}

export const isCCQuestionAnswered = (ccQuestion) => {
    let isCCQuestionAnswered = false;

    if(isEmptyObject(ccQuestion)) {
        return isCCQuestionAnswered;
    }

    if(ccQuestion?.answerId) {
        isCCQuestionAnswered = true;
    }

    return isCCQuestionAnswered;
}

export const getIndexOfCurrentGroup = (groups, currentGroupId) => {
    return groups.findIndex(group => group.groupId === currentGroupId);
}

export const doesHaveAnswerIdRequired = (question) => {
    let needAnswerId = true;

    if(
        (
            question?.responseType === CONSTANT.ELEMENT_TYPES.TEXT ||
            question?.responseType === CONSTANT.ELEMENT_TYPES.CALCULATION ||
            question?.responseType === CONSTANT.ELEMENT_TYPES.NUMBER ||
            question?.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER
        )
    ) {
        needAnswerId = false;
    }

    return needAnswerId;
}

export const indexOfGroupId = (groups, groupId) => {
    return groups.findIndex(group => group.groupId === groupId);
}

export const getClickQuestionSubGroupIndex = (currentGroup, questionId) => {
    let tempSubGroupIndex = -1;

    const subGroups = currentGroup?.subGroups || [];

    subGroups.forEach(((subGroup, subGroupIndex) => {
        subGroup.questions.forEach(question => {
            if(question.questionId === questionId) {
                tempSubGroupIndex = subGroupIndex;
            }
        });
    }))

    return tempSubGroupIndex;
}

export const getQuestionIdsOfOSWScore = (obj) => {
    let questionIds = [];
    const regionNames = Object.keys(CONSTANT.OUTCOME_SCORE_QUESTIONS);
    regionNames.forEach(name => {
        const singleRegionQuestionIds = Object.values(CONSTANT.OUTCOME_SCORE_QUESTIONS[name]);
        questionIds = [...questionIds, ...singleRegionQuestionIds];
    });

    return questionIds;
}

export const getUniqueQuestionIdsInSecondArray = (existingIds, newIds) => {
    return newIds.filter(id => !existingIds.includes(id));
}

export const getObjForUpdatingAnswerAtRedux = (questionId, responseId, responseValue, tempSubGroupIndex) => {
    return {
        questionId: questionId,
        answerId: responseId,
        answerValue: responseValue,
        subGroupIndex: tempSubGroupIndex
    }
}

export const isAllGroupCompletedExceptOSW = (groups) => {
    let isAllGroupCompleted = true;

    groups.forEach(group => {
        if(group.groupId !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            if(!group.groupCompleted) {
                isAllGroupCompleted = false;
            }
        }
    });

    return isAllGroupCompleted;
}