import { useHistory, useLocation } from "react-router-dom";

const useRedirect = () => {
    const history = useHistory();
    const location = useLocation();

    const redirect = (path) => {
        history.push(path);
    }

    const redirectKeepingQueryParams = (path) => {
        const searchParams = new URLSearchParams(location?.search).toString();
        history.push(`${path}?${searchParams}`);
    }

    return {
        redirect,
        redirectKeepingQueryParams
    }
}

export default useRedirect;