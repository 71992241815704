import React from "react";

const paymentSuccess = require("../../assets/img/payment/paymentSuccess.png");

const PaymentSuccessPopup = ({ onClose, onStartNow, onStartLater, loadingStartNow, loadingStartLater }) => {
    return (
        <div className="payment-success-popup">
            <div className="payment-success-popup__overlay" />
            <div className="payment-success-popup__content">
                <h3 className="payment-success-popup__title">Payment Successful</h3>
                <div className="payment-success-popup__icon">
                    <img
                        src={paymentSuccess}
                        alt="Success Icon"
                    />
                </div>
                <p className="payment-success-popup__message">
                    Would you like to begin your assessment now?
                </p>
                <div className="payment-success-popup__actions">
                    <button
                        className="payment-success-popup__button payment-success-popup__button--secondary"
                        onClick={onStartLater}
                        disabled={loadingStartNow || loadingStartLater}
                    >
                        {loadingStartLater ? "Loading..." : "Start Later"}
                    </button>
                    <button
                        className="payment-success-popup__button payment-success-popup__button--primary"
                        onClick={onStartNow}
                        disabled={loadingStartNow || loadingStartLater}
                    >
                        {loadingStartNow ? "Loading..." : "Start Now"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccessPopup;