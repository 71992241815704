import { success,error } from "./ResponseHandler";
import CONSTANT from "../constants/Constant";
import axios from "./Axios";

const { REACT_APP_BASE_API_URL } = process.env;
const GET_ALL_PACKAGES = REACT_APP_BASE_API_URL +  `/api/${CONSTANT.CONTROLLER.DIRECTTOCONSUMER}/GetAllPackage`;
const GET_PACKAGE_BY_ID = REACT_APP_BASE_API_URL +  `/api/${CONSTANT.CONTROLLER.DIRECTTOCONSUMER}/GetPackageById`;
const GET_ALL_COUNTRY_STATE = REACT_APP_BASE_API_URL +  `/api/${CONSTANT.CONTROLLER.DIRECTTOCONSUMER}/GetAllCountryState`;
const INVITATION_REQUEST = REACT_APP_BASE_API_URL +  `/api/${CONSTANT.CONTROLLER.DIRECTTOCONSUMER}/InvitationRequest`;
const GET_ORDER_HISTORY = REACT_APP_BASE_API_URL +  `/api/${CONSTANT.CONTROLLER.DIRECTTOCONSUMER}/OrderHistory`;


class DirectToConsumerService {
    async getAllPackages (payload = {}) {
        try {
            const response = await axios.get(GET_ALL_PACKAGES);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getPackageById (payload = {}) {
        try {
            const response = await axios.post(GET_PACKAGE_BY_ID, payload);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async getAllCountryState(payload = {}) {
        try {
            const response = await axios.get(GET_ALL_COUNTRY_STATE, payload);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async invitationRequest (payload = {}) {
        try {
            const response = await axios.post(INVITATION_REQUEST, payload);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }

    async orderHistory() {
        try {
            const response = await axios.get(GET_ORDER_HISTORY);

            return success(response);
        } catch (e) {
            return error(e);
        }
    }
}

export default new DirectToConsumerService();