import React, { useEffect } from "react";
import CONSTANT from "../../../constants/Constant";
import { playAudio } from "../../../utils/Utils";

import MobileHeader from "../../shared/layout/MobileHeader";
import MainHeaderWrapper from "../../shared/layout/MainHeaderWrapper";
import Footer from "../../shared/layout/Footer";
import SubscriptionDetails from "./SubscriptionDetails";
import BodyWithLeftMenu from "../../shared/layout/BodyWithLeftMenu";
import BodyWithTopMenu from "../../shared/layout/BodyWithTopMenu";

const MySubscription = () => {
    useEffect(() => {
        playAudio('remove').then();
    }, []);

    return (
        <>
            {/*<MobileHeader/>*/}
            <div className="kt-grid kt-grid--hor kt-grid--root">
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                        <MainHeaderWrapper/>
                        <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.MY_SUBSCRIPTION}>
                            <SubscriptionDetails/>
                        </BodyWithTopMenu>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MySubscription;
