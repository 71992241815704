import React, { useState } from 'react';
import { formatDateToMMDDYYYYForEditAssessment } from "../../../utils/Utils";
import routes from "../../../constants/Routes";
import CONSTANT from "../../../constants/Constant";
import useRedirect from "../../../hooks/useRedirect";

const PackageTable = ({ packages }) => {
    const { redirect } = useRedirect();
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    // Calculate the index of the first and last row for the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    // Slice the packages array to get only the rows for the current page
    const currentRows = packages.slice(indexOfFirstRow, indexOfLastRow);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total number of pages based on the packages length and rowsPerPage
    const totalPages = Math.ceil(packages.length / rowsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleOnClickPayNow = (row) => {
        // billing?PackageId=1108c3c5-88af-456f-9863-171fbb67d56f&BodyRegionIds=5&Price=1&AssessmentName=Posture+Assessment
        const packageId = row?.packageGId || '';
        const bodyRegionIdsFromUrl = row?.bodyRegionIds || '';
        const assessmentNameFromUrl = row?.title || '';
        const orderId = row?.orderGId || '';
        const price = row?.price || 0;

        const redirectUrl = `${routes.billing}?` +
            `${CONSTANT.QUERY_PARAM.PACKAGE_ID}=${encodeURIComponent(packageId)}&` +
            `${CONSTANT.QUERY_PARAM.BODY_REGION_IDS}=${encodeURIComponent(bodyRegionIdsFromUrl)}&` +
            `${CONSTANT.QUERY_PARAM.ASSESSMENT_NAME}=${encodeURIComponent(assessmentNameFromUrl)}&` +
            `${CONSTANT.QUERY_PARAM.ORDER_ID}=${encodeURIComponent(orderId)}&` +
            `${CONSTANT.QUERY_PARAM.PRICE}=${encodeURIComponent(price)}`;

        redirect(redirectUrl);
    }

    return (
        <div className="table">
            <table className="table__content">
                <thead className="table__header">
                <tr className="table__row">
                    <th className="table__cell table__cell--header table__cell--header--title">Title</th>
                    <th className="table__cell table__cell--header table__cell--header--small">Price</th>
                    <th className="table__cell table__cell--header table__cell--header--small">Status</th>
                    <th className="table__cell table__cell--header table__cell--header--small">Purchase Date</th>
                    {/* <th className="table__cell table__cell--header table__cell--header--small">Expiry Date</th> */}
                </tr>
                </thead>
                <tbody className="table__body">
                {currentRows.map((row, index) => (
                    <tr key={index} className="table__row table__row--body">
                        <td className="table__cell table__cell--title">
                            <img className="img" src={row?.shortImageUrl} alt="package" />
                            {row.title}
                        </td>
                        <td className="table__cell table__cell--small">$ {row.price}</td>
                        <td className="table__cell table__cell--small">
                            {
                                row.paymentStatus.toLowerCase() === 'pending'
                                    ?
                                        <button
                                            className="button--pay-now"
                                            onClick={() => handleOnClickPayNow(row)}
                                        >
                                            Pay Now
                                        </button>
                                    :
                                    `${row.paymentStatus}`
                            }
                        </td>
                        <td className="table__cell table__cell--small">{formatDateToMMDDYYYYForEditAssessment(row.paymentDate)}</td>
                        {/* <td className="table__cell table__cell--small">{row.expiryDate}</td> */}
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Pagination Buttons */}
            <div className="table__pagination">
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        className={`table__page-button ${number === currentPage ? 'table__page-button--active' : ''}`}
                        onClick={() => paginate(number)}
                    >
                        {number}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PackageTable;
