import React from 'react';
import { Link } from "react-router-dom";

import routes from "../../../constants/Routes";
import GLOBAL from "../../../global";


const Header = (props) => {
    return (
        <div className="kt-header kt-grid__item dashboard-header ">
            <div className="kt-container  kt-container--fluid ">
                <div className="kt-header__brand">
                    <div className="kt-header__brand-logo">
                        <Link to={routes.dashboard}>
                            {GLOBAL.LOGO_URL ? (<img src={GLOBAL.LOGO_URL} height="50" alt="MMH Logo"/>) : (
                                <img src={GLOBAL.WHITE_LOGO_URL} height="50" alt="MMH Logo"/>)}
                        </Link>
                    </div>
                </div>

                {/* Children components passed to Header */}
                {props?.children}
            </div>
        </div>
    )
};

export default Header;