import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import routes from "../../constants/Routes";
import {getArrayFromObject, getBodyRegionsValueLabelObjectArray, isEmptyObject} from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";
import Select, { components } from "react-select";
import Refresh from "../shared/Refresh";
import MmhTable from "../shared/table/datatable/MmhTable";
import ReactPaginate from "react-paginate";
import FysicalScoreReportView from "../report/FysicalScoreReportView";
import { UncontrolledTooltip } from "reactstrap";
import AppContext from "../../contexts/AppContext";
import GLOBAL from "../../global";
import examService from "../../services/ExamService";
import { format } from "date-fns";
// import viewReportBtn from "../../assets/img/list-page/my-assessment/viewReport.svg";
import viewReportBtn from "../../assets/img/myAssessmensList/eyeIcon.svg";
// import startExerciseDisableBtn from "../../assets/img/list-page/my-assessment/Start-Exercise-Disable.svg";
import startExerciseBtn from "../../assets/img/myAssessmensList/startExerciseBtn.svg";
// import dropdownBtn from "../../assets/img/mmh-table/dropDown.svg";
import MyExercisesDetails from "./MyExercisesDetails"
import MultipleSelectOption from "../my-assessments/MultipleSelectOption";
import MyAssessmentTooltip from "../my-assessments/MyAssessmentTooltip";
import ExerciseDetails from "./ExerciseDetails";

const MyExcercisesList = (props) => {
    const history = useHistory()
    let { testId } = useParams();
    const { showCompletedAssessments = false } = props
    const { userInfo } = useContext(AppContext);
    const { EnableFysicalScore, IsPatientReportAvailable } = userInfo.Permissions;
    const [assessments, setAssessments] = useState([]);
    const [fysicalReportView, setFysicalReportView] = useState(false);
    const [fysicalRportUrl, setFysicalRportUrl] = useState('');
    const [selectedExam, setSelectedExam] = useState({});
    const subscription = useRef(true);
    const [reloadMyRoms, setReloadMyRoms] = useState(false);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [reportFilterValue, setReportFilterValue] = useState(CONSTANT.FILTER_BY_REPORT.ALL.key);
    const [bodyRegions, setBodyRegions] = useState([]);

    // eslint-disable-next-line
    const [followUpFilterValue, setFollowUpFilterValue] = useState(CONSTANT.FOLLOW_UP.YES_OR_NO.key)
    const [myPageSize, setMyPageSize] = useState(CONSTANT.PAGE_SIZE.TEN.value);
    const [myPageNumber, setMyPageNumber] = useState(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reportStatus, setReportStatus] = useState(null);
    const [bodyRegionList, setBodyRegionList] = useState('');
    const [isFollowUp, setIsFollowUp] = useState(null);
    const firstTestIdRef = useRef(null);
    const [testIdTmp, setTestIdTmp] = useState('');


    useEffect(() => {
        setLoading(true);
        // noinspection JSIgnoredPromiseFromCall

        // if(isEmptyObject(firstTestIdWithHomeExercise)) {
        //     getFirstExerciseTestId().then();
        // }
        _getMyAssessments();
        // eslint-disable-next-line
    }, [myPageNumber, myPageSize, reportStatus, bodyRegionList, isFollowUp]);

    useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        setBodyRegions(getBodyRegionsValueLabelObjectArray());

        return () => {
            subscription.current = false;
            GLOBAL.ASSESSMENT = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall

        if (reloadMyRoms) {
            _getMyAssessments().then();
            setReloadMyRoms(false);
        }
        // eslint-disable-next-line
    }, [reloadMyRoms]);

    // const getFirstExerciseTestId = async () => {
    //     setLoading(true);
    //     let payload = {
    //         // PatientId: patientId,
    //         PageSize: 10,
    //         PageNumber: 1,
    //         ReportStatus: null,
    //         BodyRegionList: [],
    //         IsFollowUp: null,
    //         Completed: false,
    //         Search: ``,
    //     };
    //
    //     const response = await examService.getAllMyExercises(payload);
    //     const {data, success, error} = response;
    //
    //     if (subscription.current) {
    //         if (!success) {
    //             console.log(error);
    //             return [];
    //         }
    //
    //         const tempData = data || '[]';
    //         if (tempData['MyRomList']?.length > 0) {
    //             setFirstTestIdWithHomeExercise(tempData['MyRomList'][0]);
    //         }
    //     }
    //     setLoading(false);
    // }

    const _getMyAssessments = async () => {
        setLoading(true);
        let payload = {
            PageSize: myPageSize,
            PageNumber: myPageNumber,
            ReportStatus: reportStatus,
            BodyRegionList: bodyRegionList ? bodyRegionList.split`,`.map(x => +x) : [],
            IsFollowUp: isFollowUp,
            Completed: showCompletedAssessments,
            Search: `${testIdTmp}`,
        };

        const response = await examService.getAllMyExercises(payload);
        const { data, success, error } = response;

        if (subscription.current) {
            if (!success) {
                console.error(error);
                return [];
            }

            // const tempData = data || '[]';
            // setAssessments(tempData['MyRomList']);
            // setTotalRecord(tempData['TotalRecords']);
            const tempData = data || '[]';
            let romListExceptFirstOne = tempData['MyRomList'];
            let totalRomRecords = tempData['TotalRecords'];
            firstTestIdRef.current = tempData?.LatestTestId;
            // if(tempData['MyRomList']?.length > 0 && myPageNumber === 1) {
            //     romListExceptFirstOne = tempData['MyRomList'].slice(1);
            //     totalRomRecords = tempData['TotalRecords'] - 1;
            // }
            if(firstTestIdRef.current) {
                romListExceptFirstOne = tempData?.MyRomList.filter(item => item.TestId !== firstTestIdRef.current);
                totalRomRecords = totalRomRecords - 1 >= 0 ? totalRomRecords - 1 : 0;
            }

            // setAssessments(tempData['MyRomList']);
            // setTotalRecord(tempData['TotalRecords']);

            setAssessments(romListExceptFirstOne);
            setTotalRecord(totalRomRecords);

            commonFilter({
                data: romListExceptFirstOne,
                reportStatus: reportFilterValue,
                bodyRegions: []
            });

            setLoading(false);
        }
    };

    const onClickViewFysicalScoreReportCell = (row) => () => {
        if(!IsPatientReportAvailable) return;
        const { original } = row;
        const url = original['ReportUrl'];
        setFysicalRportUrl(url);
        toggleFysicalReportModal();
    };

    const onClickTrackMovement = (row) => () => {
        GLOBAL.ASSESSMENT = null;
        const { original } = row;
        setSelectedExam(original);
        // setRedirectToMyExercises(true);
        window.location.replace(`/#${routes.myExercises}/${original?.TestId}`)
    }

    const toggleFysicalReportModal = () => {
        setFysicalReportView(!fysicalReportView);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Test ID',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    if(original['IsFollowUp']) {
                        return (
                            <div className="text-center">
                                <p className="m-0">FOLLOW-UP</p>
                                <p className="m-0">{original['TestId']}</p>
                                <p className="m-0 p-1 bg-color-primary-light-1 initial-testId">Initial: {original['BaseTestId']}</p>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {original['TestId']}
                            </div>

                        )
                    }
                    // return `${original['IsFollowUp'] ? 'FOLLOW-UP ' + original['TestId'] : original['TestId']}`;
                }
            },
            {
                Header: 'Body Region',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original['BodyRegionName']}`;
                }
            },
            {
                Header: 'Assessment',
                HeaderSecLine: 'Type',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original['InvitationTypeTxt']}`;
                }
            },
            {
                Header: 'Order',
                HeaderSecLine: 'Date',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return format(new Date(original['SubmissionDate']) || new Date(), CONSTANT.DATE_FORMAT.MMM_DD_YYYY);
                }
            },
            {
                Header: 'Report',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;

                    return (
                        <>
                            {!original?.['ReportUrl']
                                ? (
                                    <span className="badge badge-warning font-medium font-size-sm color-black">In progress</span>
                                    // <span className="color-orange-dark">In progress</span>
                                ) : (
                                    // <button className="btn color-primary"
                                    //         disabled={!IsPatientReportAvailable}
                                    //         onClick={onClickViewFysicalScoreReportCell(cellProps.row)}>
                                    //     <img src={viewReportBtn} alt="view" />
                                    // </button>

                                    <button
                                        className={`btn button-row btn-border ${IsPatientReportAvailable ? '' : 'pointer-none opacity-0_4'}`}
                                        onClick={onClickViewFysicalScoreReportCell(cellProps.row)}>
                                         <span className="d-flex justify-content-center align-items-center">
                                            <img className="mr-3" src={viewReportBtn} alt="Telemedicine" /> View Report
                                        </span>
                                    </button>
                                )}
                        </>

                    );
                }
            },
            {
                Header: 'Home',
                HeaderSecLine: 'Exercises',
                style: 'w-100 text-center kt-align-center',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return (
                        <>
                            {original['TotalHomeExercise'] === 0
                                ? (
                                    <span className="badge badge-warning font-medium font-size-sm color-black">Not Available</span>
                                )
                                : (
                                    <button
                                        className="btn pointer button-row bg-color-ice-blue-4"
                                        id={`home_exercise-${original['TestId']}`}
                                        onClick={onClickTrackMovement(cellProps.row)}
                                    >
                                            <span className="d-flex justify-content-center align-items-center color-primary-dark-1">
                                                    <img className="mr-3" src={startExerciseBtn} alt="Home Exercise" /> Start
                                            </span>
                                            <MyAssessmentTooltip text="Start Home Exercise" target={`home_exercise-${original['TestId']}`} />
                                    </button>
                                )
                            }
                        </>
                    );
                }
            }
        ],

        // eslint-disable-next-line
        []
    );

    const onChangeSelectBodyRegion = (values) => {
        const keys = values.map(item => item.value);

        if (values && values.length === 0) {
            setBodyRegionList('');
        }

        keys.map((key) => {
            if (!bodyRegionList.includes(key)) {
                if (bodyRegionList.length > 0) {
                    setBodyRegionList(bodyRegionList + ',' + key);
                } else {
                    setBodyRegionList(key + '');
                }
            }

            return null;
        });

        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
        setMyPageSize(CONSTANT.PAGE_SIZE.TEN.value);
    }

    const onKeyUpTestId = (e) => {
        e.preventDefault();
        setTestIdTmp(e.target.value);
        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
        setMyPageSize(CONSTANT.PAGE_SIZE.TEN.value);
        setReloadMyRoms(true);
    }

    const handlePageSize = (event) => {
        setMyPageSize(event.target.value);
        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
    }

    const handleRefresh = () => {
        setReloadMyRoms(true);
    }

    const getFilterByBodyRegionsData = (assessments, bodyRegionKeys) => {
        let filteredData = assessments;
        if (bodyRegionKeys?.length > 0) {
            filteredData = assessments.filter((item) => bodyRegionKeys?.includes(item?.BodyRegion));
            setFilteredAssessments(filteredAssessments);
        }

        return filteredData;
    }

    const getFilterByReportStatusData = (assessments, value) => {
        return assessments?.filter((item) => {
            if (value === CONSTANT.FILTER_BY_REPORT.IN_PROGRESS.key) {
                return !item?.['ReportUrl'];

            } else if (value === CONSTANT.FILTER_BY_REPORT.COMPLETED.key) {
                return item?.['ReportUrl'];

            } else {
                return true;
            }
        });
    }

    const getFilterByFollowUpData = (assessments, value) => {
        return assessments?.filter((item) => {
            if (value === CONSTANT.FOLLOW_UP.YES.key) {
                return item?.['IsFollowUp']

            } else if (value === CONSTANT.FOLLOW_UP.NO.key) {
                return !item?.['IsFollowUp']

            } else {
                return true;
            }
        });
    }

    const commonFilter = (
        {
            data,
            reportStatus = reportFilterValue,
            bodyRegions = [],
            followUp = followUpFilterValue
        }) => {
        // const filterByReportStatusData = getFilterByReportStatusData(data, reportStatus);
        // const filterByBodyRegions = getFilterByBodyRegionsData(filterByReportStatusData, bodyRegions);
        // const filterByFollowUp = getFilterByFollowUpData(filterByBodyRegions, followUp);

        //as already api filtering the data we do not need to filter those data again
        setFilteredAssessments(data);
    }

    return (
        <>



            {testId.startsWith(`TEST-`)
                ?
                    <MyExercisesDetails testId={testId || ""} />
                :
                    <>
                        {/*{(!loading && !isEmptyObject(firstTestIdWithHomeExercise))*/}
                        {/*    ? (*/}
                        {/*        <div className="d-flex flex-column w-100">*/}
                        {/*            <h3 className="color-primary my-3">Current Assigned Exercises</h3> ,*/}
                        {/*            <MyExercisesDetails testId={firstTestIdWithHomeExercise?.TestId} isFromMyExercisesTable={false} />*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*    :*/}
                        {/*    (*/}
                        {/*        <h3 className="color-primary my-3">Do Not Have Assigned Exercises</h3>*/}
                        {/*    )*/}
                        {/*}*/}

                        {(!loading
                            // && (!isEmptyObject(firstTestIdWithHomeExercise)
                            && (
                                firstTestIdRef.current
                                    ? (
                                        <div className="d-flex flex-column w-100">
                                            <h3 className="color-primary my-3 w-100">Current Assigned Exercises</h3> ,
                                            {/*<MyExercisesDetails testId={firstTestIdWithHomeExercise?.TestId} isFromMyExercisesTable={false} />*/}
                                            <MyExercisesDetails testId={firstTestIdRef.current} isFromMyExercisesTable={false} />
                                        </div>
                                    )
                                    :
                                    (
                                        <h3 className="color-primary my-3 w-100">Do Not Have Assigned Exercises</h3>
                                    )
                            )
                        )}

                        <h3 className="color-primary my-3">Past Exercises</h3>

                        <div className="kt-portlet kt-portlet--bordered color-primary font-wight-500 my-assessment w-100-percent">
                            <div className="kt-portlet__body">
                                <div className="filter-list">
                                    <div className="row flex-row  kt-ml-0 float-left">
                                        {EnableFysicalScore && (
                                            <div className=''>
                                                <label htmlFor="example-text-input"
                                                       className="align-self-center kt-ml-15">Body Region</label>
                                                <div>
                                                    <MultipleSelectOption
                                                        placeholder="Body Region"
                                                        options={bodyRegions}
                                                        onChange={onChangeSelectBodyRegion}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className='min-w-20 kt-ml-5'>
                                            <label
                                                htmlFor="example-text-input"
                                                className="align-self-center kt-ml-15">TEST ID
                                            </label>
                                            <div>
                                                <input
                                                    placeholder="Test ID"
                                                    className="form-control filter_width_height"
                                                    onChange={e => setTestIdTmp(e.target.value)}
                                                    onKeyUp={onKeyUpTestId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Refresh refreshFunction={handleRefresh}/>
                                </div>

                                <MmhTable columns={columns} data={filteredAssessments} loading={loading}/>

                                <div className="mt-2  d-flex justify-content-between">
                                    <div>
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(totalRecord / myPageSize)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) => {
                                                setMyPageNumber(data.selected + 1)
                                            }}
                                            containerClassName={"pagination  justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item "}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            forcePage={(myPageNumber - 1)}
                                        />
                                    </div>
                                    <div className="justify-content-end">
                            <span
                                className="mr-2 kt-datatable__pager-detail">Showing {((myPageNumber * myPageSize) - myPageSize) + 1} to {(myPageNumber * myPageSize)} of {totalRecord} entries</span>
                                        <select
                                            className="form-control-sm color-primary"
                                            onChange={handlePageSize}
                                            value={myPageSize}>
                                            {getArrayFromObject(CONSTANT.PAGE_SIZE)?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value}>
                                                        {item.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            {fysicalReportView && (
                <FysicalScoreReportView
                    open={fysicalReportView}
                    url={fysicalRportUrl}
                    toggleFysicalReportModal={toggleFysicalReportModal}
                >
                </FysicalScoreReportView>
            )}
        </>
    )
}

export default MyExcercisesList;