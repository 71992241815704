import { useDispatch, useSelector } from "react-redux";

import {
    clearEditAssessmentData, setAllQuestionsError,
    setCurrentGroupId, setCurrentSubGroupIndex, setShowInlineError,
    setTestId
} from "../../../features/editAssessment/editAssessmentSlice";
import { fetchEditAssessmentDataAndInitData } from "../../../features/editAssessment/editAssessmentThunks";
import CONSTANT from "../../../constants/Constant";
import routes from "../../../constants/Routes";
import { useHistory } from "react-router-dom";
import useSweetAlert from "../../shared/sweetAlertComponent/useSweetAlert";
import { deepClone } from "../../../utils/Utils";
import { getAllVisibleQuestions, handleQuestionAnswerValidation } from "../EditAssessmentService";

const useEditAssessment = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { showSweetAlert, hideSweetAlert } = useSweetAlert();

    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentSubGroupIndex = baseData?.currentSubGroupIndex || 0;

    const groups = editAssessmentGroup?.groups || [];
    const currentGroup = groups.find(group => group.groupId === currentGroupId);

    const initEditAssessment = (testId, groupId= "") => {
        dispatch(setTestId(testId));
        if(groupId && parseInt(groupId)) {
            dispatch(setCurrentGroupId(parseInt(groupId)));
        }
        dispatch(fetchEditAssessmentDataAndInitData({ TESTID: testId }));
    };

    const clearEditAssessment = () => {
        dispatch(clearEditAssessmentData())
    }

    const showThankYouForYorResponsePopup = () => {
        showSweetAlert({
            title: 'Success',
            message: 'Thank you for your response.',
            type: CONSTANT.ALERT_TYPE.SUCCESS,
            showOkBtn: true,
            showConfirmBtn: false,
            onConfirm: () => {
                hideSweetAlert();
                history.push(`${routes.myAssessments}/${false}`);
            }
        })
    }

    const showPopupUserShouldAnswerAllQuestions = (doesHaveAnswerValidationError, cb) => {
        let message = '';
        if(currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            message = "All mandatory sections must be filled to submit."
        } else {
            message = "It is required to fill in all mandatory fields in order to submit."
        }

        if(doesHaveAnswerValidationError) {
            showSweetAlert({
                title: 'Attention',
                message: message,
                type: CONSTANT.ALERT_TYPE.INFO,
                onConfirm: () => {
                    hideSweetAlert();
                    cb && cb();
                },
                showCancelBtn: false,
                showConfirmBtn: false,
                showOkBtn: true,
                confirmBtnText: 'OK',
            });
        }
    }

    const handleIsAllVisibleQuestionsOfCurrentGroupAnswered = () => {
        let isAllQuestionsAnswered = true;
        const allVisibleQuestion = deepClone(getAllVisibleQuestions(currentGroup, currentSubGroupIndex));

        const doesHaveAnswerValidationError = handleQuestionAnswerValidation(allVisibleQuestion, dispatch, setAllQuestionsError, setShowInlineError, currentGroupId, currentSubGroupIndex);
        if(doesHaveAnswerValidationError) {
            isAllQuestionsAnswered = false;
        }

        showPopupUserShouldAnswerAllQuestions(doesHaveAnswerValidationError);
        return isAllQuestionsAnswered;
    }

    const handleIsAllQuestionsOfGroupAnswered = (group) => {
        let isAllQuestionsOfGroupAnswered = true;
        let groupId = group?.groupId || 0;
        let totalSubGroups = group?.totalSubGroups || 0;
        let doesHasValidationErrors = false;

        let groupIdWithNotValidAnswer = -1;
        let subGroupIndexWithNotValidAnswer = -1;

        for(let i = 0; i < totalSubGroups; i++) {
            const allVisibleQuestion = deepClone(getAllVisibleQuestions(group, i));
            doesHasValidationErrors = handleQuestionAnswerValidation(allVisibleQuestion, dispatch, setAllQuestionsError, setShowInlineError, groupId, i);
            if(doesHasValidationErrors) {
                isAllQuestionsOfGroupAnswered = false;
                groupIdWithNotValidAnswer = groupId;
                subGroupIndexWithNotValidAnswer = i;
                break;
            }
        }

        if(doesHasValidationErrors) {
            showPopupUserShouldAnswerAllQuestions(doesHasValidationErrors, () => {
                dispatch(setCurrentGroupId(groupIdWithNotValidAnswer));
                dispatch(setCurrentSubGroupIndex(subGroupIndexWithNotValidAnswer));
            });
        }
        return isAllQuestionsOfGroupAnswered;
    }

    return {
        initEditAssessment,
        clearEditAssessment,
        showThankYouForYorResponsePopup,
        handleIsAllVisibleQuestionsOfCurrentGroupAnswered,
        showPopupUserShouldAnswerAllQuestions,
        handleIsAllQuestionsOfGroupAnswered
    }
}

export default useEditAssessment;