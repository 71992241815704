import { combineReducers } from '@reduxjs/toolkit';
import editAssessmentReducer from '../features/editAssessment/editAssessmentSlice';
import sweetAlertReducer from '../features/sweetAlert/sweetAlertSlice';
import chatBotReducer from '../features/chat/chatbotSlice';
import intakeFormReducer from '../features/intakeForm/intakeFormSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import directToConsumerReducer from '../features/directToConsumer/directToConsumerSlice';

const rootReducer = combineReducers({
    editAssessment: editAssessmentReducer,
    sweetAlert: sweetAlertReducer,
    chatBot: chatBotReducer,
    intakeForm: intakeFormReducer,
    dashboard: dashboardReducer,
    directToConsumer: directToConsumerReducer,
});

export default rootReducer;