import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import AppContext from "../../contexts/AppContext";
import CONSTANT from "../../constants/Constant";
import routes from "../../constants/Routes";
import { playAudio } from "../../utils/Utils";

import MobileHeader from "../shared/layout/MobileHeader";
import MainHeaderWrapper from "../shared/layout/MainHeaderWrapper";
import Footer from "../shared/layout/Footer";
import MyReportsDetails from "./MyReportsDetails";
import BodyWithLeftMenu from "../shared/layout/BodyWithLeftMenu";
import BodyWithTopMenu from "../shared/layout/BodyWithTopMenu";

const MyReports = () => {
    const { userInfo } = useContext(AppContext);
    const { EnableFysicalScore, IsPostureAnalyzeEnabled } = userInfo.Permissions;

    useEffect(() => {
        playAudio('remove').then();
    }, []);

    return (
        <>
            {!EnableFysicalScore && !IsPostureAnalyzeEnabled && (
                <Redirect to={`${routes.landing}`}/>
            )}
            {(EnableFysicalScore || IsPostureAnalyzeEnabled) && (
                <>
                    {/*<MobileHeader/>*/}
                    <div className="kt-grid kt-grid--hor kt-grid--root">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                                <MainHeaderWrapper/>
                                {!EnableFysicalScore && IsPostureAnalyzeEnabled && (
                                    <MyReportsDetails/>
                                )}
                                {EnableFysicalScore && (
                                    <>
                                        <BodyWithTopMenu pageName={CONSTANT.PAGE_NAME.MY_REPORTS}>
                                            <MyReportsDetails/>
                                        </BodyWithTopMenu>
                                        <Footer/>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MyReports;
