import { useDispatch, useSelector } from "react-redux";
import {
    setAllQuestionsError, setCurrentQuestionId, setGroupCompleted, handleHideOrShowQuestion,
    setQuestionAnswer, setShowInlineError,
} from "../../../features/editAssessment/editAssessmentSlice";
import CONSTANT from "../../../constants/Constant";
import {
    checkAnswerIdValidation, getConditionalQuestions,
    handleQuestionAnswerValidation,
    saveSingleQuestionAnswer,
} from "../EditAssessmentService";
import { useDebounce } from "../../../hooks/useDebounce";
import { deepClone, getAssessmentCompletion } from "../../../utils/Utils";
import {
    doesHaveAnswerIdRequired, getObjForUpdatingAnswerAtRedux,
    getClickQuestionSubGroupIndex
} from "../EditAssessmentUtils";
import ExamService from "../../../services/ExamService";
import answerValidationService from "../../../services/answerValidation/AnswerValidationService";
import { saveDemoInfoAtLocalStorage } from "../../../services/LocalStorageService";
import useOSWScore from "./useOSWScore";
import { updateCCQuestionAnswerAndSetCompletion } from "../../../features/editAssessment/editAssessmentThunks";

const useEditAssessmentBody = (setSelectedElementForScroll) => {
    const dispatch = useDispatch();
    const { handleUpdateOSWScore } = useOSWScore();
    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const groups = editAssessmentGroup?.groups || [];

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentSubGroupIndex = baseData?.currentSubGroupIndex || 0;
    const testId = baseData?.testId || '';
    const showInlineError = baseData?.showInlineError || false;

    const currentGroup = groups.find(group => group.groupId === currentGroupId);

    const debouncedSaveAnswer = useDebounce(async (question, answers) => {
        await handleSaveSingleQuestionAnswer(question, answers);
    }, 500);

    const handleSaveSingleQuestionAnswer = async (question, answers) => {
        const questionId = question?.questionId || '';
        const responseType = question?.responseType || '';
        const answer = answers[0];

        let answerValue;
        if((question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (question?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
            answerValue = answer?.AnswerList.length > 0 ? answer?.AnswerList : [];
        } else {
            answerValue = answer?.AnswerTitle;
        }
        const validationResult = answerValidationService.validate(questionId, responseType, answerValue);
        // As for checkbox anyone can unselect all option
        if(
            !(
                (question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) ||
                (question?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)
            ) &&
            !validationResult.valid
        ) {
            return;
        }

        if(currentGroupId === CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key) {
            const answer = answers[0];
            const answerTitle = answer?.AnswerTitle;

            saveDemoInfoAtLocalStorage(questionId, { AnswerValue : answerTitle });
        }

        const response = await saveSingleQuestionAnswer(testId, answers);
        if(response?.success) {
            handleIsGroupCompleted(response)
        }
    }

    const handleIsGroupCompleted = (response) => {
        const data = response?.data;
        if(!data) {
            console.error("No Data Found!");
            return;
        }

        const mapOfAssessmentCompletion = getAssessmentCompletion(data);
        mapOfAssessmentCompletion.forEach(group => {
            if(group?.groupCompleted && group.key !== CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
                dispatch(setGroupCompleted({ groupId: group?.key, isCompleted: true }));
            }
        });
    }

    const handleAnswerSave  = async (value) => {
        if(!checkAnswerIdValidation(value?.question, value?.answerId)) {
            return;
        }

        const responseId = value?.answerId;
        const responseValue = value?.answerText;
        const question = value?.question;
        const questionId = question?.questionId;

        let tempObjForSetQuestionAnswer = {
            questionId: questionId,
            answerValue: responseValue
        }
        if(responseId) {
            tempObjForSetQuestionAnswer.answerId = responseId;
        }

        let tempSubGroupIndex = currentSubGroupIndex;
        if(currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            tempSubGroupIndex = getClickQuestionSubGroupIndex(currentGroup, questionId);
        }

        const answerObjForSetQuestionAnswer = updateAnswerOnRedux(questionId, responseId, responseValue, tempSubGroupIndex);

        let questionsOfSelectedSubGroup = currentGroup.subGroups[tempSubGroupIndex]?.questions;
        let updatedQuestions = updateCurrentQuestionAnswer(questionsOfSelectedSubGroup, answerObjForSetQuestionAnswer);
        let updateQuestion = updatedQuestions.find(question => question.questionId === questionId);
        const answers = getAnswersObjForSaving(updateQuestion, responseId, responseValue);

        if(showInlineError) {
            handleQuestionAnswerValidation(updatedQuestions, dispatch, setAllQuestionsError, setShowInlineError, currentGroupId, tempSubGroupIndex);
        }
        focusNextQuestion(questionId, tempSubGroupIndex);
        handleUpdateOSWScore(updatedQuestions, tempSubGroupIndex);
        let answeredQuestion = updatedQuestions.find(question => question.questionId === questionId);
        await handleChildQuestion(updatedQuestions, answeredQuestion);
        if(doesHaveAnswerIdRequired(value?.question)) {
            //can save directly without any debouncing as those are button, dropdown type question answer
            await handleSaveSingleQuestionAnswer(question, answers);
        } else {
            //Does not need answerId means response type TEXT so need to stop saving at every keystore
            debouncedSaveAnswer(question, answers);
        }
    }

    const updateCurrentQuestionAnswer = (questionsOfSelectedSubGroup, answerObjForSetQuestionAnswer) => {
        const tempUpdatedQuestions = deepClone(questionsOfSelectedSubGroup);

        tempUpdatedQuestions.forEach(question => {
            if(question.questionId === answerObjForSetQuestionAnswer.questionId) {
                if((question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (question?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
                    let tempAnswerList = question?.answerList ?? [];
                    let doesAlreadySelectedCurrentAnswer = tempAnswerList.find(answer => answer === answerObjForSetQuestionAnswer.answerId);
                    if(doesAlreadySelectedCurrentAnswer) {
                        tempAnswerList = tempAnswerList.filter(currentAnswerId => currentAnswerId !== answerObjForSetQuestionAnswer.answerId);
                    } else {
                        tempAnswerList.push(answerObjForSetQuestionAnswer.answerId);
                    }

                    question.answerList = tempAnswerList;
                } else {
                    question.answerValue = answerObjForSetQuestionAnswer.answerValue;
                    question.answerId = answerObjForSetQuestionAnswer.answerId;
                }

            }
        });

        return tempUpdatedQuestions;
    }

    const getAnswersObjForSaving = (question, responseId, responseValue) => {
        let answers;
        if((question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (question?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
            let tempAnswerList = question?.answerList ?? [];
            answers = [
                {
                    AnswerList: tempAnswerList,
                    QuestionId: question?.questionId ?? 0
                }
            ]
        } else {
            answers = [
                {
                    GroupId: currentGroupId,
                    AnswerId: responseId,
                    AnswerTitle: responseValue,
                    QuestionId: question?.questionId ?? 0
                }
            ]
        }

        return answers;
    }

    const handleChildQuestion = async (questions, question) => {
        if(!question?.answerId) return;

        let { childQuestionIdsOfSelectedResponse, childQuestionIdsOfNotSelectedResponse } =
            getConditionalQuestions(question);

        // remove questions ids those are in both arrays as do not need to hide question those are inside selected question
        childQuestionIdsOfNotSelectedResponse = childQuestionIdsOfNotSelectedResponse
            .filter((questionId) => !childQuestionIdsOfSelectedResponse.includes(questionId));

        updateVisibility(childQuestionIdsOfNotSelectedResponse, true);
        updateVisibility(childQuestionIdsOfSelectedResponse, false);

        if(childQuestionIdsOfNotSelectedResponse.length > 0) {
            await ExamService.clearAssessments({ TestId: testId, QuestionIds: childQuestionIdsOfNotSelectedResponse });
        }
    }

    const updateVisibility = (questionIds, isDefaultHide) => {
        questionIds.forEach(questionId => {
            dispatch(handleHideOrShowQuestion({
                questionId,
                isDefaultHide
            }))
        });
    };

    const updateAnswerOnRedux = (questionId, responseId, responseValue, tempSubGroupIndex) => {
        const answerObjForSetQuestionAnswer = getObjForUpdatingAnswerAtRedux(questionId, responseId, responseValue, tempSubGroupIndex);

        dispatch(setQuestionAnswer(answerObjForSetQuestionAnswer));
        return answerObjForSetQuestionAnswer;
    }

    const focusNextQuestion = (currentQuestionId, subGroupIndex) => {
        const subGroups = currentGroup?.subGroups || [];
        const tempQuestions = deepClone(subGroups[subGroupIndex]?.questions || []);

        const currentQuestionIndex = tempQuestions.findIndex(question => question.questionId === currentQuestionId);
        let nextQuestionIndex = currentQuestionIndex + 1;
        while((nextQuestionIndex < tempQuestions.length) && (tempQuestions[nextQuestionIndex].isDefaultHide)) nextQuestionIndex++;

        if(nextQuestionIndex < tempQuestions.length) {
            let nextQuestionHTMLElement = document.getElementById(`cc-question-${currentQuestionId}`);
            if(nextQuestionHTMLElement) {
                setSelectedElementForScroll && setSelectedElementForScroll(nextQuestionHTMLElement);
                dispatch(setCurrentQuestionId(tempQuestions[nextQuestionIndex].questionId));
            }
        }
    }

    const handleUpdateCCQuestionAnswer = (answerId, answerValue) => {
        dispatch(updateCCQuestionAnswerAndSetCompletion(answerId, answerValue));
    }

    return {
        handleAnswerSave,
        handleUpdateCCQuestionAnswer,
        updateAnswerOnRedux,
    }
}

export default useEditAssessmentBody;