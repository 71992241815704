import React, {useEffect, useState} from 'react';

import Header from "./Header";
import CONSTANT from "../../../constants/Constant";
import {useLocation} from "react-router-dom";

const PackageHeaderWrapper = () => {
    const location = useLocation();
    const [pageHeader, setPageHeader] = useState('');

    useEffect(() => {
        handleSetPageHeader();
    }, [location?.search]);

    const handleSetPageHeader = () => {
        let query = new URLSearchParams(location?.search);
        let assessmentNameFromUrl = query.get(CONSTANT.QUERY_PARAM.ASSESSMENT_NAME) || 'Select a Plan';

        setPageHeader(assessmentNameFromUrl);
    }

    return (
        <Header>
            <div className="d-flex flex-grow-1 justify-content-center align-items-center font-family-outfit">
                <p className="font-size-heading-md color-white font-medium m-0">{pageHeader}</p>
            </div>
        </Header>
    )
};

export default PackageHeaderWrapper;