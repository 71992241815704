import GLOBAL from "../../global";
import { setOpenChatBot } from "../../features/chat/chatbotSlice";
import { useDispatch } from "react-redux";
import React, { useContext, useRef } from "react";
import AppContext from "../../contexts/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import CONSTANT from "../../constants/Constant";
import { createAssessment } from "./notification/data/api";
import { toast } from "react-toastify";
import { _getInCompleteAssessmentType } from "./dashboardUtills";
import { isEmptyObject } from "../../utils/Utils";
import Info from "../../assets/img/utils/info.svg";
import { redirectTo } from "../shared/chatbotNew/ChatServices";
import routes from "../../constants/Routes";
import Assessment from "../../models/Assessment";
import { saveIntake } from "../shared/intake-form/intake-form-service";
import useIntakeFrom from "../shared/intake-form/useIntakeFrom";
import {
    setIsShowIsFollowUpPopup, setOpenBiometricMovementAndCautionQuestionContainer, setSelectedTelemedicineInvitationId,
    setShowDetailInstruction, setShowLoadingAtJoinNowBtn, setShowLoadingInConformTelemedicine
} from "../../features/dashboard/dashboardSlice";
import useHandleExpiredAndUpcomingMeetingPopupMsg from "../../hooks/useHandleExpiredAndUpcomingMeetingPopupMsg";

const useDashboardBody = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { setTelemedicineProperties, setSelectedTabForEditAssessment, resetTelemedicineKeys, setFollowupForAssessmentPage, setLastIncompletedAssessmentsForAssessmentPage } = useContext(AppContext);
    const { handleOpenIntakeForm, handleCloseIntakeForm, handleSetJoiningTelemedicine } = useIntakeFrom();
    const { getAssessmentAvailabilityStatus, handleOpenUpcomingOrExpiredPopupMessage } = useHandleExpiredAndUpcomingMeetingPopupMsg();

    const notificationInformationOfSelectedAssessment = useRef({});

    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';

    const handleNewUser = (allNotifications) => {
        if(GLOBAL.USER_INFO?.IsNewUser && allNotifications.length > 0) {
            if(!GLOBAL.USER_INFO?.CreditCardCount && GLOBAL.IS_SUBSCRIPTION_ENABLED) {
                return;
            }

            const invitationData = allNotifications.slice(0, 1)[0];
            dispatch(setOpenChatBot({
                invitationData,
                patientId,
                meetingStart: invitationData?.MeetingStart,
                meetingEnd: invitationData?.MeetingEnd
            }));
        }
    }

    const handleRemoteAssistance = () => {
        let query = new URLSearchParams(location?.search);
        let invitationId = query.get('InvGId') || '';
        let bodyRegionListParam = query.get('BodyRegionIds') || '';
        let bodyRegionList = bodyRegionListParam.split(',').map(Number).filter(Boolean);
        let testId = query.get('TestId') || '';
        let isRemoteAssistance = query.get('RA') === 'true'; // Will open self as telemedicine?
        if(isRemoteAssistance && invitationId && bodyRegionList.length > 0 && testId) {
            setTelemedicineProperties(invitationId, bodyRegionList, null, removeURLQueryParams);
        }
    }

    ////TODO: will move conformationAlertContentRef to redux
    const notificationHandler = async (notification, conformationAlertContentRef) => {
        const firstInCompleteGroup = _getInCompleteAssessmentType(notification.AssessmentStatus).key;
        const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(notification.MeetingStart, notification.MeetingEnd);

        if(
            notification?.TestId &&
            !notification?.IsFollowup &&
            firstInCompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key &&
            +notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine &&
            (assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY || assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY)
        ) {
            handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus);
        }
        else if(notification?.IsFollowup) {
            handleFollowupNotification(notification, conformationAlertContentRef);
        } else if(notification?.IsLastIncomplete) {
            notificationInformationOfSelectedAssessment.current = notification;
            await handleLastInCompleteAssessmentAction(notification)
        } else if(notification?.TestId && (+notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine)) {
            setTelemedicineProperties(notification.InvitationId, notification.BodyRegionList, onOpenTelemedicineModal, onCloseTelemedicineModal);
            dispatch(setSelectedTelemedicineInvitationId(notification.InvitationId));
        }
        else {
            await startHandler(notification)
        }
    }

    // To start bot
    const startHandler = async (notification) => {
        // It is an old business logic, if any tenant does not want to use chatbot for there case we will first create
        // assessment and then redirect to edit assessment page so that user can fill the assessment data
        // Do not know is this logic is still present at VA portal or not for safety purpose we are keeping this logic
        if(notification.IsFormBasedIntakeEnabled && !notification.TestId) {
            let testId = await createAssessment(preparePayload(notification.BodyRegionList, notification.InvitationId))
            if(testId) {
                redirectToEditAssessmentPage({...notification, TestId: testId})
            } else {
                toast.error(<div>Failed to create assessment.</div>);
            }
        } else if(notification.TestId) {
            //If testId is present then resume the assessment for extra security purpose such that any assessment with
            //having testid can't be started from bot by any chance
            redirectToEditAssessmentPage(notification)
        } else {
            dispatch(setOpenChatBot({
                invitationData: notification,
                patientId,
                meetingStart: notification?.MeetingStart,
                meetingEnd: notification?.MeetingEnd
            }));
        }
    }

    const redirectToEditAssessmentPage = (notification) => {
        notificationInformationOfSelectedAssessment.current = notification
        let firstInCompletedGroup = _getInCompleteAssessmentType(notification?.AssessmentStatus);
        let keyOfFirstIncompleteGroup = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
        if(!isEmptyObject(firstInCompletedGroup)) {
            keyOfFirstIncompleteGroup = firstInCompletedGroup?.key || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
            if(keyOfFirstIncompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) keyOfFirstIncompleteGroup = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
        }
        setSelectedTabForEditAssessment(keyOfFirstIncompleteGroup);

        // setRedirectToAssessmentPage(true)
        redirectTo(`${routes.editAssessment}/${notificationInformationOfSelectedAssessment.current.TestId}?groupId=${_getInCompleteAssessmentType(notificationInformationOfSelectedAssessment.current?.AssessmentStatus)?.key}`)
    }

    const handleFollowupNotification = (notification, conformationAlertContentRef) => {
        notificationInformationOfSelectedAssessment.current = notification;

        if (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine) {
            setTelemedicineAlert(conformationAlertContentRef);
        } else if (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Self) {
            setSelfInvitationAlert(conformationAlertContentRef);
        } else if(notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic) {
            setInClinicAlert(conformationAlertContentRef);
        }

        dispatch(setIsShowIsFollowUpPopup(true));
    };

    const handleLastInCompleteAssessmentAction = (notification) => {
        const firstInCompleteGroup = _getInCompleteAssessmentType(notification.AssessmentStatus).key;

        if(firstInCompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key && +notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Self) {
            openBiometricCautionQuestionModal(notification);
        } else if (firstInCompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key && +notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine) {
            handleJoinTelemedicine(notification);
        } else {
            redirectToEditAssessmentPage(notification)
            // dispatch(setOpenChatBot({
            //     invitationData: notification,
            //     patientId,
            //     testIdToResumeChat: notification?.TestId,
            //     meetingStart: notification?.MeetingStart,
            //     meetingEnd: notification?.MeetingEnd
            // }));
        }
    }

    const setTelemedicineAlert = (conformationAlertContentRef) => {
        const assessmentAvailabilityStatus = getAssessmentAvailabilityStatus(notificationInformationOfSelectedAssessment.current?.MeetingStart, notificationInformationOfSelectedAssessment.current?.MeetingEnd);

        let yesBtnText = "";
        let handleOnClickYes = () => onClickJoinTelemedicine();

        if(assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.KEY) {
            yesBtnText = CONSTANT.ASSESSMENT_AVAILABILITY.UPCOMING.MESSAGE;
            handleOnClickYes = () => handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus, () => dispatch(setIsShowIsFollowUpPopup(false)));
        } else if(assessmentAvailabilityStatus === CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.KEY) {
            yesBtnText = CONSTANT.ASSESSMENT_AVAILABILITY.EXPIRED.MESSAGE;
            handleOnClickYes = () => handleOpenUpcomingOrExpiredPopupMessage(assessmentAvailabilityStatus, () => dispatch(setIsShowIsFollowUpPopup(false)));
        } else {
            yesBtnText = CONSTANT.ASSESSMENT_AVAILABILITY.AVAILABLE.MESSAGES.JOIN_TELEMEDICINE;
        }

        const commonContent = getCommonAlertContentForInclinic();
        Object.assign(conformationAlertContentRef.current, {
            yesBtnText: yesBtnText,
            noBtnText: "Update My Background",
            content: commonContent,
            onClickYes: handleOnClickYes,
            onClickNo: () => redirectToEditAssessmentPage(notificationInformationOfSelectedAssessment.current),
            headerText: "Update Your Information?"
        });
    };

    const setInClinicAlert = (conformationAlertContentRef) => {
        const commonContent = getCommonAlertContentForInclinic();
        Object.assign(conformationAlertContentRef.current, {
            noBtnText: "Update My Background",
            content: commonContent,
            onClickNo: () => redirectToEditAssessmentPage(notificationInformationOfSelectedAssessment.current),
            headerText: "Update Your Information?",
            showYesBtn: false
        });
    };

    const setSelfInvitationAlert = (conformationAlertContentRef) => {
        const commonContent = getCommonAlertContentForInclinic();
        Object.assign(conformationAlertContentRef.current, {
            yesBtnText: "Resume Assessment",
            noBtnText: "Update My Background",
            content: commonContent,
            onClickYes: () => {
                openBiometricCautionQuestionModal(notificationInformationOfSelectedAssessment.current);
                dispatch(setIsShowIsFollowUpPopup(false));
                conformationAlertContentRef.current = {}
            },
            onClickNo: () => redirectToEditAssessmentPage(notificationInformationOfSelectedAssessment.current),
            headerText: "Update Your Information?"
        });
    };

    const getCommonAlertContentForInclinic = () => (
        <div>
            <p>Would you like to update your background information (Activities of Daily Living, Medical History, Chief Complaints)?
                <span
                    onClick={() => dispatch(setShowDetailInstruction(true))}
                    className="edit-assessment-info-container kt-ml-10"
                >
                <img src={Info} alt="Information" className='tele_eye_btn cursor-pointer' />
            </span>
            </p>
        </div>
    );

    const onClickJoinTelemedicine = () => {
        handleJoinTelemedicine(notificationInformationOfSelectedAssessment.current);
        dispatch(setShowLoadingInConformTelemedicine(true));
        setIsShowIsFollowUpPopup(false);
    }

    const handleClickMore = (type) => {
        if(type === CONSTANT.ASSESSMENT_TYPE.FOLLOW_UP.key) {
            setFollowupForAssessmentPage(true);
        } else if(type === CONSTANT.ASSESSMENT_TYPE.LAST_INCOMPLETE.key) {
            setLastIncompletedAssessmentsForAssessmentPage(true);
        }

        redirectTo(routes.myAssessments + '/false');
    }

    const handleJoinTelemedicine = (notification) => {
        const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || false;
        dispatch(setIsShowIsFollowUpPopup(false));

        if(!isIntakeSigned) {
            // dispatch(initIntakeForm({
            //     showIntakeForm: true,
            //     bodyRegionList: notification.BodyRegionList || [],
            //     invitationTypeName: notification.InvitationTypeName || '',
            // }));

            handleOpenIntakeForm({
                bodyRegionList: notification.BodyRegionList || [],
                invitationTypeName: notification.InvitationTypeName || '',
                showSubmitBtn: true,
                onClickSubmitIntakeForm: onClickSubmitIntakeForm
            })
        } else {
            joinTelemedicine(notification)
        }
    }

    const joinTelemedicine = (notification) => {
        handleCloseIntakeForm();
        setTelemedicineProperties(notification.InvitationId, notification.BodyRegionList, onOpenTelemedicineModal, onCloseTelemedicineModal);
        dispatch(setSelectedTelemedicineInvitationId(notification.InvitationId));
        dispatch(setShowLoadingAtJoinNowBtn(true));
    }



    const onClickSubmitIntakeForm = async (values) => {
        handleSetJoiningTelemedicine(true);
        GLOBAL.ASSESSMENT = new Assessment({ TestId: notificationInformationOfSelectedAssessment.current?.TestId });
        const response = await saveIntake(values);
        const { data, success, error } = response;
        if(!success) {
            toast.error(error || 'Failed to save intake form');
            return;
        }
        joinTelemedicine(notificationInformationOfSelectedAssessment.current);
    }

    const openBiometricCautionQuestionModal = (notification) => {
        dispatch(setOpenBiometricMovementAndCautionQuestionContainer({
            openBiometricMovementAndCautionQuestionContainer: true,
            testId: notification.TestId,
            selectedBodyRegions: notification.BodyRegionList
        }));
    }

    const onCloseTelemedicineModal = () => {
        resetTelemedicineKeys();
        dispatch(setSelectedTelemedicineInvitationId(null));
        dispatch(setShowLoadingAtJoinNowBtn(false));
        dispatch(setShowLoadingInConformTelemedicine(false));
    }

    const onOpenTelemedicineModal = () => {
        setIsShowIsFollowUpPopup(false);
    }

    const preparePayload = (BodyRegionList, InvitationId) => {
        return {
            BodyRegionList,
            InvitationId
        }
    }

    const removeURLQueryParams = () => {
        history.replace({ pathname: location.pathname });
    }

    return {
        handleNewUser,
        handleRemoteAssistance,
        notificationHandler,
        handleClickMore,
    }
}

export default useDashboardBody;