import React, { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import AppContext from "../../contexts/AppContext";
import examService from "../../services/ExamService";
import FysicalScoreReportView from "../report/FysicalScoreReportView";
import Refresh from "../shared/Refresh";
import { getArrayFromObject, getBodyRegionsValueLabelObjectArray } from "../../utils/Utils";
import MmhTable from "../shared/table/datatable/MmhTable";
import DisplayUrlContent from "../shared/DisplayUrlContent";
import SelectOption from "../my-assessments/SelectOption";
// import dropdownBtn from "../../assets/img/mmh-table/dropDown.svg";
// import initialBtn from "../../assets/img/report/initial.svg";
// import followUpBtn from "../../assets/img/report/followUp.svg";
// import clickForReportBtn from "../../assets/img/report/clickForReport.svg";
import MultipleSelectOption from "../my-assessments/MultipleSelectOption";
import viewReportBtn from "../../assets/img/myAssessmensList/eyeIcon.svg";

const MyReportsDetails = memo(() => {
    const { userInfo } = useContext(AppContext);
    const { EnableFysicalScore, IsPatientReportAvailable } = userInfo.Permissions;
    const [assessments, setAssessments] = useState([]);
    const [fysicalReportView, setFysicalReportView] = useState(false);
    const [fysicalRportUrl, setFysicalRportUrl] = useState('');
    const subscription = useRef(true);
    const [reloadMyRoms, setReloadMyRoms] = useState(false);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [bodyRegions, setBodyRegions] = useState([]);
    // eslint-disable-next-line
    const [followUpFilterValue, setFollowUpFilterValue] = useState(CONSTANT.FOLLOW_UP.YES_OR_NO.key)
    const [myPageSize, setMyPageSize] = useState(CONSTANT.PAGE_SIZE.TEN.value);
    const [myPageNumber, setMyPageNumber] = useState(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(true);
    const [bodyRegionList, setBodyRegionList] = useState('');
    const [isFollowUp, setIsFollowUp] = useState(null);
    const [followupOption, setFollowupOption] = useState(CONSTANT.FOLLOW_UP.YES_OR_NO.key);
    const [reportUrl, setReportUrl] = useState('');
    const [testId, setTestId] = useState('');

    useEffect(() => {
        setLoading(true);
        // noinspection JSIgnoredPromiseFromCall

        _getMyAssessments();
        // eslint-disable-next-line
    }, [myPageNumber, myPageSize, bodyRegionList, isFollowUp]);

    useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        setBodyRegions(getBodyRegionsValueLabelObjectArray());

        if (GLOBAL.URL_PARAMS.IS_REPORT_DOWNLOAD) {
            _getAssessmentById().then();
        }

        return () => {
            subscription.current = false;
            GLOBAL.ASSESSMENT = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        if (reloadMyRoms) {
            _getMyAssessments().then();
            setReloadMyRoms(false);
        }
        // eslint-disable-next-line
    }, [reloadMyRoms]);

    const _getMyAssessments = async () => {
        setLoading(true);
        let payload = {
            PageSize: myPageSize,
            PageNumber: myPageNumber,
            ReportStatus: 1,
            BodyRegionList: bodyRegionList ? bodyRegionList.split`,`.map(x => +x) : [],
            IsFollowUp: isFollowUp,
            Completed: true,
            Search: `${testId}`,
        };

        const response = await examService.getAssessments(payload);
        const { data, success, error } = response;

        if (subscription.current) {
            if (!success) {
                console.error(error);
                return [];
            }

            const tempData = data || '[]';
            setAssessments(tempData['MyRomList']);
            setTotalRecord(tempData['TotalRecords']);

            commonFilter({
                data: tempData['MyRomList'],
                reportStatus: 1,
                bodyRegions: []
            });

            setLoading(false);
        }
    };

    const _getAssessmentById = async () => {
        const payload = {
            TestId: GLOBAL.URL_PARAMS.TEST_ID
        };
        const response = await examService.getAssessmentById(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        const report = data || '{}';
        if (report && report['ReportUrl']) {
            setReportUrl(report['ReportUrl']);
        }
    };

    const onClickViewFysicalScoreReportCell = (row) => () => {
        if(!IsPatientReportAvailable) return;
        const { original } = row;
        const url = original['ReportUrl'];
        setFysicalRportUrl(url);
        toggleFysicalReportModal();
    };

    const toggleFysicalReportModal = () => {
        setFysicalReportView(!fysicalReportView);
    };

    const hideReportContent = (e) => {
        e.preventDefault();
        setReportUrl('');
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Test ID',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original['IsFollowUp'] ? 'FOLLOW-UP ' + original['TestId'] : original['TestId']}`;
                }
            },
            {
                Header: 'Region',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return `${original['BodyRegionName']}`;
                }
            },
            {
                Header: 'Order Date',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    return format(new Date(original['SubmissionDate']) || new Date(), CONSTANT.DATE_FORMAT.MMM_DD_YYYY);
                }
            },
            {
                Header: 'Report',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;

                    return (
                        <>
                            {!original?.['ReportUrl'] && (
                                <span>Not Completed</span>
                            )}
                            {original?.['ReportUrl'] && (
                                // <button className="btn"
                                //         disabled={IsPatientReportAvailable}
                                //         onClick={onClickViewFysicalScoreReportCell(cellProps.row)}>
                                //     <img src={clickForReportBtn} alt="Click For Report" />
                                // </button>

                                <button
                                    className={`btn button-row btn-border ${IsPatientReportAvailable ? '' : 'pointer-none opacity-0_4'}`}
                                    onClick={onClickViewFysicalScoreReportCell(cellProps.row)}>
                                    <span className="d-flex justify-content-center align-items-center">
                                        <img className="mr-3" src={viewReportBtn} alt="Telemedicine" /> Click for Report
                                    </span>
                                </button>
                            )}
                        </>
                    );
                }
            },
            {
                Header: 'Report Type',
                Cell: (cellProps) => {
                    const { original } = cellProps.row;
                    if (original['IsFollowUp']) {
                        return (
                            <>
                                {/*<span*/}
                                {/*    className="kt-badge kt-badge--primary kt-badge--xl kt-badge--inline kt-badge--pill">Follow Up</span>*/}
                                {/*&nbsp;{original['BaseTestId']}*/}
                                {/*<img src={followUpBtn} alt="Follow Up" />*/}
                                <button
                                    className={`btn button-row btn-border pointer-none`}>
                                    <span className="d-flex justify-content-center align-items-center">
                                        Follow Up
                                    </span>
                                </button>
                            </>
                        )
                    } else {
                        return (
                            // <span
                            //     className="kt-badge kt-badge--danger kt-badge--xl kt-badge--inline kt-badge--pill">Initial</span>
                            // <img src={initialBtn} alt="initial" />
                            <button
                                className={`btn button-row btn-border pointer-none`}>
                                    <span className="d-flex justify-content-center align-items-center">
                                        Initial
                                    </span>
                            </button>
                        )
                    }
                }
            }
        ],

        // eslint-disable-next-line
        []
    );

    const onChangeSelectBodyRegion = (values) => {
        const keys = values.map(item => item.value);

        if (values && values.length === 0) {
            setBodyRegionList('');
        }

        keys.map((key) => {
            if (!bodyRegionList.includes(key)) {
                if (bodyRegionList.length > 0) {
                    setBodyRegionList(bodyRegionList + ',' + key);
                } else {
                    setBodyRegionList(key + '');
                }
            }

            return null;
        });

        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
        setMyPageSize(CONSTANT.PAGE_SIZE.TEN.value);
    }

    const onChangeFollowUpOption = (value) => {
        // event.preventDefault();
        // const value = event.target.value;
        setFollowupOption(value);

        if (value === CONSTANT.FOLLOW_UP.YES.key) {
            setIsFollowUp(true);
        }
        if (value === CONSTANT.FOLLOW_UP.NO.key) {
            setIsFollowUp(false);
        }
        if (value === CONSTANT.FOLLOW_UP.YES_OR_NO.key) {
            setIsFollowUp(null);
        }

        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
        setMyPageSize(CONSTANT.PAGE_SIZE.TEN.value);

        setFollowUpFilterValue(value);
        commonFilter({ data: assessments, followUp: value });
    }

    const onKeyUpTestId = (e) => {
        e.preventDefault();
        setTestId(e.target.value);
        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
        setMyPageSize(CONSTANT.PAGE_SIZE.TEN.value);
        setReloadMyRoms(true);
    }

    const handlePageSize = (e) => {
        setMyPageSize(e.target.value);
        setMyPageNumber(CONSTANT.DEFAULT_PAGE_NUMBER.INITIAL_PAGE.value);
    }

    const handleRefresh = () => {
        setReloadMyRoms(true);
    }
    const getFilterByBodyRegionsData = (assessments, bodyRegionKeys) => {
        let filteredData = assessments;
        if (bodyRegionKeys?.length > 0) {
            filteredData = assessments.filter((item) => bodyRegionKeys?.includes(item?.BodyRegion));
            setFilteredAssessments(filteredAssessments);
        }
        return filteredData;
    }

    const getFilterByReportStatusData = (assessments, value) => {
        return assessments?.filter((item) => {
            if (value === CONSTANT.FILTER_BY_REPORT.IN_PROGRESS.key) {
                return !item?.['ReportUrl'];

            } else if (value === CONSTANT.FILTER_BY_REPORT.COMPLETED.key) {
                return item?.['ReportUrl'];

            } else {
                return true;
            }
        });
    }

    const getFilterByFollowUpData = (assessments, value) => {
        return assessments?.filter((item) => {
            if (value === CONSTANT.FOLLOW_UP.YES.key) {
                return item?.['IsFollowUp']

            } else if (value === CONSTANT.FOLLOW_UP.NO.key) {
                return !item?.['IsFollowUp']

            } else {
                return true;
            }
        });
    }

    const commonFilter = (
        {
            data,
            reportStatus = 1,
            bodyRegions = [],
            followUp = followUpFilterValue
        }) => {
        // const filterByReportStatusData = getFilterByReportStatusData(data, reportStatus);
        // const filterByBodyRegions = getFilterByBodyRegionsData(filterByReportStatusData, bodyRegions);
        // const filterByFollowUp = getFilterByFollowUpData(filterByBodyRegions, followUp);

        //as already api filtering the data we do not need to filter those data again
        setFilteredAssessments(data);
    }
    return (
        <>
            <div className="kt-portlet kt-portlet--bordered color-primary my-report w-100-percent">
                <div className="kt-portlet__body">
                    <div className="filter-list">
                        <div className="row flex-row w-75 kt-ml-0 float-left">
                            <div className='w-300px kt-mr-5'>
                                <label htmlFor="example-text-input"
                                       className="align-self-center kt-ml-20">Follow up</label>
                                <div>
                                    <SelectOption
                                        onChange={onChangeFollowUpOption}
                                        value={followupOption}
                                        values={getArrayFromObject(CONSTANT.FOLLOW_UP)}
                                    />
                                </div>
                            </div>
                            {EnableFysicalScore && (
                                <div className='w-300px kt-mr-5'>
                                    <label htmlFor="example-text-input"
                                           className="align-self-center kt-ml-20">Body Region</label>
                                    <MultipleSelectOption
                                        placeholder="Body Region"
                                        options={bodyRegions}
                                        onChange={onChangeSelectBodyRegion}
                                    />
                                </div>
                            )}
                            <div className='min-w-15 kt-ml-5'>
                                <label
                                    htmlFor="example-text-input"
                                    className="align-self-center kt-ml-15">TEST ID
                                </label>
                                <div>
                                    <input
                                        placeholder="Test ID"
                                        className="form-control filter_width_height"
                                        onChange={e => setTestId(e.target.value)}
                                        onKeyUp={onKeyUpTestId}
                                    />
                                </div>
                            </div>
                        </div>
                        <Refresh refreshFunction={handleRefresh}/>
                    </div>

                    <MmhTable columns={columns} data={filteredAssessments} loading={loading}/>

                    <div className="mt-2  d-flex justify-content-between">
                        <div>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(totalRecord / myPageSize)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) => {
                                    setMyPageNumber(data.selected + 1)
                                }}
                                containerClassName={"pagination  justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item "}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={(myPageNumber - 1)}
                            />
                        </div>
                        <div className="justify-content-end">
                            <span
                                className="mr-2 kt-datatable__pager-detail">Showing {((myPageNumber * myPageSize) - myPageSize) + 1} to {(myPageNumber * myPageSize)} of {totalRecord} entries</span>

                            <select
                                className="form-control-sm color-primary"
                                onChange={handlePageSize}
                                value={myPageSize}>
                                {getArrayFromObject(CONSTANT.PAGE_SIZE)?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </select>

                        </div>
                    </div>
                </div>
            </div>
            {fysicalReportView && (
                <FysicalScoreReportView
                    open={fysicalReportView}
                    url={fysicalRportUrl}
                    toggleFysicalReportModal={toggleFysicalReportModal}>
                </FysicalScoreReportView>
            )}
            {reportUrl && (
                <DisplayUrlContent
                    headerTitle="Report"
                    open={!!reportUrl}
                    url={reportUrl}
                    onToggleModal={hideReportContent}>
                </DisplayUrlContent>
            )}
        </>
    )
});

export default MyReportsDetails;
