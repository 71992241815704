import { useDispatch, useSelector } from "react-redux";
import {
    setAllQuestionsError, setCurrentGroupId, setCurrentQuestionId, setCurrentSubGroupIndex, setShowInlineError,
    setShowInstruction,
} from "../../../features/editAssessment/editAssessmentSlice";
import { toast } from "react-toastify";
import CONSTANT from "../../../constants/Constant";
import {
    getIndexOfCurrentGroup,
    indexOfGroupId,
    isCCQuestionAnswered,
} from "../EditAssessmentUtils";
import useSweetAlert from "../../shared/sweetAlertComponent/useSweetAlert";
import useCCInEditAssessment from "./useCCInEditAssessment";
import useBiometricInEditAssessment from "./useBiometricInEditAssessment";
import useEditAssessment from "./useEditAssessment";

const useEditAssessmentHeaderAndFooter = () => {
    const { showSweetAlert, hideSweetAlert, setNoLoading } = useSweetAlert();
    const dispatch = useDispatch();
    const { handleNoCC, handleDirectUserToCCSection } = useCCInEditAssessment();
    const { showPopupForBiometric } = useBiometricInEditAssessment();
    const { showThankYouForYorResponsePopup, handleIsAllVisibleQuestionsOfCurrentGroupAnswered,
        handleIsAllQuestionsOfGroupAnswered
    } = useEditAssessment();

    const editAssessmentGroup = useSelector(state => state.editAssessment);
    const ccQuestion = editAssessmentGroup?.ccQuestion || {};
    const groups = editAssessmentGroup?.groups || [];

    const baseData = editAssessmentGroup?.baseData || {};
    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentSubGroupIndex = baseData?.currentSubGroupIndex || 0;

    const currentGroupIndex = groups.findIndex(group => group.groupId === currentGroupId);


    const handleOnClickGroupTab = (groupId) => {
        if(!groupId) {
            toast.error('Group Id is required');
            console.error('Group Id is required');
            return;
        }

        let indexOfCurrentGroup = indexOfGroupId(groups, groupId);
        let doesAllQuestionAnswered = false;

        if(indexOfCurrentGroup > currentGroupIndex) {
            for(let i = 0; i < indexOfCurrentGroup; i++) {
                let groupId = groups[i]?.groupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
                if(groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                    if(!isCCQuestionAnswered(ccQuestion)) {
                        handleDirectUserToCCSection();
                        return;
                    }
                }
                doesAllQuestionAnswered = handleIsAllQuestionsOfGroupAnswered(groups[i]);
                if(!doesAllQuestionAnswered) return;
            }
        }

        dispatch(setCurrentGroupId(groupId));
        dispatch(setCurrentSubGroupIndex(0));
        dispatch(setCurrentQuestionId(0));
    }

    const handleOnClickNextBtn = () => {
        const currentGroupIndex = getIndexOfCurrentGroup(groups, currentGroupId);
        const totalGroupsCount = groups.length;

        if (currentGroupIndex === -1 || currentGroupIndex === totalGroupsCount - 1) {
            toast.error('You are already in the last group or group not found');
            console.error('You are already in the last group or group not found');
            return;
        }

        const totalSubGroupsCount = groups[currentGroupIndex]?.totalSubGroups;

        let doesAllQuestionAnswered = handleIsAllVisibleQuestionsOfCurrentGroupAnswered();
        if(!doesAllQuestionAnswered) return;

        if (totalSubGroupsCount > 0) {
            if (baseData?.currentSubGroupIndex < totalSubGroupsCount - 1) {
                dispatch(setCurrentSubGroupIndex(currentSubGroupIndex + 1));
                return;
            }
        }

        const nextGroupIndex = currentGroupIndex + 1;
        const nextGroupId = groups[nextGroupIndex]?.groupId;
        dispatch(setCurrentGroupId(nextGroupId));
        dispatch(setCurrentSubGroupIndex(0));
        dispatch(setShowInlineError(false));
        dispatch(setCurrentQuestionId(0));
    };


    const handleOnClickPrevBtn = () => {
        const currentGroupIndex = getIndexOfCurrentGroup(groups, currentGroupId);

        if(currentGroupIndex === -1 || currentGroupIndex === 0) {
            toast.error('You are already in the first group or group not found');
            console.error('You are already in the first group or group not found');
            return;
        }

        if(currentSubGroupIndex >= 1) {
            const prevCurrentSubGroupIndex = currentSubGroupIndex - 1;
            dispatch(setCurrentSubGroupIndex(prevCurrentSubGroupIndex));
            return;
        }

        const prevGroupIndex = currentGroupIndex - 1;
        const prevGroupId = groups[prevGroupIndex]?.groupId;
        const lastIndexOfPrevGroup =
            groups[prevGroupIndex]?.totalSubGroups - 1 > 0
                ? groups[prevGroupIndex]?.totalSubGroups - 1
                : 0;
        dispatch(setCurrentGroupId(prevGroupId));
        dispatch(setCurrentSubGroupIndex(lastIndexOfPrevGroup));
        dispatch(setShowInlineError(false));
        dispatch(setCurrentQuestionId(0));
    }

    const handleSetShowInstruction = (value) => {
        dispatch(setShowInstruction(value));
    }

    const showCCOrPainQuestionPopUp = async () => {
        if(isCCQuestionAnswered(ccQuestion)) {
            showSweetAlert({
                title: 'Question',
                message: 'Do you have any other areas of pain?',
                onCancel: async () => {
                    setNoLoading(true);
                    await showPopupForBiometric();
                },
                onConfirm: hideSweetAlert
            })
        } else {
            showSweetAlert({
                title: 'Question',
                message:
                    'If you are experiencing acute or chronic pain, you can point to the specific area of discomfort' +
                    ' on the skeleton illustration provided. If you don\'t have any complaints, please proceed by' +
                    ' clicking on the "No" button.',
                onCancel: handleNoCC,
                onConfirm: hideSweetAlert
            })
        }
    }

    const handleOnClickSubmit = async (e) => {
        e.preventDefault();

        let doesAllQuestionAnswered = handleIsAllVisibleQuestionsOfCurrentGroupAnswered();
        if (!doesAllQuestionAnswered) return;


        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            await showCCOrPainQuestionPopUp();
        } else {
            showSweetAlert({
                title: 'Summary',
                message: "Before submitting, please be sure to complete all sections of the intake process.",
                type: CONSTANT.ALERT_TYPE.INFO,
                onConfirm: () => {
                    hideSweetAlert();
                    showThankYouForYorResponsePopup();
                },
                onCancel: hideSweetAlert,
                confirmBtnText: 'Submit',
                cancelBtnText: 'Complete Other Sections'
            });
        }
    }

    return {
        handleOnClickGroupTab,
        handleOnClickNextBtn,
        handleOnClickPrevBtn,
        handleSetShowInstruction,
        handleOnClickSubmit,
        showCCOrPainQuestionPopUp
    }
}

export default useEditAssessmentHeaderAndFooter;